import React, { useEffect, useState } from 'react'
import { cloneDeep, difference, isEmpty } from 'lodash'

import CheckboxTabLabelled from '../../BaseComponents/Checkboxes/CheckboxTabLabelled'
import TextInputOutlined from '../../BaseComponents/Text/TextInputOutlined'
import PickerContained from '../../BaseComponents/Pickers/PickerContained'
import { ColorPalette } from '../../../config/colors'
import TwoColumnSelector from '../TwoColumnSelector/TwoColumnSelector'
import { AutoPopulateFields } from './AutoPopulateFields'
import { ConditionalSelectorItems, FieldConfigItemSchema } from '../../../types'
import { FieldConfigItem } from '../../../models/fieldConfig/fieldConfigItemModel'
import { ConfigSectionHeader } from '../ConfigSectionHeader'
import { RadioGroupContained } from '../../BaseComponents/RadioGroup/RadioGroupContained'
import { FieldConfig } from '../../../models/fieldConfig/fieldConfigModel'
import { ensureStringFormat } from '../../../utils'

type DataFieldDetailsProps = {
  selectedAssociation?: string
  fieldConfigItemEntity: FieldConfigItem
  existingSelectorFieldConfigItems?: FieldConfigItemSchema[]
  fieldConfigEntity: FieldConfig
  style?: React.CSSProperties
  updateFieldConfigSetting: (
    detailName: keyof FieldConfigItemSchema,
    newValue: string | string[] | number | boolean | {},
    callback?: () => void,
  ) => void
  openSelectorModal: () => void
  openConditionalSelectorModal?: (
    conditionalSelectorPriFieldValue: string,
    conditionalSelectorItems: ConditionalSelectorItems,
  ) => void
}

const DataFieldDetails = (props: DataFieldDetailsProps) => {
  const [conditionalSelectorPriFieldLabel, setConditionalSelectorPriFieldLabel] = useState('')
  const [conditionalSelectorPriFieldValue, setConditionalSelectorPriFieldValue] = useState('')
  const [shouldAutoPopulate, setShouldAutoPopulate] = useState(false)
  const [compTypePickerDisabled, setCompTypePickerDisabled] = useState(false)

  useEffect(() => {
    const autoPopulate = props.fieldConfigItemEntity.getAutoPopulate()
    if (autoPopulate && !isEmpty(autoPopulate)) {
      setShouldAutoPopulate(true)
    }
  }, [props.fieldConfigItemEntity.getAutoPopulate()])

  useEffect(() => {
    const { fieldConfigItemEntity } = props
    setCompTypePickerDisabled(fieldConfigItemEntity.getCompTypePickerDisabled())
    const conditionalSelectorItems = fieldConfigItemEntity.getConditionalSelectorItems()
    if (conditionalSelectorItems) {
      const priFieldLabel = fieldConfigEntity.getLabel(conditionalSelectorItems.priFieldKey)
      if (priFieldLabel) {
        setConditionalSelectorPriFieldLabel(priFieldLabel)
        if (!conditionalSelectorPriFieldValue) {
          setConditionalSelectorPriFieldValue(Object.keys(conditionalSelectorItems.values)[0])
        }
      }
    }
  }, [])

  const {
    fieldConfigItemEntity,
    existingSelectorFieldConfigItems,
    fieldConfigEntity,
    updateFieldConfigSetting,
    openSelectorModal,
    openConditionalSelectorModal,
  } = props

  const autoPopulate = fieldConfigItemEntity.getAutoPopulate()
  const conditionalSelectorItems = fieldConfigItemEntity.getConditionalSelectorItems()
  const compType = fieldConfigItemEntity.getCompType()
  const dateFormat = fieldConfigItemEntity.getDateFormat()
  const defaultValue = fieldConfigItemEntity.getDefaultValue()
  const selectorItems = fieldConfigItemEntity.getSelectorItems()
  const setDefaultValueFromHistory = fieldConfigItemEntity.getSetDefaultValueFromHistory()
  const allowCustomOption = fieldConfigItemEntity.getAllowCustomOption()
  const maxItems = ensureStringFormat(fieldConfigItemEntity.getMaxItems())
  const disabled = fieldConfigItemEntity.getDisabled()
  const multiLine = fieldConfigItemEntity.getMultiLine()
  const suggestionsEnabled = fieldConfigItemEntity.getSuggestionsEnabled()
  const singleSelectionMode = fieldConfigItemEntity.getSingleSelectionMode()
  const compulsory = fieldConfigItemEntity.getCompulsory()
  const fieldKey = fieldConfigItemEntity.getKey()

  const setAutoPopulateByValues = (fieldValues: string[]) => {
    const newAutoPopulateItemKeys = difference(fieldValues, Object.keys(autoPopulate))
    newAutoPopulateItemKeys.forEach((fieldValue) => {
      const autoPopulateKey = fieldValue
      setAutoPopulateFieldValue(autoPopulateKey)
    })
  }

  const setAutoPopulateFieldValue = (item: string, field?: string, value?: string, previousField?: string) => {
    const existingItemValues = autoPopulate && autoPopulate[item] ? cloneDeep(autoPopulate[item]) : {}

    if (previousField && field && value) {
      if (field === '') {
        delete existingItemValues[previousField]
        updateFieldConfigSetting('autoPopulate', {
          ...autoPopulate,
          [item]: {
            ...existingItemValues,
          },
        })
        return
      }
      if (field !== previousField) {
        let newItemValues = {}
        Object.keys(existingItemValues).forEach((key) => {
          if (key === previousField) {
            newItemValues = {
              ...newItemValues,
              [field]: '',
            }
          } else {
            newItemValues = {
              ...newItemValues,
              [key]: existingItemValues[key],
            }
          }
        })
        updateFieldConfigSetting('autoPopulate', {
          ...autoPopulate,
          [item]: {
            ...newItemValues,
          },
        })
        return
      }
    }
    const existingAutoPopulate = autoPopulate ? cloneDeep(autoPopulate) : {}
    const autoPopulateUpdate =
      field && value !== undefined
        ? {
            ...existingAutoPopulate,
            [item]: {
              ...existingItemValues,
              [field]: value,
            },
          }
        : { ...existingAutoPopulate, [item]: {} }
    updateFieldConfigSetting('autoPopulate', autoPopulateUpdate)
  }

  const removeAutoPopulateByFieldValue = (fieldValue: string) => {
    const autoPopulateUpdate = autoPopulate ? cloneDeep(autoPopulate) : {}
    if (autoPopulateUpdate.hasOwnProperty(fieldValue)) {
      delete autoPopulateUpdate[fieldValue]
      updateFieldConfigSetting('autoPopulate', autoPopulateUpdate)
    }
  }

  const getTranslatedCompType = (compType: string, dateFormat?: string) => {
    if (compType === 'conditionalSelector') {
      return 'selector (conditional)'
    }
    if (compType === 'itemSelector') {
      return 'item selector'
    }
    if (compType === 'text' && dateFormat && dateFormat.length) {
      return 'date'
    }
    return compType
  }

  const handleCompTypeChange = (compType: string) => {
    if (compType === 'date') {
      const defaultDateFormat = 'YYYY/MM/DD'
      updateFieldConfigSetting('compType', 'text')
      updateFieldConfigSetting('dateFormat', defaultDateFormat)
      return
    }
    updateFieldConfigSetting('dateFormat', '')
    if (compType === 'selector (conditional)') {
      updateFieldConfigSetting('compType', 'conditionalSelector')
      return
    }
    if (compType === 'item selector') {
      updateFieldConfigSetting('compType', 'itemSelector')
      return
    }

    updateFieldConfigSetting('compType', compType)
  }

  const handleOptionClick = () => {
    const priFieldKey = fieldConfigEntity.getKeyForLabel(conditionalSelectorPriFieldLabel) || ''
    if (conditionalSelectorPriFieldValue && openConditionalSelectorModal) {
      let values: Record<string, string[]> = {}
      if (conditionalSelectorItems) {
        values[conditionalSelectorPriFieldValue] = conditionalSelectorItems.values[conditionalSelectorPriFieldValue]
      }
      openConditionalSelectorModal(conditionalSelectorPriFieldValue, {
        priFieldKey,
        values,
      })
    }
  }

  const handleSelection = (values: string[], isMount: boolean, settingKey: string) => {
    if (!isMount) {
      updateFieldConfigSetting(settingKey as keyof FieldConfigItemSchema, values[0] === 'YES')
    }
  }

  const handleShouldAutoPopulateChange = (values: string[], isMount: boolean) => {
    if (!isMount) {
      setShouldAutoPopulate(values[0] === 'YES')
    }
  }

  const handleAutoPopulateValuePairChange = (item: string, field?: string, value?: string, previousField?: string) =>
    setAutoPopulateFieldValue(item, field, value, previousField)

  const handleRelatedFieldChange = (e: any) => {
    setConditionalSelectorPriFieldLabel(e.target.value)
    setConditionalSelectorPriFieldValue('')
  }

  const buildConditionalSelectorUi = (
    conditionalSelectorPriFieldValue: string,
    conditionalSelectorItems?: ConditionalSelectorItems,
  ) => {
    function getPriFieldSelectorItems(priFieldLabel: string) {
      let priFieldSelectorItems: string[] = []
      const priFieldKey = fieldConfigEntity.getKeyForLabel(priFieldLabel) || ''
      const priFieldConfigItemEntity = fieldConfigEntity.getFieldConfigItemEntity(priFieldKey)
      if (!priFieldConfigItemEntity) {
        return priFieldSelectorItems
      }
      return priFieldConfigItemEntity.getSelectorItems()
    }

    function getOptions() {
      if (
        conditionalSelectorItems &&
        conditionalSelectorPriFieldValue &&
        conditionalSelectorItems.values[conditionalSelectorPriFieldValue]
      ) {
        return conditionalSelectorItems.values[conditionalSelectorPriFieldValue]
      }
      return []
    }

    let priFieldSelectorItems: string[] = []
    if (conditionalSelectorPriFieldLabel) {
      priFieldSelectorItems = getPriFieldSelectorItems(conditionalSelectorPriFieldLabel)
    }

    let options: string[] = getOptions()
    return (
      <div>
        <div style={styles.sectionInput}>
          <PickerContained
            style={{ width: '100%', padding: '10px 37px 15px' }}
            label="DEPENDS ON WHICH PARENT FIELD?"
            items={existingSelectorFieldConfigItems?.map((field) => field.label).sort() || []}
            value={conditionalSelectorPriFieldLabel}
            onChange={handleRelatedFieldChange}
          />
        </div>
        <div style={styles.sectionInput}>
          <RadioGroupContained
            label={'CHOOSE PARENT FIELD VALUE'}
            value={conditionalSelectorPriFieldValue}
            options={priFieldSelectorItems.map((label) => ({
              key: label,
              label,
            }))}
            rowDisplay={true}
            disabled={!conditionalSelectorPriFieldLabel}
            onChange={(e) => setConditionalSelectorPriFieldValue(e.target.value)}
            optionsStyle={{ justifyContent: 'space-around', gap: 20 }}
            style={{ padding: '10px 37px 15px' }}
          />
        </div>
        <TwoColumnSelector
          label="THE FOLLOWING OPTIONS WILL BE PRESENTED"
          values={options}
          onClick={handleOptionClick}
        />
      </div>
    )
  }

  const translatedCompType = getTranslatedCompType(compType, dateFormat)
  const componentTypeOptions = ['date', 'item selector', 'picker', 'selector', 'selector (conditional)', 'text']

  let conditionalSelectorUi = null
  if (translatedCompType === 'selector (conditional)') {
    conditionalSelectorUi = buildConditionalSelectorUi(conditionalSelectorPriFieldValue, conditionalSelectorItems)
  }

  let selectorOrPickerUi = null
  if (['selector', 'picker', 'itemSelector'].indexOf(compType) > -1) {
    let defaultValueComponent = null
    if (defaultValue) {
      defaultValueComponent = (
        <div style={styles.sectionInput}>
          <PickerContained
            style={{
              width: '100%',
              padding: '1em 2.5em',
            }}
            value={defaultValue || ''}
            label={'Default value'}
            items={selectorItems || []}
            onChange={(e) => updateFieldConfigSetting('defaultValue', e.target.value)}
          />
        </div>
      )
    }

    let autoPopulateFieldsComponent = null
    if (selectorItems && selectorItems.length > 0) {
      autoPopulateFieldsComponent = (
        <AutoPopulateFields
          autoPopulate={autoPopulate || {}}
          shouldAutoPopulate={shouldAutoPopulate}
          selectorItems={selectorItems}
          fieldConfigEntity={fieldConfigEntity}
          onShouldAutoPopulateChange={handleShouldAutoPopulateChange}
          onFieldValuePairChange={handleAutoPopulateValuePairChange}
        />
      )
    }

    selectorOrPickerUi = (
      <>
        <TwoColumnSelector
          label="THE FOLLOWING OPTIONS WILL BE PRESENTED TO THE USER"
          values={selectorItems || []}
          onClick={openSelectorModal}
        />
        <CheckboxTabLabelled
          style={styles.sectionInput}
          itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
          label={'HAS DEFAULT VALUE?'}
          values={defaultValue ? ['yes'] : ['no']}
          selectorItems={['no', 'yes']}
          selectionHandler={(values: string[], isMount: boolean) => handleSelection(values, isMount, 'useDefaultValue')}
        />
        {defaultValueComponent}
        <CheckboxTabLabelled
          style={styles.sectionInput}
          itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
          label={'SET DEFAULT FROM HISTORY?'}
          values={setDefaultValueFromHistory ? ['yes'] : ['no']}
          selectorItems={['no', 'yes']}
          selectionHandler={(values: string[], isMount: boolean) =>
            handleSelection(values, isMount, 'setDefaultValueFromHistory')
          }
        />
        {autoPopulateFieldsComponent}
      </>
    )
  }

  let selectorCompTypeInputs = null
  if (translatedCompType === 'selector') {
    selectorCompTypeInputs = (
      <>
        <CheckboxTabLabelled
          style={styles.sectionInput}
          itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
          label={'SHOULD USERS TO ABLE TO ADD THEIR OWN OPTION TO BE SELECTED?'}
          values={allowCustomOption ? ['yes'] : ['no']}
          selectorItems={['no', 'yes']}
          selectionHandler={(values: string[], isMount) => {
            if (!isMount) {
              updateFieldConfigSetting('allowCustomOption', values[0] === 'YES')
            }
          }}
        />
        <TextInputOutlined
          style={styles.sectionInput}
          label="MAX SELECTABLE ITEMS"
          placeholder="Specify a number (default is 1)"
          value={maxItems}
          onClick={() => null}
          textHandler={(e) => {
            let value = e.target.value
            // only allow numbers as input
            if (value.match(/^[0-9]*$/)) {
              updateFieldConfigSetting('maxItems', +value)
            }
          }}
          disabled={false}
        />
      </>
    )
  }

  let dateCompTypeInputs = null
  if (translatedCompType === 'date') {
    dateCompTypeInputs = (
      <>
        <TextInputOutlined
          style={styles.sectionInput}
          label="DATE FORMAT"
          placeholder="Date format (e.g. YYYY/MM/DD)"
          value={dateFormat || ''}
          onClick={() => null}
          textHandler={(e) => {
            let value = e.target.value
            updateFieldConfigSetting('dateFormat', value)
          }}
          disabled={true}
        />
        <CheckboxTabLabelled
          style={styles.sectionInput}
          itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
          label={'SHOW AS DISABLED?'}
          values={disabled ? ['yes'] : ['no']}
          selectorItems={['no', 'yes']}
          selectionHandler={(values: string[], isMount) => {
            if (!isMount) {
              updateFieldConfigSetting('disabled', values[0] === 'YES')
            }
          }}
        />
      </>
    )
  }

  let textCompTypeInputs = null
  if (translatedCompType === 'text') {
    textCompTypeInputs = (
      <>
        <AutoPopulateFields
          fieldKey={fieldKey}
          shouldAutoPopulate={shouldAutoPopulate}
          autoPopulate={autoPopulate || {}}
          isParentTextField={true}
          fieldConfigEntity={fieldConfigEntity}
          onShouldAutoPopulateChange={(values: string[], isMount: boolean) => {
            if (!isMount) {
              setShouldAutoPopulate(values[0] === 'YES')
            }
          }}
          onFieldValuePairChange={(item: string, field?: string, value?: string, previousField?: string) =>
            setAutoPopulateFieldValue(item, field, value, previousField)
          }
          onFieldValuesChange={setAutoPopulateByValues}
          onFieldValueRemove={removeAutoPopulateByFieldValue}
        />
        <CheckboxTabLabelled
          style={styles.sectionInput}
          itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
          label={'MULTILINE?'}
          values={multiLine ? ['YES'] : ['NO']}
          selectorItems={['NO', 'YES']}
          selectionHandler={(values: string[], isMount) => {
            if (!isMount) {
              updateFieldConfigSetting('multiLine', values[0] === 'YES')
            }
          }}
        />
        <CheckboxTabLabelled
          style={styles.sectionInput}
          itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
          label={'SUGGESTIONS ENABLED?'}
          values={suggestionsEnabled ? ['YES'] : ['NO']}
          selectorItems={['NO', 'YES']}
          selectionHandler={(values: string[], isMount) => {
            if (!isMount) {
              updateFieldConfigSetting('suggestionsEnabled', values[0] === 'YES')
            }
          }}
        />
        <CheckboxTabLabelled
          style={styles.sectionInput}
          itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
          label={'SHOW AS DISABLED?'}
          values={disabled ? ['YES'] : ['NO']}
          selectorItems={['NO', 'YES']}
          selectionHandler={(values: string[], isMount) => {
            if (!isMount) {
              updateFieldConfigSetting('disabled', values[0] === 'YES')
            }
          }}
        />
      </>
    )
  }

  let itemSelectorInputs = null
  if (translatedCompType === 'item selector') {
    itemSelectorInputs = (
      <>
        <CheckboxTabLabelled
          style={styles.sectionInput}
          itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
          label={'SINGLE SELECTION MODE?'}
          values={singleSelectionMode ? ['YES'] : ['NO']}
          selectorItems={['NO', 'YES']}
          selectionHandler={(values: string[], isMount) => {
            if (!isMount) {
              updateFieldConfigSetting('singleSelectionMode', values[0] === 'YES')
            }
          }}
        />
      </>
    )
  }

  return (
    <div style={{ ...styles.section, ...props.style }}>
      <ConfigSectionHeader>FIELD SETTINGS</ConfigSectionHeader>
      <div style={styles.sectionInput}>
        <PickerContained
          style={{ width: '100%', padding: '10px 37px 15px' }}
          label="WHAT TYPE OF COMPONENT SHOULD BE USED TO CAPTURE THE DATA?"
          items={componentTypeOptions}
          value={translatedCompType}
          onChange={(e) => handleCompTypeChange(e.target.value)}
          transformToUpperCase={true}
          disabled={compTypePickerDisabled}
        />
      </div>
      {conditionalSelectorUi}
      {itemSelectorInputs}
      {selectorOrPickerUi}
      {selectorCompTypeInputs}
      {dateCompTypeInputs}
      {textCompTypeInputs}
      <CheckboxTabLabelled
        style={styles.sectionInput}
        itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
        label={'IS IT COMPULSORY FOR USERS TO ENTER A VALUE?'}
        values={compulsory ? ['YES'] : ['NO']}
        selectorItems={['NO', 'YES']}
        selectionHandler={(values: string[], isMount) => {
          if (!isMount) {
            updateFieldConfigSetting('compulsory', values[0] === 'YES')
          }
        }}
        // disabled={compType === ''}
        disabled={false}
      />
    </div>
  )
}

const styles = {
  section: {
    width: '100%',
    margin: '4% 0',
    paddingBottom: '20px',
  },
  sectionInput: {
    margin: '40px 1.5em 0px 1.5em',
  },
}

export default DataFieldDetails
