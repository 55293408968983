import { FieldConfigCompType, FieldConfigItemSchema, PpeSku } from '../../types'

export class FieldConfigItem {
  constructor(private fieldConfigItemJson: FieldConfigItemSchema) {}

  getCompTypePickerDisabled() {
    return this.fieldConfigItemJson.compTypePickerDisabled || false
  }

  getConditionalSelectorItems() {
    return this.fieldConfigItemJson.conditionalSelectorItems
  }

  getAutoPopulate() {
    return this.fieldConfigItemJson.autoPopulate || {}
  }

  getCompType() {
    return this.fieldConfigItemJson.compType
  }

  getDateFormat() {
    return this.fieldConfigItemJson.dateFormat
  }

  getKey() {
    return this.fieldConfigItemJson.key
  }

  getLabel() {
    return this.fieldConfigItemJson.label
  }

  getSuppliers() {
    return this.fieldConfigItemJson.suppliers
  }

  getDefaultValue() {
    return this.fieldConfigItemJson.defaultValue
  }

  getSetDefaultValueFromHistory() {
    return this.fieldConfigItemJson.setDefaultValueFromHistory || false
  }

  getAllowCustomOption() {
    return this.fieldConfigItemJson.allowCustomOption || false
  }

  getMaxItems() {
    return this.fieldConfigItemJson.maxItems
  }

  getDisabled() {
    return this.fieldConfigItemJson.disabled || false
  }

  getMultiLine() {
    return this.fieldConfigItemJson.multiLine || false
  }

  getSuggestionsEnabled() {
    return this.fieldConfigItemJson.suggestionsEnabled || false
  }

  getSingleSelectionMode() {
    return this.fieldConfigItemJson.singleSelectionMode || false
  }

  getCompulsory() {
    return this.fieldConfigItemJson.compulsory || false
  }

  getSelectorItems(): string[] {
    const selectorItems = this.fieldConfigItemJson.selectorItems || []
    return [...selectorItems]
  }

  getSkus() {
    if (!this.fieldConfigItemJson.hasOwnProperty('skus')) {
      return []
    }
    return this.fieldConfigItemJson.skus
  }

  getSupplierConfigForSku(sku: PpeSku) {
    const suppliers = this.getSuppliers()
    if (!suppliers) {
      return
    }
    if (!suppliers[sku]) {
      return
    }
    return suppliers[sku]
  }

  isPpeKey() {
    return this.fieldConfigItemJson.isPpe
  }

  isDateField(): boolean {
    const compType = this.getCompType()
    const dateFormat = this.getDateFormat()
    if (compType === FieldConfigCompType.TEXT && typeof dateFormat === 'string') {
      return !!dateFormat.length
    }
    return false
  }
}
