import { checkDigitsOnly } from './regexUtils'
import { toUpperCaseCustom } from './stringUtils'
import Fuse from 'fuse.js'

export const sortArrayOfObjects = <T>(
  comparisonKey: string,
  direction: 'ascending' | 'descending',
  arrayOfObjects: T[],
  sectionKey?: string,
) => {
  try {
    let x: number = 0
    let y: number = 0
    if (direction === 'descending') {
      x = -1
      y = 1
    } else {
      x = 1
      y = -1
    }

    arrayOfObjects = [...arrayOfObjects]
    const sortFunc = (a: T, b: T) => {
      let elemA = sectionKey ? (a as any)[sectionKey][comparisonKey] : (a as any)[comparisonKey]
      let elemB = sectionKey ? (b as any)[sectionKey][comparisonKey] : (b as any)[comparisonKey]
      elemA = elemA ?? ''
      elemB = elemB ?? ''

      const intA = checkDigitsOnly(elemA) ? parseInt(elemA) : null
      const intB = checkDigitsOnly(elemB) ? parseInt(elemB) : null

      if (intA && intB) {
        // Check to see whether comparisonItems are numbers
        if (intA > intB) {
          return x
        }
        if (intB > intA) {
          return y
        }
        return 0
      }

      if (elemA > elemB) {
        return y
      }
      if (elemB > elemA) {
        return x
      }
      return 0
    }

    return arrayOfObjects.sort(sortFunc)
  } catch (error) {
    return []
  }
}

export function searchArrayOfObjects<T>(searchString: string, arrayOfObjects: T[], keysToSearch: string[]): T[] {
  if (!searchString) {
    return arrayOfObjects
  }
  arrayOfObjects = arrayOfObjects.filter((item) => {
    const x = item as any
    if (x === null) {
      return false
    }
    return keysToSearch.some((searchKey: string) =>
      toUpperCaseCustom((item as any)[searchKey]).includes(toUpperCaseCustom(searchString)),
    )
  })

  return arrayOfObjects
}

export const fuzzyMatch = (possibilities: {}[], searchKey: string, fuseOptions: {}) => {
  const fuseInstance = new Fuse(possibilities, fuseOptions)
  return fuseInstance.search(searchKey)
}
