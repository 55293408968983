import React, { Component, createRef } from 'react'
import { RouteComponentProps } from 'react-router'
import Radium from 'radium'
import { connect } from 'react-redux'
import { GridSize } from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore'
import GetAppIcon from '@material-ui/icons/GetApp'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

import { ColorPalette } from '../../config/colors'
import { ActionType } from '../../store/actions/actions'
import { toLowerCaseCustom, removeUnderScores, extractDocumentComponents } from '../../utils'
import { docCategories } from '../../config'
import completedScreens from '../completedScreens'
import { APP_VERSION } from '../../cloud-config'
import { DocServiceFactory } from '../../services/docService'
import { IDocVerificationTracker, IDocVerificationItem, Profile } from '../../models'
import UpdateTrackerItemDocs from '../../models/profileUpdates/updateTrackerItemDocs'
import NavBar from '../../components/Navigation/NavBar'
import SectionHeader from '../../components/Headings/SectionHeaderPrimary'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import SideMenu from '../../components/Navigation/SideMenu'
import ProfileNavMenu from '../../components/GeneralUI/ProfileNavMenu/ProfileNavMenu'
import DataFlowNavigator from '../../components/Navigation/DataFlowNavigator'
import DocumentTable from '../../components/Tables/DocumentTable/DocumentTable'
import DocumentViewerModal from '../../components/Modals/DocumentViewerModal'
import ButtonGeneric from '../../components/BaseComponents/Buttons/ButtonGeneric'
import { PeopleFlowCombinedReducer } from '../../store'
import { ConfigService, SessionService } from '../../services'
import ButtonStatus from '../../components/BaseComponents/Buttons/ButtonStatus'
import { AssociationSettingsRepository, UsersRepository } from '../../repositories'
import { DocStatus, DocVerificationActions, TimeBasedDocStatusEnum, IamServiceErrorCodesEnum } from '../../enums'
import { IFileMeta, ISectionConfigItem } from '../../interfaces'
import { getRemoteFile, unlinkDocuments } from '../../providers/remoteDataProvider'
import {
  DocumentCategory,
  ScreenNamesEnum,
  PeopleRouteState,
  EmploymentStatus,
  DocTableColumnConfig,
  DocViewerConfig,
  NavMenuAccess,
  ProfileNavMenuAccess,
  AssociationId,
  SystemFeaturesEnum,
  SystemOperationsEnum,
} from '../../types'

const configService = new ConfigService()
const { Unauthorised } = IamServiceErrorCodesEnum
interface DocumentProps extends RouteComponentProps {
  activeDocumentPortalSection?: string
  comingFromDocValidity: boolean
  profile: Profile
  associationRepo: AssociationSettingsRepository
  selectedEmploymentStatus: string
  userRepo: UsersRepository
  profilePic: string
  currentScreen: ScreenNamesEnum
  selectedAssociation: string
  selectedRole: string
  idPassport: string
  password: string
  navMenuAccess: NavMenuAccess
  profileNavMenuAccess: ProfileNavMenuAccess
  docVerificationTracker: IDocVerificationTracker

  updateState: (data: any) => void
  changeScreen: (screen: string) => void
}

interface DocumentState {
  loadingModalOpen: boolean
  warningModalOpen: boolean
  accountModalOpen: boolean
  forgotPasswordModalOpen: boolean
  settingsModalOpen: boolean
  documentViewerModalOpen: boolean
  loadingModalMessage: string
  warningModalHeader: string
  warningModalMessage: string
  sideMenuComponents: JSX.Element
  sideMenuVisible: boolean
  tableWidth: GridSize
  sectionConfig: ISectionConfigItem[]
  currentSectionName: string
  docViewerInfo: DocViewerConfig
  selectedDocs: string[]
  expandedFolders: string[]
  selectedDoc: string
  screenHeight: number
  showDocValdiityInfo: boolean
  featureAccessFlags: Record<string, boolean>
  columnConfig: DocTableColumnConfig
}

class Documents extends Component<DocumentProps, DocumentState> {
  DocsService = DocServiceFactory.create()

  private navigatorRef: React.RefObject<HTMLDivElement> = createRef()

  initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
    accountModalOpen: false,
    forgotPasswordModalOpen: false,
    settingsModalOpen: false,
    documentViewerModalOpen: false,
    screenHeight: window.innerHeight,
  }

  state: DocumentState = {
    ...this.initialModalState,
    loadingModalMessage: '',
    warningModalHeader: '',
    warningModalMessage: '',
    sideMenuComponents: <div />,
    sideMenuVisible: true,
    tableWidth: 9,
    sectionConfig: [],
    currentSectionName: '',
    docViewerInfo: {},
    selectedDocs: [],
    expandedFolders: [],
    selectedDoc: '',
    showDocValdiityInfo: false,
    columnConfig: [],
    featureAccessFlags: { hasDocMarkingAccess: false },
  }

  componentDidMount() {
    this.props.updateState({
      currentSection: ScreenNamesEnum.PEOPLE,
      currentScreen: ScreenNamesEnum.DOCUMENTS,
    })
    const { selectedAssociation, profile, associationRepo, userRepo } = this.props
    const { name, surname, idPassport } = this.props.profile.getPersonalInfo()
    const associationEntity = associationRepo.getAssociationSettingsEntity(selectedAssociation)
    const showDocValdiityInfo = associationEntity.getDocValidityEnabledStatus()
    const featureAccessFlags = this.getFeatureAccessFlags(userRepo, selectedAssociation)
    const sectionConfig = this.getSectionConfig()
    const currentSectionName = this.getCurrentSectionName(sectionConfig)
    this.setState({ currentSectionName, showDocValdiityInfo, sectionConfig, featureAccessFlags }, () => {
      this.initialiseSideMenu(name, surname, idPassport)
      this.initialiseDocumentTable()
    })
    this.updateDocVerificationTrackers(
      DocVerificationActions.FETCH_TRACKER,
      profile.getDocFileNameStrings(selectedAssociation),
    )
  }

  getFeatureAccessFlags(userRepo: UsersRepository, association: AssociationId) {
    let featureAccessFlags = {} as Record<string, boolean>
    const iamEntity = userRepo.getCurrentUserEntity().getAssocationIamEntity(association)
    if (!iamEntity) {
      console.log("No IAM entity found on the user's profile for the selected association")
      return featureAccessFlags
    }
    featureAccessFlags.hasDocMarkingAccess = iamEntity.checkAccess(
      SystemFeaturesEnum.documents,
      SystemOperationsEnum.editDocCheckedStatus,
    )
    return featureAccessFlags
  }

  getSectionConfig() {
    try {
      const { userRepo, selectedAssociation } = this.props
      const iamEntity = userRepo.getCurrentUserEntity().getAssocationIamEntity(selectedAssociation)
      if (!iamEntity) {
        throw { code: Unauthorised }
      }
      return configService.generateSectionConfig(iamEntity)
    } catch (error) {
      throw error
    }
  }

  getCurrentSectionName(sectionConfig: ISectionConfigItem[] = []) {
    const targetSectionName = this.props.activeDocumentPortalSection || ''
    const sectionNameOptions = sectionConfig.map((sectionConfigItem) => sectionConfigItem.sectionName)
    if (sectionNameOptions.includes(targetSectionName)) {
      return targetSectionName
    }
    if (sectionNameOptions.length) {
      return sectionNameOptions[0]
    }
    return ''
  }

  closeModals() {
    this.setState({ ...this.initialModalState }, this.forceUpdate)
  }

  initialiseSideMenu(name: string, surname: string, idPassport: string) {
    try {
      const { userRepo, profilePic, currentScreen, selectedEmploymentStatus, selectedAssociation } = this.props
      const iamEntity = userRepo.getCurrentUserEntity().getAssocationIamEntity(selectedAssociation)
      if (!iamEntity) {
        throw { code: Unauthorised }
      }
      const profileNavMenuAccess = configService.generateProfileNavMenuConfig(
        iamEntity,
        selectedEmploymentStatus as EmploymentStatus,
      )
      const sideMenuComponents = (
        <ProfileNavMenu
          profilePic={profilePic}
          name={`${name} ${surname}`}
          idPassport={idPassport}
          onClick={(screen: string) => this.sideMenuHandler(screen)}
          currentScreen={currentScreen}
          accessFlags={profileNavMenuAccess}
        />
      )
      this.props.updateState({ profileNavMenuAccess })
      this.setState({ sideMenuComponents })
    } catch (error) {
      this.displayWarning(error)
    }
  }

  generateCheckboxes(selectedDocs: string[], id: string, index: string | number): JSX.Element {
    const checked = selectedDocs.includes(id)
    return (
      <Checkbox
        key={`checkbox_${index}`}
        style={{ color: checked ? ColorPalette.PRIMARY_BLUE : ColorPalette.TERTIARY_TEXT }}
        checked={checked}
        onChange={() => this.docSelectionHandler(id)}
      />
    )
  }

  generateStatusIndicators(
    fileName: string,
    validityStatus: string,
    showDocValdiityInfo: boolean,
    docMarkingParams: { hasDocMarkingAccess: boolean; isVerified: boolean },
  ) {
    const { hasDocMarkingAccess, isVerified } = docMarkingParams
    const getStatusDotColor = (validityStatus: string) => {
      const statusDotColorMap = {
        [removeUnderScores(TimeBasedDocStatusEnum.NO_EXPIRY_SET)]: ColorPalette.MEDIUM_GREY,
        [removeUnderScores(TimeBasedDocStatusEnum.VALID)]: ColorPalette.PRIMARY_BLUE,
        [removeUnderScores(TimeBasedDocStatusEnum.EXPIRES_IN_45_DAYS)]: ColorPalette.DARK_GREY,
        [removeUnderScores(TimeBasedDocStatusEnum.EXPIRED)]: ColorPalette.WARNING_RED,
      } as Record<string, string>
      if (validityStatus in statusDotColorMap) {
        return statusDotColorMap[validityStatus]
      }
      return ColorPalette.VERY_LIGHT_GREY
    }
    let docMarkingButton = null
    if (hasDocMarkingAccess) {
      docMarkingButton = (
        <ButtonStatus
          hasTriggered={isVerified}
          triggeredLabel={'VERIFIED'}
          notTriggeredLabel={'VERIFY'}
          style={{ marginLeft: 20 }}
          onClick={() =>
            this.updateDocVerificationTrackers(
              isVerified ? DocVerificationActions.REMOVE_VERIFIER : DocVerificationActions.ADD_VERIFIER,
              [fileName],
            )
          }
        />
      )
    }

    let validityInfo = null
    if (showDocValdiityInfo) {
      validityInfo = (
        <>
          <p style={styles.docStatusText}>{validityStatus}</p>
          <div style={{ ...styles.statusDot, backgroundColor: getStatusDotColor(validityStatus) }} />
        </>
      )
    }
    const component = (
      <div style={styles.statusIndicatorContainer}>
        {validityInfo}
        {docMarkingButton}
      </div>
    )
    return component
  }

  generateSideComponents(
    docViewerInfo: DocViewerConfig,
    selectedDocs: string[],
    hasDocMarkingAccess: boolean,
  ): DocViewerConfig {
    const result: DocViewerConfig = {}
    const { showDocValdiityInfo } = this.state
    for (const [categoryName, category] of Object.entries(docViewerInfo)) {
      let x: Record<string, IFileMeta[]> = {}
      for (const [foldername, filemetaarray] of Object.entries(category)) {
        x[foldername] = filemetaarray.map((meta, i) => {
          const { fileName, validityStatus = '', isVerified = false } = meta.fileInfo
          return {
            ...meta,
            leftComponent: this.generateCheckboxes(selectedDocs, fileName, i),
            rightComponent: this.generateStatusIndicators(fileName, validityStatus, showDocValdiityInfo, {
              hasDocMarkingAccess,
              isVerified,
            }),
          }
        })
      }
      result[categoryName] = x
    }
    return result
  }

  initialiseDocumentTable(): void {
    const toolBarIdComponentMap = {
      expand: <UnfoldMoreIcon style={styles.iconStyle} />,
      collapse: <UnfoldLessIcon style={styles.iconStyle} />,
      download: <GetAppIcon style={styles.iconStyle} />,
      delete: <DeleteForeverIcon style={styles.iconStyle} />,
    }
    const { hasDocMarkingAccess } = this.state.featureAccessFlags
    const { profile, docVerificationTracker, userRepo, selectedAssociation } = this.props
    const iamEntity = userRepo.getCurrentUserEntity().getAssocationIamEntity(selectedAssociation)
    if (!iamEntity) {
      throw { code: Unauthorised }
    }
    const { idPassport } = profile.getPersonalInfo()
    const columnConfig = configService.generateDocTableColumnConfig(iamEntity, toolBarIdComponentMap)
    let docViewerInfo = this.prepareDocs(profile, docVerificationTracker.getVerificationTrackerItem(idPassport))
    docViewerInfo = this.generateSideComponents(docViewerInfo, this.state.selectedDocs, hasDocMarkingAccess)
    this.setState({ docViewerInfo, columnConfig })
  }

  prepareDocs(profile: Profile, docVerificationTracker: IDocVerificationItem): DocViewerConfig {
    const generateDocNameCategoryMap = (fileMetaDataPerDocName: Record<DocumentCategory, IFileMeta[]>) => {
      let docNameCategoryMap: Record<string, string> = {}
      const docsOnProfile = Object.keys(fileMetaDataPerDocName)
      Object.values(docCategories).forEach((docCategory: any) => {
        docViewerInfo[docCategory] = {}
        const categoryDocNames = this.DocsService.extractDocGroup(
          docConfig,
          docCategory,
          [DocStatus.PUBLISHED, DocStatus.DEPUBLISHED],
          docsOnProfile,
        )
        categoryDocNames.forEach((categoryDocName: string) => (docNameCategoryMap[categoryDocName] = docCategory))
      })
      Object.keys(fileMetaDataPerDocName).forEach((docName) => {
        if (!docNameCategoryMap[docName]) {
          docNameCategoryMap[docName] = 'OTHER'
        }
      })
      return docNameCategoryMap
    }

    let docViewerInfo: DocViewerConfig = {}
    const { selectedAssociation, associationRepo } = this.props
    const docConfig = associationRepo.getDocConfig(selectedAssociation)
    const pfDocRecords = profile.getAssociationDocRecords(selectedAssociation)
    const fileMetaDataPerDocName = this.DocsService.loadDocumentViewer(pfDocRecords, docVerificationTracker)
    const docNameCategoryMap = generateDocNameCategoryMap(fileMetaDataPerDocName)

    Object.keys(docNameCategoryMap).forEach((docName) => {
      docViewerInfo[docNameCategoryMap[docName]][docName] = fileMetaDataPerDocName[docName] || []
    })
    return docViewerInfo
  }

  loadSection(currentSectionName: string) {
    this.setState({ currentSectionName } as DocumentState)
  }

  sideMenuHandler(screen: string) {
    if (completedScreens.includes(screen)) {
      this.props.updateState({ currentScreen: screen })
      this.props.history.push(`/people/${toLowerCaseCustom(screen.split(' ').join(''))}`)
    } else {
      this.props.history.push('/comingsoon')
    }
  }

  async toolbarHandler(buttonId: string): Promise<void> {
    const expandedFolders =
      (this.state.currentSectionName && Object.keys(this.state.docViewerInfo[this.state.currentSectionName])) || []

    switch (buttonId) {
      case 'expand':
        this.setState({ expandedFolders })
        break
      case 'collapse':
        this.setState({ expandedFolders: [] })
        break
      case 'download':
        if (this.state.selectedDocs.length) {
          await this.DocsService.downloadDocuments(
            this.props.selectedAssociation,
            this.props.profile.getPk(),
            this.state.selectedDocs,
          )
        } else {
          this.displayWarning({ code: 'NoDocumentSelected' })
        }
        break
      case 'delete':
        if (this.state.selectedDocs.length === 0) {
          this.displayWarning({ code: 'NoDocumentSelected' })
        }
        this.setState({
          ...this.initialModalState,
          loadingModalOpen: true,
          loadingModalMessage: 'Deleting...',
        })
        const updateItem = this.packageDocChanges(this.state.selectedDocs)
        const token = await SessionService.prepareAuthTokens(this.props.idPassport, this.props.password)
        const profile = await unlinkDocuments(
          this.props.profile,
          this.props.selectedAssociation,
          this.props.profile.getPk(),
          updateItem,
          this.state.selectedDocs,
          token,
        )
        this.props.updateState({ profile })
        setTimeout(() => {
          this.initialiseDocumentTable()
          this.closeModals()
        }, 500)
        break
    }
  }

  packageDocChanges(selectedDocs: string[]): UpdateTrackerItemDocs {
    try {
      const docChanges = selectedDocs.map((fileName) => {
        const { docFamily, docName } = extractDocumentComponents(fileName)
        const user = this.props.userRepo.getCurrentUserEntity()
        const { name, surname } = user.getPersonalUserInfo()
        const changeItem = {
          action: 'unlink',
          timestamp: new Date().getTime(),
          docFamily: docFamily,
          docName: docName.substring(0, docName.length - 4),
          fileName,
          name: name,
          surname: surname,
          userIdPassport: this.props.idPassport,
          appVersion: APP_VERSION,
        }
        return changeItem
      })

      const { name, surname, idPassport } = this.props.profile.getPersonalInfo()
      const uid = this.props.profile.getUid()
      let updateItem = new UpdateTrackerItemDocs(idPassport, uid, name, surname, 'Web browser')
      updateItem.changes.docs = docChanges
      return updateItem
    } catch (error) {
      throw error
    }
  }

  async viewDoc(docName: string) {
    this.setState({
      ...this.initialModalState,
      loadingModalOpen: true,
      loadingModalMessage: 'Downloading...',
    })
    const pk = this.props.profile.getPk()
    const filepath = `People/${pk}/Documents/${this.props.selectedAssociation}/${docName}`
    let selectedDoc = await getRemoteFile(filepath, { download: true })
    selectedDoc = new Blob([selectedDoc.Body], { type: 'application/pdf' })
    selectedDoc = URL.createObjectURL(selectedDoc)
    this.setState({
      ...this.initialModalState,
      documentViewerModalOpen: true,
      selectedDoc,
    })
  }

  docSelectionHandler(selectedDocName: string) {
    const { hasDocMarkingAccess } = this.state.featureAccessFlags
    let selectedDocs = this.state.selectedDocs
    if (selectedDocs.includes(selectedDocName)) {
      selectedDocs = selectedDocs.filter((docName) => docName !== selectedDocName)
    } else {
      selectedDocs.push(selectedDocName)
    }
    const docViewerInfo = this.generateSideComponents(this.state.docViewerInfo, selectedDocs, hasDocMarkingAccess)
    this.setState({ docViewerInfo, selectedDocs })
  }

  expandCollapseFolder(selectedFolder: string) {
    let expandedFolders = this.state.expandedFolders
    if (expandedFolders.includes(selectedFolder)) {
      expandedFolders = expandedFolders.filter((folder) => folder !== selectedFolder)
    } else {
      expandedFolders.push(selectedFolder)
    }
    this.setState({ expandedFolders })
  }

  goToProfiles() {
    this.props.updateState({})
    this.closeModals()
    window.scrollTo(0, 0)
    if (this.props.comingFromDocValidity) {
      this.props.history.goBack()
      return
    }
    const prevRouteState = this.props.location.state || {}
    setTimeout(() => this.props.history.push('/people', prevRouteState), 30)
  }

  async updateDocVerificationTrackers(action: DocVerificationActions, docNames: string[]) {
    this.setState({
      ...this.initialModalState,
      loadingModalOpen: true,
      loadingModalMessage: 'Updating...',
    })
    const {
      idPassport: userIdPassport,
      password,
      selectedAssociation,
      profile,
      userRepo,
      comingFromDocValidity,
    } = this.props
    const user = userRepo.getCurrentUserEntity()
    const { name, surname, username } = user.getPersonalUserInfo()
    const token = await SessionService.prepareAuthTokens(userIdPassport, password)
    const trackers = await this.DocsService.updateDocVerificationTrackers(
      selectedAssociation,
      action,
      profile.getPersonalInfo().idPassport,
      docNames,
      token,
      { name, surname, idPassport: username, updatedMs: new Date().getTime() },
    )
    this.props.docVerificationTracker.addTrackers(trackers)
    this.initialiseDocumentTable()
    this.closeModals()
    if (comingFromDocValidity) {
      this.toolbarHandler('expand')
    }
  }

  displayWarning(error: any) {
    let header = 'Warning'
    let warning = ''

    try {
      if (error.code === 'NetworkError') {
        warning = 'Seems like your internet connection is down. Reconnect to the network, then try again.'
      } else if (error.code === Unauthorised) {
        header = 'Not Authorised'
        warning = 'You do not have permission to perform this action.'
      } else if (error.code === 'NoDocumentSelected') {
        header = 'Select a document'
        warning = 'Select a document'
      } else if ('message' in error) {
        warning =
          "The following error message was returned when logging in:\n\n'" +
          error.message +
          "'. \n\nRefresh the page and try again. If unsuccessful, then contact tech support"
      } else {
        warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
      }
    } catch (error) {
      warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
    }

    this.setState({
      ...this.initialModalState,
      warningModalOpen: true,
      warningModalHeader: header,
      warningModalMessage: warning,
    })
  }

  render() {
    const { hasDocPortalAccess } = this.props.profileNavMenuAccess

    let documentViewerModal = null
    if (this.state.documentViewerModalOpen) {
      documentViewerModal = (
        <DocumentViewerModal
          document={{ url: this.state.selectedDoc }}
          onClick1={() => this.closeModals()}
          buttonLabel1={'Close'}
        />
      )
    }

    const tableData = (this.state.currentSectionName && this.state.docViewerInfo[this.state.currentSectionName]) || {}
    return (
      <div style={styles.container}>
        <NavBar match={this.props.match} location={this.props.location} history={this.props.history} />

        <SectionHeader
          style={styles.sectionHeader}
          disabled={true}
          onClick={() => this.setState({ sideMenuVisible: false, tableWidth: 10 })}>
          {this.props.currentScreen}
        </SectionHeader>

        <div style={styles.contentContainer}>
          <SideMenu visible={this.state.sideMenuVisible} menuComponents={this.state.sideMenuComponents} />
          <div style={styles.rightSide}>
            <ButtonGeneric
              style={{
                ...styles.backButton,
                width: (window.innerWidth * (this.state.tableWidth as number)) / 12,
              }}
              iconBefore={<ChevronLeftIcon style={styles.buttonIconStyle} />}
              label={'BACK'}
              onClick={() => this.goToProfiles()}
            />

            <div style={styles.rightSideContent}>
              <div>
                <DataFlowNavigator
                  buttonStyle={{ width: 260 }}
                  sectionConfig={this.state.sectionConfig}
                  currentSectionName={this.state.currentSectionName}
                  onSectionClick={(section: string) => this.loadSection(section)}
                  containerRef={this.navigatorRef}
                  key={`dataFlowNavigator_${this.state.currentSectionName}`}
                />
                <DocumentTable
                  style={{ borderTop: `4px solid ${ColorPalette.LIGHT_GREY}` }}
                  tableWidth={this.state.tableWidth}
                  data={tableData}
                  onToolbarClicked={(buttonid: string) => this.toolbarHandler(buttonid)}
                  onDocClicked={(docname: string) => this.viewDoc(docname)}
                  expandedFolders={this.state.expandedFolders}
                  expandCollapseFolder={(folder: string) => this.expandCollapseFolder(folder)}
                  columnConfig={this.state.columnConfig}
                  key={`documentTable_${this.state.currentSectionName}`}
                />
              </div>
            </div>
          </div>
        </div>

        {documentViewerModal}

        <AlertModalOneButton
          open={!hasDocPortalAccess}
          header={'Not Authorised'}
          body={"You don't have permission to view profile documents."}
          buttonLabel={'Ok'}
          opaqueBackground={true}
          onClick={() => this.props.history.goBack()}
        />
        <AlertModalOneButton
          open={this.state.warningModalOpen}
          header={this.state.warningModalHeader}
          body={this.state.warningModalMessage}
          buttonLabel={'Ok'}
          onClick={() => this.closeModals()}
        />
        <LoadingModal open={this.state.loadingModalOpen}>{this.state.loadingModalMessage}</LoadingModal>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.SCREEN_TOP_GRADIENT}, ${ColorPalette.SCREEN_BOTTOM_GRADIENT})`,
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1.5%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'max(2em, 2%)',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  iconStyle: {
    color: ColorPalette.CARD_WHITE,
    width: '1.1rem',
    height: '1.1rem',
    marginRight: 10,
    marginLeft: 10,
  },
  buttonIconStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    width: '1.2rem',
    height: '1.2rem',
  },
  backButton: {
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
  statusIndicatorContainer: {
    marginRight: 50,
    height: 42,
    minWidth: 250,
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  statusDot: {
    width: 10,
    height: 10,
    borderRadius: 30,
  },
  docStatusText: {
    color: ColorPalette.TERTIARY_TEXT,
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    fontWeight: 'normal',
    marginRight: 10,
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    password: state.sessionManager.password,
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
    userRepo: state.sessionManager.userRepo as UsersRepository,
    comingFromDocValidity: state.sessionManager.comingFromDocValidity,
    selectedAssociation: state.sessionManager.selectedAssociation,
    selectedRole: state.sessionManager.selectedRole,
    currentScreen: state.sessionManager.currentScreen,
    profile: state.sessionManager.profile,
    profilePic: state.sessionManager.profilePic,
    activeDocumentPortalSection: state.sessionManager.activeDocumentPortalSection,
    profileNavMenuAccess: state.sessionManager.profileNavMenuAccess,
    docVerificationTracker: state.sessionManager.docVerificationTracker,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
    changeScreen: (screen: string) => dispatch({ type: ActionType.CHANGE_SCREEN, data: { currentScreen: screen } }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(Documents))
