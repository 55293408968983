import { AssociationId, AuthCredentials } from '../types'
import { toValidPfDate } from '../utils'
import { SessionService } from './sessionService'
import { getBillingProfiles } from '../providers'

export class BillingService {
  generateBillingDates(billedDayNumber = 20, iterations = 12) {
    const MONTHS_PER_YEAR = 12
    const now = new Date()
    let currentMonthNumber = now.getMonth() + 1
    let currentYearNumber = now.getFullYear()
    let monthDateStrings = []

    for (let i = 0; i < iterations; i++) {
      monthDateStrings.push(
        toValidPfDate(
          new Date(`${currentYearNumber}-${currentMonthNumber}-${billedDayNumber}`).getTime(),
          'YYYY-MM-DD',
        ),
      )
      currentMonthNumber -= 1
      if (currentMonthNumber < 1) {
        currentMonthNumber = MONTHS_PER_YEAR
        currentYearNumber -= 1
      }
    }
    return monthDateStrings
  }

  async getBillingData(
    association: AssociationId,
    billingPeriodStart: string,
    billingPeriodEnd: string,
    authCredentials: AuthCredentials,
  ) {
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    const data = await getBillingProfiles(association, billingPeriodStart, billingPeriodEnd, token)
    const sortedResponseData = data.sort((a: any, b: any) => a.unixMs - b.unixMs)
    const peopleData = sortedResponseData.map((item: any) => ({
      date: new Date(item.unixMs).toISOString(),
      employees: item.employees,
      candidates: item.candidates,
    }))
    return peopleData
  }
}
