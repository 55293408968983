export type AssociationId = string
export type CohortName = string
export type Username = string
export enum TrashStatus {
  NONE = 'NONE',
  TRASHED = 'TRASHED',
  PERMANENT = 'PERMANENT',
}
export enum EmploymentStatus {
  EMPLOYEE = 'EMPLOYEE',
  CANDIDATE = 'CANDIDATE',
}
export enum DateFormatConvention {
  UK = 'UK',
  US = 'US',
}

export type Filepath = string
