import { Component } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'

import Grid, { GridSize } from '@material-ui/core/Grid'

import { ColorPalette } from '../../config/colors'
import { ActionType } from '../../store/actions/actions'
import { NavMenuAccess, ScreenNamesEnum } from '../../types'
import { SessionService } from '../../services'
import NavBar from '../../components/Navigation/NavBar'
import SectionHeader from '../../components/Headings/SectionHeaderPrimary'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import Timeline from '../../components/GeneralUI/Timeline/Timeline'
import DeviceLogBlock from '../../components/Logs/DeviceLogBlock'
import MultiListViewerModal from '../../components/Modals/MultiListViewerModal'
import { RouteComponentProps } from 'react-router'
import { PeopleFlowCombinedReducer } from '../../store'
import { DeviceLog } from '../../components/Logs/DeviceLogBlock'
import { Profile } from '../../models'
import { ProfileRepository } from '../../repositories'
import { fetchDeviceLogs, fetchProfileData } from '../../providers/remoteDataProvider'

interface DevicesProps extends RouteComponentProps {
  selectedAssociation: string
  idPassport: string
  password: string
  currentScreen: ScreenNamesEnum
  navMenuAccess: NavMenuAccess
  profileRepo: ProfileRepository

  updateState: (data: any) => void
  changeScreen: (screen: string) => void
}

interface DevicesState {
  loadingModalOpen: boolean
  warningModalOpen: boolean
  unsyncedChangesTrackerModalOpen: boolean
  loadingModalMessage: string
  warningModalHeader: string
  warningModalMessage: string
  sideMenuVisible: boolean
  tableWidth: GridSize
  deviceInfo: any[]
  listViewerData: Record<string, string[]>
  logs?: any
}

const deviceLogKeyMap = {
  adHocDocProfilePksToSync: 'Adhoc Doc Records',
  competencyProfilePksToSync: 'Pre-requisite Records',
  discActionProfilePksToSync: 'Disciplinary Action Records',
  discPhotoProfilePksToSync: 'Disciplinary Photos',
  docProfilePksToSync: 'PDF Document Files',
  employmentProfilePksToSync: 'Employment Records',
  faceShotProfilePksToSync: 'Profile Pics',
  rawDataProfilePksToSync: 'Profile Data',
} as Record<string, string>

class Devices extends Component<DevicesProps, DevicesState> {
  initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
    unsyncedChangesTrackerModalOpen: false,
  }

  state: DevicesState = {
    ...this.initialModalState,
    loadingModalMessage: '',
    warningModalHeader: '',
    warningModalMessage: '',
    sideMenuVisible: true,
    tableWidth: 10,
    deviceInfo: [],
    logs: [],
    listViewerData: {},
  }

  componentDidMount() {
    try {
      this.props.updateState({
        currentSection: ScreenNamesEnum.GLOBAL_LOGS,
        currentScreen: ScreenNamesEnum.DEVICE_LOGS,
      })
      this.refreshDeviceLogs()
    } catch (error) {
      this.closeModals()
    }
  }

  closeModals = () => {
    this.setState({ ...this.initialModalState })
  }

  createLogsComponents(logs: DeviceLog[]) {
    const LOGS_PER_LINE = 2
    let logComponents = [] as JSX.Element[]
    let lineItem = [] as JSX.Element[]
    logs.forEach((logItem, index) => {
      lineItem.push(
        <DeviceLogBlock
          index={index}
          logData={logItem}
          openProfileListViewer={() => this.openProfileListViewer(logItem)}
        />,
      )
      const isLastLog = index === logs.length - 1
      if (lineItem.length === LOGS_PER_LINE || isLastLog) {
        logComponents.push(
          <div style={{ ...styles.logLineContainer }} key={`deviceLog_${index}`}>
            {lineItem}
          </div>,
        )
        lineItem = []
      }
    })
    return logComponents
  }

  async refreshDeviceLogs() {
    try {
      this.setState({
        loadingModalOpen: true,
        loadingModalMessage: 'Refreshing device logs',
      })

      const { idPassport, password, selectedAssociation } = this.props
      const token = await SessionService.prepareAuthTokens(idPassport, password)
      const logs: DeviceLog[] = await fetchDeviceLogs(selectedAssociation, token)

      let allProfilePks: string[] = []
      logs.forEach((log: DeviceLog) => {
        Object.keys(deviceLogKeyMap).forEach((key: string) => {
          //@ts-ignore
          const listItems = log[key]
          allProfilePks = [...allProfilePks, ...listItems]
        })
      })
      allProfilePks = [...new Set(allProfilePks)] // remove duplicates

      this.fetchLatestProfilesToCache(allProfilePks)

      const logsUi = this.createLogsComponents(logs)
      this.setState({
        ...this.initialModalState,
        logs: logsUi,
      })
    } catch (error) {
      this.closeModals()
      throw error
    }
  }

  async fetchLatestProfilesToCache(pks: string[]) {
    const { idPassport, password, selectedAssociation, profileRepo } = this.props

    const token = await SessionService.prepareAuthTokens(idPassport, password)
    return fetchProfileData(selectedAssociation, pks, token)
      .then((newProfiles: Record<string, Profile>) => {
        profileRepo.updateProfiles(newProfiles)
      })
      .catch((err) => {
        console.error(err)
        return {}
      })
  }

  formatList(items: string[]) {
    const { profileRepo } = this.props

    const getProfileDetails = (pk: string) => {
      const profile: Profile | undefined = profileRepo.getProfileEntity(pk)
      if (!profile) {
        return pk
      }
      const { name, surname, idPassport } = profile.getPersonalInfo()
      return `${idPassport} - ${name || '?'} ${surname || '?'}`
    }

    return items.map((item) => {
      return getProfileDetails(item)
    })
  }

  openProfileListViewer(selectedLogData: DeviceLog) {
    let listViewerData = {} as Record<string, string[]>
    Object.keys(deviceLogKeyMap).forEach((listKey) => {
      const listLabel = deviceLogKeyMap[listKey]
      //@ts-ignore
      const listItems = selectedLogData[listKey]
      listViewerData[listLabel] = this.formatList(listItems)
    })
    this.setState({
      unsyncedChangesTrackerModalOpen: true,
      listViewerData,
    })
  }

  displayWarning(error: any) {
    let warning = ''

    try {
      if (error.code === 'NetworkError') {
        warning = 'Seems like your internet connection is down. Reconnect to the network, then try again.'
      } else if ('message' in error) {
        warning =
          "The following error message was returned when logging in:\n\n'" +
          error.message +
          "'. \n\nRefresh the page and try again. If unsuccessful, then contact tech support"
      } else {
        warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
      }
    } catch (error) {
      warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
    }

    this.setState({
      ...this.initialModalState,
      warningModalOpen: true,
      warningModalHeader: 'Warning',
      warningModalMessage: warning,
    })
  }

  render() {
    const { hasDeviceLogsAccess } = this.props.navMenuAccess

    let profileTimestampModal = null
    const { listViewerData } = this.state
    if (this.state.unsyncedChangesTrackerModalOpen) {
      profileTimestampModal = (
        <MultiListViewerModal
          open={true}
          title="Unsynced Data"
          listData={listViewerData}
          onClick={() => this.closeModals()}
        />
      )
    }

    return (
      <div style={styles.container}>
        <NavBar match={this.props.match} location={this.props.location} history={this.props.history} />

        <SectionHeader
          style={{ marginTop: 25 }}
          labelStyle={{ paddingLeft: 0 }}
          disabled={true}
          onClick={() => this.setState({ sideMenuVisible: false, tableWidth: 10 })}>
          {this.props.currentScreen}
        </SectionHeader>

        <div
          style={{
            width: '100%',
            alignSelf: 'center',
            marginLeft: window.innerWidth * 0.16,
          }}>
          <Grid
            style={styles.outerCard}
            item
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            xs={this.state.tableWidth}>
            <Timeline logs={this.state.logs} />
          </Grid>
        </div>

        {profileTimestampModal}

        <AlertModalOneButton
          open={this.state.warningModalOpen}
          header={this.state.warningModalHeader}
          body={this.state.warningModalMessage}
          buttonLabel={'Ok'}
          onClick={() => this.closeModals()}
        />
        <AlertModalOneButton
          open={!hasDeviceLogsAccess}
          header={'Not Authorised'}
          body={"You don't have permission to view employee/candidate info."}
          buttonLabel={'Ok'}
          opaqueBackground={true}
          onClick={() => this.props.history.goBack()}
        />
        <LoadingModal open={this.state.loadingModalOpen}>{this.state.loadingModalMessage}</LoadingModal>
      </div>
    )
  }
}

const styles = {
  container: {
    minHeight: window.innerHeight,
    maxHeight: window.innerHeight,
    display: 'flex',
    flexDirection: 'column' as 'column',
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.SCREEN_TOP_GRADIENT}, ${ColorPalette.SCREEN_BOTTOM_GRADIENT})`,
    overflowX: 'hidden' as 'hidden',
    overflowY: 'hidden' as 'hidden',
  },
  outerCard: {
    zIndex: 1,
    alignSelf: 'center',
    backgroundColor: ColorPalette.CARD_WHITE,
    minHeight: window.innerHeight * 0.76,
    marginTop: window.innerHeight * 0.08,
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    overflow: 'hidden',
  },
  logLineContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-start',
    width: '100%',
    paddingLeft: '2.5%',
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    password: state.sessionManager.password,
    selectedAssociation: state.sessionManager.selectedAssociation,
    currentScreen: state.sessionManager.currentScreen,
    profile: state.sessionManager.profile,
    navMenuAccess: state.sessionManager.navMenuAccess,
    profileRepo: state.sessionManager.profileRepo as ProfileRepository,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) =>
      dispatch({
        type: ActionType.UPDATE_STATE,
        data,
      }),
    changeScreen: (screen: string) => dispatch({ type: ActionType.CHANGE_SCREEN, data: { currentScreen: screen } }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(Devices))
