import React from 'react'
import { mdiPencil } from '@mdi/js'

import { ColorPalette } from '../../../config/colors'
import { GraphParameter } from './GraphParameter'
import CheckboxTabLabelled from '../../BaseComponents/Checkboxes/CheckboxTabLabelled'
import TextInputLabelled from '../../BaseComponents/Text/TextInputLabelled'
import CalendarRangeContained from '../../BaseComponents/Calendar/CalendarRangeContained'
import { toUpperCaseCustom } from '../../../utils'
import { AggregationTypesEnum } from '../../../types'

interface DataQueryTrendsProps {
  profileOptionsLoading: boolean
  aggregateBy: AggregationTypesEnum
  graphName: string
  dateRange: Record<string, Date>
  numberOfAggregationOptions: number
  numberOfSelectedProfiles: number
  selectedAssociation?: string
  style?: React.CSSProperties
  onGraphNameChange: (value: string) => void
  onDateRangeChange: (value: Record<string, Date>) => void
  onAggregateByChange: (value: AggregationTypesEnum) => void
  toggleFieldSelection: () => void
  toggleProfileSelection: () => void
}

const DataQueryGraphConfig = (props: DataQueryTrendsProps) => {
  const {
    aggregateBy,
    graphName,
    dateRange,
    numberOfAggregationOptions,
    numberOfSelectedProfiles,
    onGraphNameChange,
    onDateRangeChange,
    onAggregateByChange,
    toggleFieldSelection,
    toggleProfileSelection,
    style,
  } = props

  function aggregationTypeHandler(values: string[]) {
    if (!values.length) {
      return
    }
    onAggregateByChange(values[0] as AggregationTypesEnum)
  }

  return (
    <div style={style}>
      <div style={styles.section}>
        <div style={styles.graphParameters}>
          <TextInputLabelled
            inputProps={{
              color: ColorPalette.PRIMARY_TEXT,
              fontSize: '0.8rem',
            }}
            key="graphName"
            label="GRAPH NAME"
            value={graphName}
            defaultValue={'Enter graph name'}
            textHandler={(value: string, isMount?: boolean) => {
              if (!isMount) {
                onGraphNameChange(toUpperCaseCustom(value))
              }
            }}
            primaryStyle={{ width: '94%', fontSize: '0.8rem' }}
            focusOnMount={true}
          />
          <GraphParameter
            isLoading={props.profileOptionsLoading}
            label="PROFILES TO INCLUDE"
            placeholder={`${numberOfSelectedProfiles} PROFILES`}
            onClick={toggleProfileSelection}
            iconPath={mdiPencil}
            key="profilesToInclude"
          />
          <GraphParameter
            label="FIELDS TO AGGREGATE"
            placeholder={`${numberOfAggregationOptions} FIELDS`}
            onClick={toggleFieldSelection}
            iconPath={mdiPencil}
            key="fieldsToAggregate"
          />
          <CheckboxTabLabelled
            style={{ width: '100%', marginTop: 0 }}
            itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
            label={'AGGREGATE BY'}
            values={[aggregateBy]}
            selectorItems={Array.from(Object.values(AggregationTypesEnum))}
            selectionHandler={(values: string[]) => aggregationTypeHandler(values)}
          />
          <CalendarRangeContained
            label="SHOW RESULTS IN RANGE"
            dateRange={dateRange}
            onDateChange={(date?: number, rangePoint?: string) => {
              if (!date || !rangePoint) {
                return
              }
              const newDateRange = { ...dateRange, [rangePoint]: new Date(date) }
              onDateRangeChange(newDateRange)
            }}
            minDate={new Date(2023, 0, 1)}
            maxDate={new Date()}
            orientation="horizontal" // TODO: make horisontal when screen is wide enough
            inputStyle={styles.dateRangeInput}
            textStyle={styles.dateRangeText}
            style={{ width: '100%', marginTop: 0 }}
          />
        </div>
      </div>
    </div>
  )
}

const styles = {
  section: {
    width: '100%',
    paddingBottom: '20px',
  },
  sectionHeader: {
    marginTop: 0,
    fontSize: '1rem',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    textAlign: 'left' as 'left',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid' as 'solid',
    borderBottomColor: ColorPalette.PRIMARY_BLUE,
    padding: '0em 0.5em 0.5em 0.5em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  graphParameters: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap: '2.25em',
    marginTop: '1em',
  },
  dateRangeInput: {
    border: 'none',
    transform: 'scale(0.9)',
    padding: 0,
  },
  dateRangeText: {
    marginTop: '0.75rem',
    marginBottom: 0,
    padding: 0,
    fontSize: '0.85em',
  },
}

export default DataQueryGraphConfig
