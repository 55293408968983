import _ from 'lodash'
import {
  getAnalyticsConfigs,
  updateAnalyticsConfig,
  createAnalyticsConfig,
  getAnalyticsResults,
  triggerAnalyticsResultsGeneration,
} from '../providers'
import { ensureArrayFormat } from '../utils'
import {
  DateRangeKey,
  DocFilterTimeRange,
  FieldConfigItemSchema,
  FieldOperation,
  FilterUiListItem,
  Query,
  QueryFilter,
  QueryResults,
  ResultExportTargetEmails,
} from '../types'
import { QUERY_OPERATIONS_TO_KEY } from '../constants'
import { FieldConfig } from '../models/fieldConfig/fieldConfigModel'

export class QueryService {
  static async getQueries(association: string, username: string, password: string): Promise<Query[]> {
    const { data } = await getAnalyticsConfigs(association, username, password)
    const queries = data.allConfigs as Query[]
    return queries
  }

  static async updateQuery(association: string, username: string, password: string, query: Query): Promise<Query> {
    const data = await updateAnalyticsConfig(association, username, password, query)
    return data.config
  }

  static async createQuery(association: string, queryName: string, username: string, password: string): Promise<Query> {
    const { data } = await createAnalyticsConfig(association, queryName, username, password)
    const { blobStoreDir, pk, sk, ...query } = data.config
    return query
  }

  static async getQueryResults(
    fieldConfig: Record<string, FieldConfigItemSchema>,
    association: string,
    queryId: string,
    unixMs: number = 0,
    username: string,
    password: string,
  ): Promise<QueryResults> {
    const insertTrendWarningLabels = (queryResults: QueryResults) => {
      queryResults.trends = queryResults.trends.map((trend) => {
        let warningFieldLabels = [] as string[]
        trend.trendData.forEach((item) => {
          item.warnings.forEach(({ key }) => {
            if (fieldConfig[key] && !warningFieldLabels.includes(fieldConfig[key].label)) {
              warningFieldLabels.push(fieldConfig[key].label)
            }
          })
        })
        trend.warningFieldLabels = warningFieldLabels
        return trend
      })
      return queryResults
    }

    const data = await getAnalyticsResults(association, queryId, unixMs, username, password)
    return insertTrendWarningLabels(data)
  }

  static async triggerResults(association: string, queryId: string, username: string, password: string) {
    await triggerAnalyticsResultsGeneration(association, queryId, username, password)
  }

  static async exportResults(
    association: string,
    queryId: string,
    username: string,
    password: string,
    emailAddresses: ResultExportTargetEmails,
  ) {
    await triggerAnalyticsResultsGeneration(association, queryId, username, password, emailAddresses)
  }

  static filterUiListItemsToQueryFilter = (
    filterUiListItems: FilterUiListItem[][],
    fieldConfigEntity: FieldConfig,
  ): QueryFilter[][] => {
    return filterUiListItems.map((orBlock: FilterUiListItem[]) => {
      return orBlock.map((filter: FilterUiListItem) => {
        const { comparatorType, filterType, filterValue, selectedTarget } = filter
        let translatedFilter: Partial<QueryFilter> = {
          filterType,
          filterParams: undefined,
        }
        if (filterType === 'field') {
          const filterParams = {
            targetPath: [''],
            operation: { comparatorType: '', comparatorValue: filterValue },
          }
          const targetKey = fieldConfigEntity.getKeyForLabel(selectedTarget)
          filterParams.targetPath = ensureArrayFormat(targetKey)
          filterParams.operation = {
            comparatorType: QUERY_OPERATIONS_TO_KEY[comparatorType] || comparatorType,
            comparatorValue: filterValue,
          } as FieldOperation

          // @ts-ignore
          translatedFilter.filterParams = filterParams
        }

        if (filterType === 'document') {
          const filterParams = {
            targetDoc: '',
            timeRange: 'NONE' as DocFilterTimeRange,
          }

          filterParams.targetDoc = selectedTarget
          if (filterValue) {
            const startDate = (filterValue as Record<DateRangeKey, Date>).startDate
            const endDate = (filterValue as Record<DateRangeKey, Date>).endDate
            filterParams.timeRange = {
              startUnixMs: new Date(startDate).getTime(),
              endUnixMs: new Date(endDate).getTime(),
            }
          }
          translatedFilter.filterParams = filterParams
        }

        return translatedFilter as QueryFilter
      })
    })
  }
}
