import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { mdiFilePlus, mdiPlaylistPlus } from '@mdi/js'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { ColorPalette } from '../../config/colors'
import NavBar from '../../components/Navigation/NavBar'
import SectionHeader from '../../components/Headings/SectionHeaderPrimary'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import DataTable from '../../components/Tables/DataTable/DataTable'
import { AsyncTaskService, DocsService, ProfileService } from '../../services'
import { PeopleFlowCombinedReducer } from '../../store/reducers'
import { AssociationSettingsRepository, ProfileRepository, UsersRepository } from '../../repositories'
import { Profile } from '../../models'
import { AsyncTaskDocExport, EmploymentStatus, ProfilePk } from '../../types'
import { Toolbar } from '../../components/GeneralUI/Toolbar'
import DownloadQueueModal from '../../components/Modals/DownloadQueueModal'

dayjs.extend(relativeTime)

const profileService = new ProfileService()
const asyncTaskService = new AsyncTaskService()
const docsService = new DocsService()

const TABLE_COLUMN_CONFIG = [
  { id: 'idPassport', label: 'ID / Passport', sizeFactor: 1, iconComponent: <div /> },
  { id: 'name', label: 'Name', sizeFactor: 1, iconComponent: <div /> },
  { id: 'surname', label: 'Surname', sizeFactor: 1, iconComponent: <div /> },
  { id: 'employmentStatus', label: 'Status', sizeFactor: 1, iconComponent: <div /> },
]

type EmployeeCardsProps = {
  match: any
  location: any
  history: any
}

export const EmployeeCards = (props: EmployeeCardsProps) => {
  const dataTableRef = useRef<DataTable>(null)

  const [tableData, setTableData] = useState<Record<string, any>[]>([])
  const [loadingModalOpen, setLoadingModalOpen] = useState(false)
  const [loadingModalMessage, setLoadingModalMessage] = useState('')
  const [loadingModalAuxMessage, setLoadingModalAuxMessage] = useState(' ')
  const [warningModalOpen, setWarningModalOpen] = useState(false)
  const [warningModalHeader, setWarningModalHeader] = useState('')
  const [warningModalMessage, setWarningModalMessage] = useState('')
  const [asyncTaskQueue, setAsyncTaskQueue] = useState<AsyncTaskDocExport[]>([])
  const [downloadQueueModalOpen, setDownloadQueueModalOpen] = useState(false)
  const [itemsDownloading, setItemsDownloading] = useState<string[]>([])
  const [isUpdatingQueue, setIsUpdatingQueue] = useState(false)

  const [searchString, setSearchString] = useState('')

  const idPassport = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.idPassport)
  const password = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.password)
  const userRepo = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.userRepo) as UsersRepository
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository

  const profileRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.profileRepo,
  ) as ProfileRepository

  useEffect(() => {
    loadProfileData()
  }, [])

  const loadProfileData = async () => {
    try {
      setLoadingModalOpen(true)
      await profileService.downloadMissingAssociationProfiles(
        associationRepo,
        profileRepo,
        { username: idPassport, password },
        selectedAssociation,
        (progress: string) => {
          setLoadingModalMessage('Fetching profiles...')
          setLoadingModalAuxMessage(progress)
        },
      )
      const allProfiles = profileRepo.getAllProfiles()
      const tableData = prepareProfileDataForDisplay(allProfiles)
      setTableData(tableData)
      if (!dataTableRef?.current) {
        return
      }
      dataTableRef.current.reload()
    } catch (error: any) {
      displayWarning(error)
    }
    setLoadingModalOpen(false)
  }

  const prepareProfileDataForDisplay = (allProfiles: Record<ProfilePk, Profile> | undefined): Record<string, any>[] => {
    if (allProfiles === undefined) {
      return []
    }
    let tableData: Record<string, any>[] = []
    const profiles = Object.values(allProfiles)
    profiles.forEach((profile: Profile) => {
      const generalData = profile.getGeneralData() ?? { employmentStatus: EmploymentStatus.CANDIDATE }
      const { name, surname, idPassport } = profile.getPersonalInfo()
      const returnData: Record<string, any> = {
        id: profile.getPk(),
        name,
        surname,
        idPassport,
        employmentStatus: generalData.employmentStatus,
      }
      customProfileFilterConfig.forEach(({ key, label }) => (returnData[key] = profile.getGeneralDataValue(key)))
      tableData.push(returnData)
    })
    return tableData
  }

  const searchHandler = (event: ChangeEvent<{ value: string }>) => {
    setSearchString(event.target.value)
    if (!dataTableRef?.current) {
      return
    }
    dataTableRef.current.search(event.target.value)
  }

  const displayWarning = (error: any) => {
    let header = 'Warning'
    let warning = ''

    try {
      switch (error.code) {
        case 'EmployeeCardGenerationError':
          warning = 'We encountered a problem while generating the employee cards. Refresh the page and try again.'
          break
        case 'EmployeeCardFetchError':
          warning = 'We encountered a problem while fetching the employee cards. Refresh the page and try again.'
          break
        case 'NetworkError':
          warning = 'Seems like your internet connection is down. Reconnect to the network, then try again.'
          break
        case 'NoSelection':
          header = 'None selected'
          warning = 'Select at least one profile to continue.'
          break
        case 'DownloadInProgress':
          header = 'Donwload in progress'
          warning = 'There is already a download in progress. Wait for it to finish then try again.'
          break
        case 'message' in error:
          warning =
            "The following error message was returned when logging in:\n\n'" +
            error.message +
            "'. \n\nRefresh the page and try again. If unsuccessful, then contact tech support"
          break
        default:
          warning =
            'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
          break
      }
    } catch (error) {
      warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
    }

    setWarningModalOpen(true)
    setWarningModalHeader(header)
    setWarningModalMessage(warning)
  }

  const handleWarningModalOnClick = () => {
    setWarningModalOpen(false)
  }

  const generateCardsForSelection = async () => {
    const selectedProfiles = dataTableRef.current?.getSelectedRows() ?? {}
    const selectedProfilePks = Object.keys(selectedProfiles)

    if (selectedProfilePks.length === 0) {
      displayWarning({ code: 'NoSelection' })
      return
    }

    setLoadingModalMessage('Triggering card generation...')
    setLoadingModalOpen(true)

    const user = userRepo?.getCurrentUserEntity()
    const { name = '', surname = '' } = user?.getPersonalUserInfo()

    try {
      await asyncTaskService.createEmployeeCardExport(
        selectedAssociation,
        { username: idPassport, password },
        selectedProfilePks,
        name,
        surname,
      )
    } catch (error) {
      displayWarning({ code: 'EmployeeCardGenerationError' })
    }

    setLoadingModalOpen(false)
  }

  const updateQueueData = async () => {
    try {
      const items: AsyncTaskDocExport[] = await asyncTaskService.getEmployeeCardExportBatchItems(selectedAssociation, {
        username: idPassport,
        password,
      })
      setAsyncTaskQueue(items)
    } catch (error) {
      displayWarning({ code: 'EmployeeCardFetchError' })
    }
    setIsUpdatingQueue(false)
  }

  const handleViewQueue = async () => {
    setDownloadQueueModalOpen(true)
    setIsUpdatingQueue(true)
    await updateQueueData()
  }

  const downloadItem = async (filePath: string, queueId: string) => {
    try {
      if (itemsDownloading.length && !itemsDownloading.includes(queueId)) {
        throw { code: 'DownloadInProgress' }
      }
      let newItemsDownloading = [...new Set([...itemsDownloading, queueId])]
      setItemsDownloading(newItemsDownloading)
      if (filePath.includes('public')) {
        filePath = filePath.replace('public/', '')
      }
      await docsService.downloadDocBatch(filePath)
      newItemsDownloading = newItemsDownloading.filter((existingQueueId) => existingQueueId !== queueId)
      setItemsDownloading(newItemsDownloading)
      await asyncTaskService.deleteBatchItem(selectedAssociation, queueId, { username: idPassport, password })
    } catch (error) {
      displayWarning(error)
    }
  }

  let downloadQueueModal = null
  if (downloadQueueModalOpen) {
    downloadQueueModal = (
      <DownloadQueueModal
        open={downloadQueueModalOpen}
        queuedTasks={asyncTaskQueue || []}
        itemsDownloading={itemsDownloading || []}
        itemsBeingGenerated={[]}
        header={'Download Queue'}
        isUpdatingDocExportQueue={isUpdatingQueue}
        updateQueueData={() => updateQueueData()}
        download={(filePath: string, queueId: string) => downloadItem(filePath, queueId)}
        dismiss={() => setDownloadQueueModalOpen(false)}
      />
    )
  }

  const customProfileFilterConfig = associationRepo.getProfileFilterConfig(selectedAssociation)
  customProfileFilterConfig.push({ key: 'employmentStatus', label: 'EMPLOYMENT STATUS' })

  const tableNavButtons = [
    {
      label: 'VIEW QUEUE',
      iconPath: mdiPlaylistPlus,
      onClick: () => handleViewQueue(),
    },
    {
      label: 'GENERATE',
      iconPath: mdiFilePlus,
      onClick: () => generateCardsForSelection(),
    },
  ]

  return (
    <div style={styles.container}>
      <NavBar match={props.match} location={props.location} history={props.history} />
      <SectionHeader
        style={styles.sectionHeader}
        searchString={searchString}
        textHandler={searchHandler}
        onClick={() => ({})}>
        Employee Cards
      </SectionHeader>

      <div style={styles.contentContainer}>
        <div style={styles.rightSide}>
          <Toolbar actionButtons={tableNavButtons} />
          <div style={styles.rightSideContent}>
            <DataTable
              ref={dataTableRef}
              tableData={tableData}
              columnConfig={TABLE_COLUMN_CONFIG}
              tableWidth={10}
              onRowClick={(rowID) => ({})}
              selectedRowItemId={''}
              selectionEnabled={true}
              customFilterConfig={customProfileFilterConfig}
              disabled={true}
            />
          </div>
        </div>
      </div>
      {downloadQueueModal}
      <AlertModalOneButton
        open={warningModalOpen}
        header={warningModalHeader}
        body={warningModalMessage}
        buttonLabel={'Ok'}
        onClick={handleWarningModalOnClick}
      />
      <LoadingModal
        open={loadingModalOpen}
        loadingMessage={loadingModalMessage}
        auxilliaryMessage={loadingModalAuxMessage}
      />
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column' as 'column',
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.SCREEN_TOP_GRADIENT}, ${ColorPalette.SCREEN_BOTTOM_GRADIENT})`,
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1.5%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'min(5em, 5%)',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    backgroundColor: ColorPalette.CARD_WHITE,
  },
}
