import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { difference, isEmpty, pick, pickBy } from 'lodash'
import equal from 'deep-equal'

import TextInputOutlined from '../../BaseComponents/Text/TextInputOutlined'
import AlertModalOneButton from '../../Modals/AlertModalOneButton'
import AlertModalTwoButton from '../../Modals/AlertModalTwoButton'
import InfoCollectorModal from '../../Modals/InfoCollector'
import ModalPortal from '../../Modals/ModalPortal'
import LoadingModal from '../../Modals/LoadingModal'
import { ActionType } from '../../../store/actions/actions'
import { PeopleFlowCombinedReducer } from '../../../store'
import { getConfiguredProcessInfo, removeUnderScores } from '../../../utils'
import { ConfigService, SessionService } from '../../../services'
import { AssociationSettingsRepository } from '../../../repositories'
import { DocConfigItem } from '../../../models'

interface IDataAndDocsModalProps {
  open: boolean
  selectedPrimaryKey: string
  selectedSecondaryKey: string
  selectedJobType: string
  selectedProcessName: string
  associationRepo: AssociationSettingsRepository
  selectedAssociation: string
  selectedOffence?: string
  selectedOffenceAction?: string
  idPassport: string
  password: string
  updateState: (obj: any) => void
  dismiss: () => void
  onSaveClick?: () => void
}

type SaveSnapshot = {
  unsavedItem?: string
  allItemsInclUnsaved?: string[]
}

const DataAndDocsModal = (props: IDataAndDocsModalProps) => {
  const processConfig = props.associationRepo.getProcessConfig(props.selectedAssociation)
  const history = useHistory()

  const {
    open,
    dismiss,
    selectedPrimaryKey,
    selectedSecondaryKey,
    selectedProcessName,
    selectedJobType,
    associationRepo,
    selectedAssociation,
    idPassport,
    password,
    onSaveClick,
  } = props

  const [selectorIdentifier, setSelectorIdentifier] = useState('')
  const [selectorItems, setSelectorItems] = useState<string[]>([])
  const [docNames, setDocNames] = useState<string[]>([])
  const [dataFlowNames, setDataFlowNames] = useState<string[]>([])
  const [savingModalOpen, setSavingModalOpen] = useState(false)
  const [savePromptModalOpen, setSavePromptModalOpen] = useState<SaveSnapshot | {}>({})

  const sourceData = {
    division: '',
    jobType: selectedJobType,
    jobSubType: '',
    offence: selectedPrimaryKey,
    action: selectedSecondaryKey,
  }

  useEffect(() => {
    let docNames: string[] = []
    let dataFlowNames: string[] = []
    if (selectedPrimaryKey && selectedSecondaryKey) {
      docNames = getConfiguredProcessInfo('docNames', selectedProcessName, sourceData, processConfig) as string[]
      dataFlowNames = getConfiguredProcessInfo(
        'dataFlowNames',
        selectedProcessName,
        sourceData,
        processConfig,
      ) as string[]

      if (docNames.constructor === Object && isEmpty(docNames)) {
        // this condition will be truthy if no allActions object was found at the lowest level when extracting config
        docNames = []
      }
      if (dataFlowNames.constructor === Object && isEmpty(dataFlowNames)) {
        dataFlowNames = []
      }

      setDocNames(docNames)
      setDataFlowNames(dataFlowNames)
    }
  }, [])

  const closeSelectorModal = () => {
    changeSelectorIdentifier('')
  }

  const toggleSavingModalOpen = () => {
    setSavingModalOpen((savingModalOpen) => !savingModalOpen)
  }

  const changeSelectorIdentifier = (identifier: string) => {
    setSelectorIdentifier(identifier)
    if (identifier === '') {
      setSelectorItems([])
    }
    if (identifier === 'docNames') {
      setSelectorItems([...docNames])
    }
    if (identifier === 'dataFlowNames') {
      const readableDataFlowNames = dataFlowNames.map((name: string) => removeDataFlowPartAndUpperCase(name))
      setSelectorItems([...readableDataFlowNames])
    }
  }

  const removeDataFlowPartAndUpperCase = (name: string) =>
    name.replace('dataFlow___', '').replace(/___/g, ' ').toUpperCase()
  const restoreOriginalDataFlowName = (name: string) =>
    // this is hacky and will need a discussion to agree on naming conventions for data flows TODO
    `dataFlow___${name
      .replace(/\w+/g, (word: string) =>
        ['ALL', 'EMPLOY', 'DISCIPLINE', 'EMPLOYEE', 'CANDIDATE', 'PROMOTE', 'TRANSFER', 'TERMINATE'].indexOf(word) > -1
          ? word.charAt(0) + word.slice(1).toLowerCase()
          : word,
      )
      .replace(/\s/g, '___')}`

  const saveSelectorOptions = async (dataFlowNamesSnapShot?: string[]) => {
    toggleSavingModalOpen()
    const currentDocNames = getConfiguredProcessInfo(
      'docNames',
      selectedProcessName,
      sourceData,
      processConfig,
    ) as string[]

    const currentDataFlowNames = getConfiguredProcessInfo(
      'dataFlowNames',
      selectedProcessName,
      sourceData,
      processConfig,
    ) as string[]
    if (!equal(docNames, currentDocNames)) {
      await saveDocsToSignToProcessConfig(selectedJobType, docNames)
    }

    const flowNames = dataFlowNamesSnapShot || dataFlowNames
    if (!equal(flowNames, currentDataFlowNames)) {
      const response = await saveDataFlowsToProcessConfig(selectedJobType, flowNames)
      const newDataFlowNames = difference(flowNames, currentDataFlowNames)
      if (response.result === 'success') {
        associationRepo.setProcessConfigItem(selectedAssociation, pick(response.updatedConfig, selectedProcessName))
      }
      await Promise.all(newDataFlowNames.map(async (dataFlowName: string) => await saveNewDataFlow(dataFlowName)))
    }
    toggleSavingModalOpen()
    dismiss()
  }

  const saveNewDataFlow = async (newDataFlowName: string) => {
    const changes = [
      {
        editedBy: idPassport,
        updatePath: [],
        updatedData: {
          dataFlow: {},
          sectionConfig: [],
        },
        updatedMs: +new Date(),
      },
    ]

    const response = await ConfigService.updateConfig(
      selectedAssociation,
      'dataFlow',
      changes,
      { username: idPassport, password },
      newDataFlowName,
    )

    if (response.result === 'success') {
      associationRepo.setDataFlowConfig(selectedAssociation, newDataFlowName, response.updatedConfig)
    }
  }

  const saveDocsToSignToProcessConfig = async (jobType: string, docNames: string[]) => {
    const divisionPath = 'ADMIN' // "allDivisions"
    const jobTypePath = jobType === '' ? 'allJobTypes' : jobType

    let updatePath = [selectedProcessName, 'docCaptureConfig', divisionPath, jobTypePath, 'allJobSubTypes', 'docNames']
    const { selectedOffence, selectedOffenceAction } = props
    if (selectedOffence) {
      const secondToLastIndex = updatePath.length - 1
      updatePath.splice(secondToLastIndex, 0, ...[selectedOffence, selectedOffenceAction || 'allActions'])
    }
    const changes = [
      {
        editedBy: idPassport,
        updatePath,
        updatedData: docNames,
        updatedMs: +new Date(),
      },
    ]
    return ConfigService.updateConfig(selectedAssociation, 'processConfig', changes, {
      username: idPassport,
      password,
    })
  }

  const saveDataFlowsToProcessConfig = async (jobType: string, dataFlowNames: string[]) => {
    const divisionPath = 'ADMIN' // "allDivisions"
    const jobTypePath = jobType === '' ? 'allJobTypes' : jobType

    let updatePath = [
      selectedProcessName,
      'dataCaptureConfig',
      divisionPath,
      jobTypePath,
      'allJobSubTypes',
      'dataFlowNames',
    ]
    if (props.selectedOffence) {
      const secondToLastIndex = updatePath.length - 1
      const { selectedOffence, selectedOffenceAction } = props
      updatePath.splice(secondToLastIndex, 0, ...[selectedOffence, selectedOffenceAction || 'allActions'])
    }
    const changes = [
      {
        editedBy: idPassport,
        updatePath,
        updatedData: dataFlowNames,
        updatedMs: +new Date(),
      },
    ]
    return ConfigService.updateConfig(selectedAssociation, 'processConfig', changes, {
      username: idPassport,
      password,
    })
  }

  const handleSelection = (items: string[]) => {
    if (selectorIdentifier === 'docNames') {
      setDocNames(items)
    }
    if (selectorIdentifier === 'dataFlowNames') {
      const dataFlowItems = items.map((name: string) => restoreOriginalDataFlowName(name))
      setDataFlowNames(dataFlowItems)
    }
    closeSelectorModal()
  }

  const docIsPublished = (docObj: DocConfigItem) => !isEmpty(docObj.published)

  const saveChangesAndNavigateToDataFlow = async () => {
    // @ts-ignore
    const { unsavedItem, allItemsInclUnsaved } = savePromptModalOpen
    const dataFlowItems = allItemsInclUnsaved.map((name: string) => restoreOriginalDataFlowName(name))
    setDataFlowNames(dataFlowItems)
    setSavePromptModalOpen({})
    await saveSelectorOptions(dataFlowItems)
    if (onSaveClick) {
      onSaveClick()
    }
    history.push(`/configurator/dataflows/${unsavedItem}`)
  }

  const handleItemNavigateClick = (item: string, items: string[]) => {
    const dataFlowName = restoreOriginalDataFlowName(item)
    if (!availableDataFlows[dataFlowName]) {
      setSavePromptModalOpen({
        unsavedItem: item,
        allItemsInclUnsaved: items,
      })
    } else {
      history.push(`/configurator/dataflows/${item}`)
    }
  }

  const docConfig = associationRepo.getDocConfig(selectedAssociation)
  const availableDocsToChooseFrom = pickBy(docConfig, docIsPublished)
  const availableDataFlows = associationRepo.getAllDataFlows(selectedAssociation)

  let pickerItems: string[] = []
  if (selectorIdentifier) {
    if (selectorIdentifier === 'docNames') {
      pickerItems = Object.keys(availableDocsToChooseFrom)
    }
    if (selectorIdentifier === 'dataFlowNames') {
      const readableDataFlowNames = Object.keys(availableDataFlows).map((name: string) =>
        removeDataFlowPartAndUpperCase(name),
      )
      pickerItems = ['+ Add new flow', ...readableDataFlowNames]
    }
    if (selectorItems.length > 0) {
      pickerItems = difference(pickerItems, selectorItems)
    }
  }

  const getInfoCollectorHeader = (selectorIdentifier: string): string => {
    return selectorIdentifier === 'dataFlowNames' ? 'DATA FLOW SELECTION' : 'DOC SELECTION'
  }

  const getInfoCollectorSubHeader = (selectorIdentifier: string): string => {
    if (selectorIdentifier === 'dataFlowNames') {
      return `Which data flows should be used for capturing data during for this offence? (If you choose more than one, they will be merged together)`
    }
    return `Which docs should be captured for this offence?`
  }

  let selectionModal = null
  if (selectorIdentifier !== '') {
    selectionModal = (
      <ModalPortal>
        <InfoCollectorModal
          open={true}
          defaultItems={selectorItems.sort()}
          pickerItems={pickerItems.sort()}
          header={getInfoCollectorHeader(selectorIdentifier)}
          subHeader={getInfoCollectorSubHeader(selectorIdentifier)}
          warningMessage="Add at least one item"
          validateInput={() => true}
          placeholder={selectorIdentifier === 'dataFlowNames' ? 'Select data flow' : 'Select a doc'}
          successLabel="Update"
          minimumItems={1} // this could be changed to 0 if necessary to remove a last item, allowing a empty list
          dismiss={closeSelectorModal}
          onSuccess={handleSelection}
          onReject={closeSelectorModal}
          type="picker"
          addOnFirstItemClick={selectorIdentifier === 'dataFlowNames'}
          onItemNavigateClick={selectorIdentifier === 'dataFlowNames' ? handleItemNavigateClick : undefined}
          itemAddTransform={(item: string) => item.replace(/\s/g, '___').replace(/___/g, ' ').toUpperCase()}
        />
      </ModalPortal>
    )
  }

  let saveInProgressModal = null
  if (savingModalOpen) {
    saveInProgressModal = (
      <ModalPortal>
        <LoadingModal open={true} style={{ zIndex: 20000 }}>
          Saving changes...
        </LoadingModal>
      </ModalPortal>
    )
  }

  let saveConfirmModal = null
  if (savePromptModalOpen.hasOwnProperty('unsavedItem')) {
    saveConfirmModal = (
      <ModalPortal>
        <AlertModalTwoButton
          open={true}
          header="Save flow(s) recently added?"
          body="Saving is necessary before navigation to the flow details page is possible"
          dismiss={() => setSavePromptModalOpen({})}
          onClick1={() => setSavePromptModalOpen({})}
          onClick2={saveChangesAndNavigateToDataFlow}
          buttonLabel1="Cancel"
          buttonLabel2="Save changes"
        />
      </ModalPortal>
    )
  }

  return (
    <AlertModalOneButton
      open={open}
      header={removeUnderScores(`${selectedPrimaryKey} | ${selectedSecondaryKey}`)}
      subHeader="Choose which data and documents should be completed"
      body={
        <>
          <TextInputOutlined
            style={styles.sectionInput}
            label="DOCUMENTS THAT MUST BE SIGNED"
            placeholder="Click to add options"
            value={docNames.join('; ')}
            onClick={() => changeSelectorIdentifier('docNames')}
            textHandler={() => ''}
            disabled={false}
            type="textarea"
          />
          <TextInputOutlined
            style={{ ...styles.sectionInput, marginTop: 35 }}
            label="DATA CAPTURE FLOW TO USE"
            placeholder="Click to add options"
            value={dataFlowNames.map((name) => removeDataFlowPartAndUpperCase(name)).join('; ')}
            onClick={() => changeSelectorIdentifier('dataFlowNames')}
            textHandler={() => ''}
            disabled={false}
            type="textarea"
          />
          {selectionModal}
          {saveConfirmModal}
          {saveInProgressModal}
        </>
      }
      buttonLabel="Save"
      containerStyle={{ width: '35%', marginLeft: '-6%' }}
      onClick={async () => {
        await saveSelectorOptions()
        if (onSaveClick) {
          onSaveClick()
        }
      }}
      dismiss={dismiss}
    />
  )
}

const styles = {
  sectionInput: {
    padding: '0 1em 1em',
    width: window.innerWidth * 0.26,
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    password: state.sessionManager.password,
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
    selectedAssociation: state.sessionManager.selectedAssociation,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataAndDocsModal)
