import { AssociationId, ProfilePk } from '../types'

export class FilePathProvider {
  static getRemoteProfilePicFilePath(profilePk: ProfilePk) {
    return `People/${profilePk}/FaceShots/center`
  }

  static bulkTerminationPlanFilePath(association: AssociationId, triggerUnixMs: string) {
    // return `s3://peopleflow-service-prod-general-storage/public/Companies/${association}/Imports/${triggerUnixMs}`
    return `Companies/${association}/Imports/${triggerUnixMs}`
  }
}
