import { useState, useEffect, memo } from 'react'
import { useSelector } from 'react-redux'

import NavBar from '../../components/Navigation/NavBar'
import SectionHeaderPrimary from '../../components/Headings/SectionHeaderPrimary'

import { ColorPalette } from '../../config/colors'
import SideMenu from '../../components/Navigation/SideMenu'
import BillingSideMenu from '../../components/SideMenus/BillingSideMenu'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'

import { PeopleFlowCombinedReducer } from '../../store'
import { CountsBillingDataObject } from '../../types'
import { BillingDetails } from '../../components/Billing/BillingDetails'
import LoadingModal from '../../components/Modals/LoadingModal'
import { BillingService } from '../../services/billingService'
import { PickerHandlerEvent } from '../../components/BaseComponents/Pickers/Picker'

const billingService = new BillingService()

type BillingProps = {
  match: any
  location: any
  history: any
  selectedAssociation: string
}

const Billing = (props: BillingProps) => {
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const idPassport = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.idPassport)
  const password = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.password)
  const navMenuAccess = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.navMenuAccess)

  const [selectedCategory, setSelectedCategory] = useState('ALL')
  const [billingDateOptions, setBillingDateOptions] = useState([] as string[])
  const [selectedBillingDate, setSelectedBillingDate] = useState<string>('')
  const [billingItemList, setBilledItemList] = useState<CountsBillingDataObject[]>([])
  const [loadingModalOpen, setLoadingModalOpen] = useState(false)

  useEffect(() => {
    initialise()
  }, [])

  const initialise = async () => {
    setLoadingModalOpen(true)
    const billingDateOptions = billingService.generateBillingDates()
    const billingPeriodEnd = billingDateOptions[0]
    const billingPeriodStart = billingDateOptions[1]
    const billedItems = await billingService.getBillingData(selectedAssociation, billingPeriodStart, billingPeriodEnd, {
      username: idPassport,
      password,
    })
    setBillingDateOptions(billingDateOptions)
    setSelectedBillingDate(billingPeriodEnd)
    setBilledItemList(billedItems)
    setLoadingModalOpen(false)
  }

  const billingDateSelectionHandler = async (event: PickerHandlerEvent) => {
    const billingDateOptionsPlusOne = billingService.generateBillingDates(20, 13) // 13 months of billing data in case the selected date happens to be at index 0 of the original list of 12 entries
    const billingPeriodEnd = event.target.value
    const billingPeriodStart = billingDateOptionsPlusOne[billingDateOptionsPlusOne.indexOf(billingPeriodEnd) + 1]
    setLoadingModalOpen(true)
    const billedItems = await billingService.getBillingData(selectedAssociation, billingPeriodStart, billingPeriodEnd, {
      username: idPassport,
      password,
    })
    setSelectedBillingDate(billingPeriodEnd)
    setBilledItemList(billedItems)
    setLoadingModalOpen(false)
  }

  let billingCategories = ['ALL']
  if (billingItemList.length) {
    billingCategories = [...billingCategories, 'CANDIDATES', 'EMPLOYEES']
  }

  let billingAmountTotal = '0'
  let maxCandidatesInPeriod = 0
  let maxEmployeesInPeriod = 0
  const maxEmployeesItem = billingItemList[billingItemList.length - 1]
  if (maxEmployeesItem) {
    billingAmountTotal = (maxEmployeesItem.candidates.total + maxEmployeesItem.employees.total).toFixed(2)
    maxEmployeesInPeriod = maxEmployeesItem.employees.quantity
    billingItemList.forEach((item: any) =>
      item.candidates.quantity > maxCandidatesInPeriod ? (maxCandidatesInPeriod = item.candidates.quantity) : null,
    )
  }

  const candidateBar = {
    label: 'Candidates',
    data: billingItemList.map((item: any) => item.candidates.quantity),
    backgroundColor: ColorPalette.PRIMARY_BLUE,
  }
  const employeeBar = {
    label: 'Employees',
    data: billingItemList.map((item: any) => item.employees.quantity),
    backgroundColor: ColorPalette.DARK_GREY,
  }

  const lineStyle = { backgroundColor: 'rgba(0,0,0,0)', borderWidth: 3, radius: 0 }
  const candidateLine = {
    type: 'line',
    label: 'Max Candidates',
    // @ts-ignore
    data: [...billingItemList].fill(maxCandidatesInPeriod),
    borderColor: ColorPalette.PRIMARY_BLUE_TRANSPARENT,
    ...lineStyle,
  }
  const employeeLine = {
    type: 'line',
    label: 'Max Employees',
    // @ts-ignore
    data: [...billingItemList].fill(maxEmployeesInPeriod),
    borderColor: ColorPalette.DARK_GREY_TRANSPARENT,
    ...lineStyle,
  }

  let graphDataSets = [candidateBar, employeeBar, candidateLine, employeeLine]
  if (selectedCategory === 'CANDIDATES') {
    graphDataSets = [candidateBar, candidateLine]
  }
  if (selectedCategory === 'EMPLOYEES') {
    graphDataSets = [employeeBar, employeeLine]
  }

  return (
    <div style={styles.container}>
      <NavBar match={props.match} location={props.location} history={props.history} />
      <SectionHeaderPrimary style={styles.sectionHeader} disabled={true} searchString={''} onClick={() => ({})}>
        Billing
      </SectionHeaderPrimary>

      <div style={styles.contentContainer}>
        <SideMenu
          visible={true}
          menuComponents={
            <BillingSideMenu
              key={`billingSideMenu_${billingDateOptions.join('')}`}
              billingAmountTotal={billingAmountTotal}
              billingCategories={billingCategories}
              selectedCategory={selectedCategory}
              selectedBillingDate={selectedBillingDate}
              billingDateOptions={billingDateOptions}
              onBillingCategoryClick={(category: string) => setSelectedCategory(category)}
              onBillingDateChange={billingDateSelectionHandler}
            />
          }
        />
        <BillingDetails
          data={billingItemList}
          dataSets={graphDataSets}
          maxCandidatesInPeriod={
            selectedCategory === 'ALL' || selectedCategory === 'CANDIDATES' ? maxCandidatesInPeriod : undefined
          }
          maxEmployeesInPeriod={
            selectedCategory === 'ALL' || selectedCategory === 'EMPLOYEES' ? maxEmployeesInPeriod : undefined
          }
          key={`billingDetails_${selectedCategory}`}
        />

        <AlertModalOneButton
          // TODO: There should only be one AlertModalOneButton component per screen with the required headers, body, and functions being set accordingly by a handler.
          open={!navMenuAccess.hasBillingAccess}
          header={'Not Authorised'}
          body={"You don't have permission to view billing information."}
          buttonLabel={'Ok'}
          opaqueBackground={true}
          onClick={() => props.history.goBack()}
        />
      </div>
      <LoadingModal open={loadingModalOpen}>Loading billing data...</LoadingModal>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.SCREEN_TOP_GRADIENT}, ${ColorPalette.SCREEN_BOTTOM_GRADIENT})`,
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1.5%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
}

export default memo(Billing)
