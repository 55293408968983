import { CommonTs } from '../../models/common-ts/commonts'
import { AssociationId } from '../../types'
import { httpGet, httpPost } from '../remoteDataProvider'

export async function generateTerminationPlan(
  association: AssociationId,
  triggerUnixMs: string,
  inputFileName: string,
  bulkActionConfig: CommonTs.Import.IPlanConfig,
  token: string,
) {
  const payload: any = { bulkActionConfig, inputFileName }
  const { data } = await httpPost(
    'people_flow_core',
    `/imports/bulktermination/${association}/${triggerUnixMs}`,
    payload,
    token,
  )
  return data
}

export async function checkForTerminationPlanResults(association: AssociationId, triggerUnixMs: string, token: string) {
  const { data } = await httpGet('people_flow_core', `/imports/bulktermination/${association}/${triggerUnixMs}`, token)
  return data
}
