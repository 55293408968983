import { has } from 'lodash'
import { Iam } from '../models'
import { AssociationSettingsRepository } from '../repositories'
import { AssociationId, CohortName, NavMenuAccess, SystemFeaturesEnum, SystemOperationsEnum } from '../types'

export class IamService {
  identifyLoginAssociationCohort(
    preferrableAssociation: AssociationId,
    preferrableCohort: CohortName,
    allAssociationIamSettings: Record<AssociationId, Iam>,
    associationSettingsRepo: AssociationSettingsRepository,
  ) {
    let allAssociations = associationSettingsRepo.getAllAssociationIds()
    if (preferrableAssociation) {
      allAssociations = [...new Set([preferrableAssociation, ...allAssociations])]
    }
    function checkWorkforceAccess(associations: AssociationId[], allIamSettings: Record<AssociationId, Iam>) {
      for (const association of associations) {
        try {
          if (!allIamSettings[association]) {
            continue
          }
          let associationCohorts = associationSettingsRepo.getAssociationSettingsEntity(association).getAllCohorts()
          if (preferrableCohort) {
            associationCohorts = [...new Set([preferrableCohort, ...associationCohorts])]
          }
          for (const cohort of associationCohorts) {
            const hasCohortsAccess = allIamSettings[association].checkAccess(
              SystemFeaturesEnum.cohorts,
              /*
                We don't check a specific operation, as the web logs into any screen/page. 
                This is unlike the app where it logs directly into the profile listing page 
                requiring us to check for listEmployeesInCohort or listCandidatesInCohort permission.
             */
              undefined,
              cohort,
            )
            if (hasCohortsAccess) {
              return { association, cohort }
            }
          }
        } catch (error) {
          console.log('Skipping association check: ', association)
        }
      }
      throw { code: 'NoWorkforceAccess' }
    }

    function checkDocValidityAccess(associations: AssociationId[], allIamSettings: Record<AssociationId, Iam>) {
      for (const association of associations) {
        const hasDocValidityAccess = allIamSettings[association].checkAccess(
          SystemFeaturesEnum.reporting,
          SystemOperationsEnum.viewDocumentValidity,
        )
        if (hasDocValidityAccess) {
          return { association, cohort: '' }
        }
      }
      return null
    }

    const workforceAccessResult = checkWorkforceAccess(allAssociations, allAssociationIamSettings)
    if (workforceAccessResult) {
      return workforceAccessResult
    }

    const docValidityAccess = checkDocValidityAccess(allAssociations, allAssociationIamSettings)
    if (docValidityAccess) {
      return docValidityAccess
    }
    throw { code: 'NoWorkforceAccess' }
  }

  buildNavMenuAccess(iamEntity: Iam, cohort: CohortName): NavMenuAccess {
    const hasDataLogsAccess = iamEntity.checkAccess(
      SystemFeaturesEnum.cohorts,
      SystemOperationsEnum.viewProfileLogs,
      cohort,
    )
    const hasDeviceLogsAccess = iamEntity.checkAccess(
      SystemFeaturesEnum.deviceManagement,
      SystemOperationsEnum.viewDeviceLogs,
    )
    const hasDocValidityAccess = iamEntity.checkAccess(
      SystemFeaturesEnum.reporting,
      SystemOperationsEnum.viewDocumentValidity,
    )
    const hasDocumentLibraryAccess = iamEntity.checkAccess(
      SystemFeaturesEnum.documentBuilder,
      SystemOperationsEnum.viewDocumentLibrary,
    )
    const hasDocExportAccess = iamEntity.checkAccess(
      SystemFeaturesEnum.documents,
      SystemOperationsEnum.downloadBulkProfileDocuments,
    )
    const hasImportAccess = iamEntity.checkAccess(SystemFeaturesEnum.imports, SystemOperationsEnum.all)
    const hasExportAccess = iamEntity.checkAccess(SystemFeaturesEnum.exports, SystemOperationsEnum.all)
    const hasRequestHandlerAccess = iamEntity.checkAccess(SystemFeaturesEnum.requests, SystemOperationsEnum.all)
    const hasUserManagementAccess = iamEntity.checkAccess(SystemFeaturesEnum.users, SystemOperationsEnum.all)
    const hasTrashAccess = iamEntity.checkAccess(
      SystemFeaturesEnum.cohorts,
      SystemOperationsEnum.listTrashInCohort,
      cohort,
    )
    const hasEmployeesAccess = iamEntity.checkAccess(
      SystemFeaturesEnum.cohorts,
      SystemOperationsEnum.listEmployeesInCohort,
      cohort,
    )
    const hasCandidatesAccess = iamEntity.checkAccess(
      SystemFeaturesEnum.cohorts,
      SystemOperationsEnum.listCandidatesInCohort,
      cohort,
    )

    const hasBulkTerminationAccess = iamEntity.checkAccess(
      SystemFeaturesEnum.termination,
      SystemOperationsEnum.editTerminationBulk,
    )
    const hasBulkDisciplineAccess = iamEntity.checkAccess(
      SystemFeaturesEnum.discipline,
      SystemOperationsEnum.editDisciplineBulk,
    )
    const hasDataFlowConfigAccess = iamEntity.checkAccess(
      SystemFeaturesEnum.systemConfiguration,
      SystemOperationsEnum.editDataFlowConfig,
    )
    const hasFieldConfigAccess = iamEntity.checkAccess(
      SystemFeaturesEnum.systemConfiguration,
      SystemOperationsEnum.editFieldConfig,
    )
    const hasProcessConfigAccess = iamEntity.checkAccess(
      SystemFeaturesEnum.systemConfiguration,
      SystemOperationsEnum.editProcessConfig,
    )
    const hasJsonConfigFileAccess = iamEntity.checkAccess(
      SystemFeaturesEnum.systemConfiguration,
      SystemOperationsEnum.editJsonConfigFiles,
    )
    const hasBillingAccess = iamEntity.checkAccess(
      SystemFeaturesEnum.billing,
      SystemOperationsEnum.viewBillingDashboard,
    )
    const hasGroupAccess = iamEntity.checkAccess(SystemFeaturesEnum.groups, SystemOperationsEnum.all)
    const hasPpeStockConfigAccess = iamEntity.checkAccess(
      SystemFeaturesEnum.ppe,
      SystemOperationsEnum.editPpeStockConfig,
    )
    const hasPpeStockAccess = iamEntity.checkAccess(SystemFeaturesEnum.ppe, SystemOperationsEnum.editPpeStock)
    const hasPpeRequestAccess = iamEntity.checkAccess(
      SystemFeaturesEnum.ppe,
      SystemOperationsEnum.createPpeIssueRequest,
    )
    const hasAdvancedDataQueryAccess = iamEntity.checkAccess(
      SystemFeaturesEnum.advancedDataQueries,
      SystemOperationsEnum.viewDataQueries,
    )

    return {
      hasDataLogsAccess,
      hasDeviceLogsAccess,
      hasDocExportAccess,
      hasDocValidityAccess,
      hasDocumentLibraryAccess,
      hasImportAccess,
      hasExportAccess,
      hasRequestHandlerAccess,
      hasUserManagementAccess,
      hasCandidatesAccess,
      hasEmployeesAccess,
      hasTrashAccess,
      hasBulkDisciplineAccess,
      hasBulkTerminationAccess,
      hasDataFlowConfigAccess,
      hasFieldConfigAccess,
      hasProcessConfigAccess,
      hasJsonConfigFileAccess,
      hasBillingAccess,
      hasGroupAccess,
      hasPpeStockAccess,
      hasPpeStockConfigAccess,
      hasPpeRequestAccess,
      hasAdvancedDataQueryAccess,
    }
  }
}
