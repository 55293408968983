import { useState, useEffect, useRef } from 'react'
import { difference } from 'lodash'

import { ColorPalette } from '../../../config/colors'
import { FieldValueInput } from './FieldValueInput'
import { FieldConfig } from '../../../models/fieldConfig/fieldConfigModel'
import { AutoPopKey, AutoPops, FieldConfigKey } from '../../../types'
import { ensureStringFormat } from '../../../utils'

type AutoPopulatedFieldConfiguratorProp = {
  fieldConfigEntity: FieldConfig
  title: string | React.ReactElement
  allAvailableFieldKeys: FieldConfigKey[]
  autoPops: AutoPops
  style?: React.CSSProperties
  setFieldValuePair: (fieldKey: string, value: string, previousFieldKey?: string) => void
}

export const AutoPopulatedFieldConfigurator = (props: AutoPopulatedFieldConfiguratorProp) => {
  const { title, fieldConfigEntity, allAvailableFieldKeys, autoPops, setFieldValuePair } = props
  const [inputRowInFocus, setInputRowInFocus] = useState('')

  useEffect(() => {
    if (inputRowInFocus && valueInputRef?.current) {
      valueInputRef.current.focus()
    }
  }, [inputRowInFocus])

  const onFieldValuePairChange = (label: string, value: string, previousFieldKey?: string) => {
    const fieldKey = fieldConfigEntity.getKeyForLabel(label)
    if (!fieldKey) {
      return
    }
    setFieldValuePair(fieldKey, value, previousFieldKey)
  }

  const isMissingAutoPopulateValues = (autoPops: AutoPops, autoPopKeys: AutoPopKey[]) => {
    for (const key of autoPopKeys) {
      if (!autoPops[key]) {
        return true
      }
    }
    return false
  }

  const valueInputRef = useRef<HTMLInputElement>(null)
  const autoPopKeys = Object.keys(autoPops) as AutoPopKey[]
  const fieldsNotYetSelected = difference(allAvailableFieldKeys, Object.keys(autoPops))

  let newAutoPopValueInput = null
  // if (isMissingAutoPopulateValues(autoPops, autoPopKeys) || !autoPopKeys.length) {
  //   newAutoPopValueInput = (
  //     <FieldValueInput
  //       currentFieldValue={newFieldSelection}
  //       value={autoPops[newFieldSelection] || ''}
  //       fieldOptions={fieldsNotYetSelected.map((field) => fieldConfigEntity.getLabel(field))}
  //       onFieldValuePairChange={(label: string, value: string) => onFieldValuePairChange(label, value)}
  //       key={`fieldValueInput_NEW`}
  //     />
  //   )
  // }
  return (
    <div style={{ ...styles.container, ...styles.sectionInput, ...props.style }}>
      <p style={styles.label}>{title}</p>
      {autoPopKeys.map((autoPopKey: string) => (
        <FieldValueInput
          ref={inputRowInFocus === autoPopKey ? valueInputRef : null}
          currentFieldValue={fieldConfigEntity.getLabel(autoPopKey)}
          value={ensureStringFormat(autoPops[autoPopKey])}
          fieldOptions={allAvailableFieldKeys.map((field) => fieldConfigEntity.getLabel(field))}
          onFieldValuePairChange={(label: string, value: string) => onFieldValuePairChange(label, value, autoPopKey)}
          onValueInputClick={() => setInputRowInFocus(autoPopKey)}
          key={`fieldValueInput_${autoPopKey}`}
        />
      ))}
      {newAutoPopValueInput}
    </div>
  )
}

const styles = {
  container: {
    zIndex: 1,
    marginTop: 20,
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    border: `1px solid rgba(195,205,225,0.6)`,
    borderRadius: 16,
    paddingBottom: 15,
    paddingInline: 20,
  },
  label: {
    color: ColorPalette.LIGHT_GREY,
    backgroundColor: ColorPalette.CARD_WHITE,
    zIndex: 2,
    marginTop: -8,
    alignSelf: 'center',
    fontSize: '0.65rem',
    paddingLeft: 10,
    paddingRight: 10,
  },
  sectionInput: {
    margin: '40px 1.5em 0px 1.5em',
  },
}
