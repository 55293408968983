import { Profile, ProfilePk } from '../models'
import { profileIdentifier } from '../config/localStorage'
import { LocalStorageProvider } from '../providers'
import { AssociationId, CohortName, TrashStatus } from '../types'
import { normaliseStringForComparison } from '../utils'

export class ProfileRepository {
  private profileEntities: Record<ProfilePk, Profile> = {}
  private association: AssociationId | undefined

  async loadLocalProfileEntities(association: AssociationId) {
    this.association = association
    const associationProfilesStorageIdentifier = this.getLocalStorageKeyPrefix(this.association)
    const profileStorageKeys = await LocalStorageProvider.listDataKeys(associationProfilesStorageIdentifier)
    for (const key of profileStorageKeys) {
      try {
        const profileData = await LocalStorageProvider.getData(key)
        if (!profileData) {
          continue
        }
        const [prefix, pk] = key.split(profileIdentifier)
        this.profileEntities[pk] = new Profile(profileData)
      } catch (error) {
        console.log('loadLocalProfileEntities error: ', error)
      }
    }
  }

  getAssociation() {
    return this.association
  }

  getAssociationProfileIdentifier() {
    return `${this.association}#${profileIdentifier}`
  }

  // isHydratedFromProfileCache() {
  //   return this.hydrated
  // }

  async clearProfiles() {
    this.profileEntities = {}
    // this.hydrated = false
  }

  async deleteProfilesFromCache() {
    const associationProfilesStorageIdentifier = this.getAssociationProfileIdentifier()
    const profileStorageKeys = await LocalStorageProvider.listDataKeys(associationProfilesStorageIdentifier)
    await LocalStorageProvider.deleteMany(profileStorageKeys)
    // this.hydrated = false
  }

  getProfileEntity(pk: ProfilePk) {
    if (!this.profileEntities[pk]) {
      console.error("Profile doesn't exist", this.association, pk)
      // throw { code: 'ProfileDoesntExist', pk }
      return
    }
    return this.profileEntities[pk]
  }

  getProfileByIdPassport(idPassport: string) {
    const profileEntityList = Object.values(this.profileEntities)
    for (const profileEntity of profileEntityList) {
      if (normaliseStringForComparison(profileEntity.getIdPassport()) === normaliseStringForComparison(idPassport)) {
        return profileEntity
      }
    }
    console.error("Profile doesn't exist", this.association, 'idPassport', idPassport)
    // throw { code: 'ProfileDoesntExist' }
    return
  }

  getAllProfileIdPassports() {
    return Object.values(this.profileEntities).map((profileEntity: Profile) => profileEntity.getIdPassport())
  }

  getAllProfiles(): Record<ProfilePk, Profile> {
    if (!this.profileEntities) {
      throw { code: 'ProfileRepoNotInitialised' }
    }
    return this.profileEntities
  }

  getLocalStorageKeyPrefix(association: AssociationId) {
    return `${association}#${profileIdentifier}`
  }

  getProfileStorageKey(pk: string) {
    return `${this.association}#${profileIdentifier}${pk}`
  }

  getTargetProfiles(cohortsToGet?: CohortName[], statusesToGet?: string[], trashStatusesToGet?: TrashStatus[]) {
    let targetProfiles: Record<string, Profile> = {}
    Object.values(this.profileEntities).forEach((profileEntity: Profile) => {
      const pk = profileEntity.getPk()
      const cohorts = profileEntity.getCohorts()
      const employmentStatus = profileEntity.getEmploymentStatus()
      const trashStatus = profileEntity.getTrashStatus()
      let hasCohortMatch = false
      if (cohortsToGet) {
        cohorts.forEach((cohort) => (cohortsToGet.includes(cohort) ? (hasCohortMatch = true) : null))
      }
      if (
        (!cohortsToGet || hasCohortMatch) &&
        (!statusesToGet || statusesToGet.includes(employmentStatus)) &&
        (!trashStatusesToGet || trashStatusesToGet.includes(trashStatus))
      ) {
        targetProfiles[pk] = profileEntity
      }
    })
    return targetProfiles
  }

  async listProfilePksLocalStorage() {
    const allKeys = await LocalStorageProvider.listDataKeys(this.association)
    let profilePks: string[] = []
    allKeys.forEach((key) =>
      key.includes(profileIdentifier) ? profilePks.push(key.split(profileIdentifier)[1]) : null,
    )
    return profilePks
  }

  putLocalEntity(pk: string, profile: Profile) {
    const storageKey = this.getProfileStorageKey(pk)
    return LocalStorageProvider.setData(storageKey, profile.toJson())
  }

  setProfileEntity(pk: string, profileEntity: Profile) {
    this.profileEntities[pk] = profileEntity
  }

  async updateProfiles(profiles: Record<ProfilePk, Record<string, any>>) {
    for (const profilePk of Object.keys(profiles)) {
      const profileEntity = new Profile(profiles[profilePk])
      await this.putLocalEntity(profilePk, profileEntity)
      this.setProfileEntity(profilePk, profileEntity)
    }
    // return this.profileEntities
  }
}
