import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import Radium from 'radium'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import equal from 'deep-equal'
import { mdiContentDuplicate, mdiTrashCan } from '@mdi/js'
import { camelCase, cloneDeep, isEmpty, pick } from 'lodash'

import { ColorPalette } from '../../config/colors'
import ButtonBlue from '../../components/BaseComponents/Buttons/ButtonBlue'
import NavBar from '../../components/Navigation/NavBar'
import SectionHeaderPrimary from '../../components/Headings/SectionHeaderPrimary'
import SideMenu from '../../components/Navigation/SideMenu'
import DataFieldsSideMenu from '../../components/SideMenus/DataFieldsSideMenu'
import InfoCollectorModal from '../../components/Modals/InfoCollector'
import RouteLeavingGuard from '../../components/Navigation/RouteLeavingGuard'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import AlertModalTwoButton from '../../components/Modals/AlertModalTwoButton'
import SaveFailedModal from '../../components/Modals/AlertModalOneButton'
import LoadingModal from '../../components/Modals/LoadingModal'
import DataFieldDetails from '../../components/Configurator/DataFields/DataFieldDetails'
import LabelCollector from '../../components/Modals/LabelCollector'
import { ConfigService } from '../../services'
import {
  ensureStringFormat,
  getCurrentFieldConfigSnapshot,
  getFieldConfigExclPpeItems,
  toUpperCaseCustom,
} from '../../utils'
import { PeopleFlowCombinedReducer } from '../../store'
import { AssociationSettingsRepository, UsersRepository } from '../../repositories'
import { ActionButtonType, Toolbar } from '../../components/GeneralUI/Toolbar'
import { ConditionalSelectorItems, FieldConfigItemSchema, FieldConfigCompType } from '../../types'
import { UserFeedback } from '../../components/GeneralUI/Feedback/UserFeedback'
import { FieldConfigItem } from '../../models/fieldConfig/fieldConfigItemModel'
import { FieldConfig } from '../../models/fieldConfig/fieldConfigModel'

dayjs.extend(relativeTime)

interface DataFieldsProps extends RouteComponentProps {}

function DataFields(props: DataFieldsProps) {
  const sectionsRef = useRef<HTMLDivElement>(null)

  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false)
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false)
  const [savingModalOpen, setSavingModalOpen] = useState(false)
  const [saveFailedModalOpen, setSaveFailedModalOpen] = useState(false)
  const [unsavedChangesModalOpen, setUnsavedChangesModalOpen] = useState(false)
  const [selectedField, setSelectedField] = useState('')
  const [selectorModalOpen, setSelectorModalOpen] = useState(false)
  const [selectorItems, setSelectorItems] = useState([''])
  const [conditionalSelectorChildValue, setConditionalSelectorRelatedFieldValue] = useState('')
  const [fieldConfigItem, setFieldConfigItem] = useState<FieldConfigItemSchema>({
    key: '',
    compType: FieldConfigCompType.TEXT,
    compulsory: false,
    allowCustomOption: false,
    dateFormat: '',
    multiLine: false,
    suggestionsEnabled: false,
    selectorItems: [''],
    useDefaultValue: false,
    defaultValue: '',
    setDefaultValueFromHistory: false,
    label: '',
    disabled: false,
    maxItems: undefined,
    conditionalSelectorItems: undefined,
    autoPopulate: undefined,
    singleSelectionMode: false,
  })
  const [detailsRefreshTimestamp, setDetailsRefreshTimestamp] = useState(0)

  const idPassport = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.idPassport)
  const password = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.password)
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  const userRepo = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.userRepo) as UsersRepository
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const navMenuAccess = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.navMenuAccess)

  useEffect(() => {
    initialiseModalState()
    setSelectedField('')
    setSelectorItems([''])
    setConditionalSelectorRelatedFieldValue('')
    setDetailsRefreshTimestamp(Date.now())
  }, [selectedAssociation])

  useEffect(() => {
    if (selectedField) {
      initialiseFromConfig(selectedField)
      sectionsRef.current?.scrollTo(0, 0)
      setDetailsRefreshTimestamp(Date.now())
    }
  }, [selectedField])

  useEffect(() => {
    if (associationRepo) {
      initialiseFromConfig(selectedField)
      setDetailsRefreshTimestamp(Date.now())
    }
  }, [associationRepo])

  function initialiseModalState() {
    setDeleteConfirmModalOpen(false)
    setDuplicateModalOpen(false)
    setSavingModalOpen(false)
    setSaveFailedModalOpen(false)
    setUnsavedChangesModalOpen(false)
    setSelectorModalOpen(false)
  }

  function initialiseFieldDetailsState() {
    setFieldConfigItem({
      key: '',
      compType: FieldConfigCompType.TEXT, // vs '' before (NOTE)
      compulsory: false,
      allowCustomOption: false,
      dateFormat: '',
      multiLine: false,
      suggestionsEnabled: false,
      selectorItems: [''],
      useDefaultValue: false,
      defaultValue: '',
      setDefaultValueFromHistory: false,
      label: '',
      disabled: false,
      maxItems: undefined,
      conditionalSelectorItems: undefined,
      autoPopulate: undefined,
      singleSelectionMode: false,
    })
  }

  function initialiseFromConfig(selectedField: string) {
    const fieldDetailsSnapshot = getCurrentFieldConfigSnapshot(associationRepo, selectedAssociation, selectedField)
    if (!isEmpty(fieldDetailsSnapshot)) {
      const details = cloneDeep(fieldDetailsSnapshot) as FieldConfigItemSchema
      setFieldConfigItem(details)
    }
  }

  function closeModals() {
    initialiseModalState()
  }

  function resetFieldDetailsState() {
    initialiseFieldDetailsState()
    initialiseFromConfig(selectedField)
  }

  function updateFieldConfigSetting(
    settingType: keyof FieldConfigItemSchema,
    newValue: string | string[] | number | boolean | {},
  ) {
    let updatedFieldDetails = cloneDeep(fieldConfigItem)
    if (settingType === 'dateFormat') {
      updatedFieldDetails[settingType] = ensureStringFormat(toUpperCaseCustom(newValue))
    } else {
      // @ts-ignore
      updatedFieldDetails[settingType] = newValue
    }
    setFieldConfigItem(updatedFieldDetails)
  }

  function openSelectorModal() {
    setSelectorModalOpen(true)
  }
  function closeSelectorModal() {
    setSelectorModalOpen(false)
  }

  function openConditionalSelectorModal(
    conditionalSelectorChildValue: string,
    conditionalSelectorItems: ConditionalSelectorItems,
  ) {
    let updatedFieldDetails = cloneDeep(fieldConfigItem)
    const existingConditionalSelectorItemValues = fieldConfigItem.conditionalSelectorItems?.values ?? {}
    updatedFieldDetails.conditionalSelectorItems = {
      priFieldKey: conditionalSelectorItems.priFieldKey,
      values: {
        ...existingConditionalSelectorItemValues,
        [conditionalSelectorChildValue]: conditionalSelectorItems.values[conditionalSelectorChildValue],
      },
    }
    setConditionalSelectorRelatedFieldValue(conditionalSelectorChildValue)
    setFieldConfigItem(updatedFieldDetails)
  }

  function closeConditionalSelectorModal() {
    setConditionalSelectorRelatedFieldValue('')
  }

  function anyChangesMade() {
    if (selectedField) {
      const snapshotBeforeChanges = getCurrentFieldConfigSnapshot(associationRepo, selectedAssociation, selectedField)
      const newFieldBeingCreated = isEmpty(snapshotBeforeChanges)
      const changesToExistingField = !equal(snapshotBeforeChanges, fieldConfigItem)
      return newFieldBeingCreated || changesToExistingField
    }
    return false
  }

  function saveAndChange(field: string, newFieldConfigItem?: FieldConfigItemSchema) {
    if (newFieldConfigItem) {
      saveChanges(newFieldConfigItem, field)
      return
    }

    const unsavedChanges = anyChangesMade()
    if (unsavedChanges) {
      setUnsavedChangesModalOpen(true)
      return
    }

    setSelectedField(field)
  }

  async function saveChanges(newFieldConfigItem?: FieldConfigItemSchema, newFieldName?: string) {
    setSavingModalOpen(true)

    const user = userRepo.getCurrentUserEntity()
    const username = user.getUsername()

    let updatedData: any = newFieldConfigItem || fieldConfigItem
    for (const key in updatedData) {
      if (
        updatedData[key] === null ||
        updatedData[key] === undefined ||
        (['selector', 'picker', 'itemSelector'].indexOf(updatedData.compType) === -1 && key === 'selectorItems') ||
        (updatedData.compType !== 'conditionalSelector' && key === 'conditionalSelectorItems')
      ) {
        delete updatedData[key]
      }
      if (
        updatedData[key] !== null &&
        updatedData[key] !== undefined &&
        key === 'dateFormat' &&
        (typeof updatedData[key] !== 'string' ||
          // string that contains one or more 'D', one or more 'M', and two or more 'Y' characters, with any combination of blank spaces, forward slashes or hyphens as separators
          updatedData[key].match(/^[DMY\s\/-]+[DMY\s\/-]+[DMY\s\/-]+[DMY\s\/-]*(?!\s\d{2}:\d{2}:\d{2})$/) === null)
      ) {
        delete updatedData[key]
      }
    }
    const fieldName = newFieldName || selectedField
    const changes = [
      {
        editedBy: username,
        updatePath: [fieldName],
        updatedData,
        updatedMs: +new Date(),
      },
    ]
    const response: { result: any; updatedConfig: any } | { result: any; updatedConfig?: undefined } =
      await ConfigService.updateConfig(selectedAssociation, 'fieldConfig', changes, {
        username: idPassport,
        password,
      })

    if (response.result === 'success') {
      const fieldConfigItem = pick(response.updatedConfig, fieldName)
      associationRepo.setFieldConfigItem(selectedAssociation, fieldConfigItem)
      initialiseFromConfig(selectedField)
    } else {
      setSaveFailedModalOpen(true)
    }

    setSavingModalOpen(false)
  }

  async function copySettingsAndSaveNewField(newFieldName: string) {
    setDuplicateModalOpen(false)
    setSavingModalOpen(true)

    const key = camelCase(newFieldName)
    const fieldConfig = getFieldConfigExclPpeItems(associationRepo, selectedAssociation)
    const currentSelectedFieldConfig = fieldConfig[selectedField]
    // add new field to local config with settings of current selected field
    fieldConfig[key] = { ...currentSelectedFieldConfig }
    // reset the original properties to ensure they are not duplicated
    fieldConfig[key].key = key
    fieldConfig[key].label = newFieldName.toUpperCase()

    const changes = [
      {
        editedBy: idPassport,
        updatePath: [key],
        updatedData: fieldConfig[key],
        updatedMs: +new Date(),
      },
    ]

    try {
      const response = await ConfigService.updateConfig(selectedAssociation, 'fieldConfig', changes, {
        username: idPassport,
        password,
      })

      if (response.result === 'success') {
        associationRepo.setFieldConfig(selectedAssociation, response.updatedConfig)
        setSelectedField(key)
      }
    } catch (error) {}

    setSavingModalOpen(false)
  }

  function deleteSelectedField() {
    setDeleteConfirmModalOpen(true)
    setSavingModalOpen(true)
    // use setTimeout here or use useEffect...
    setTimeout(async () => {
      const fieldConfig = getFieldConfigExclPpeItems(associationRepo, selectedAssociation)
      delete fieldConfig[selectedField]

      // TODO: for selectors fields, should selectorItems, autoPopulate be reset?
      const changes = [
        {
          editedBy: idPassport,
          updatePath: [],
          updatedData: fieldConfig,
          updatedMs: +new Date(),
        },
      ]
      try {
        const response = await ConfigService.updateConfig(selectedAssociation, 'fieldConfig', changes, {
          username: idPassport,
          password,
        })

        if (response.result === 'success') {
          associationRepo.setFieldConfig(selectedAssociation, response.updatedConfig)
          setSelectedField('')
        }
      } catch (error) {}

      closeModals()
    }, 1000)
  }

  function handleFieldSelection(field: string, newFieldConfigItem?: FieldConfigItemSchema) {
    saveAndChange(field, newFieldConfigItem)
  }

  function handleChangesUndo() {
    resetFieldDetailsState()
    closeModals()
  }

  function handleChangesSave() {
    closeModals()
    saveChanges()
  }

  function handleConditionalSelectorUpdate(selectorItems: string[]) {
    const { conditionalSelectorItems } = fieldConfigItem

    if (conditionalSelectorItems) {
      updateFieldConfigSetting('conditionalSelectorItems', {
        ...conditionalSelectorItems,
        values: {
          ...conditionalSelectorItems.values,
          [conditionalSelectorChildValue]: selectorItems,
        },
      })
    }
    closeConditionalSelectorModal()
  }

  const { hasFieldConfigAccess } = navMenuAccess
  const { compType, conditionalSelectorItems } = fieldConfigItem
  const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
  const fieldConfigEntity = new FieldConfig(fieldConfig) // TODO: associationRepo should be contain the entities
  const fieldConfigItemEntity = new FieldConfigItem(fieldConfigItem)
  const selectedFieldDetails: any = selectedField ? fieldConfig[selectedField] : {}
  const changesMade = anyChangesMade()

  const existingSelectorFieldConfigItems: FieldConfigItemSchema[] = []
  Object.entries(fieldConfig).forEach(([key, value]) => {
    if (value.compType === 'selector') {
      existingSelectorFieldConfigItems.push(value)
    }
  })

  let toolbarActionButtons: ActionButtonType[] = []
  if (selectedField) {
    toolbarActionButtons = [
      {
        iconPath: mdiTrashCan,
        onClick: () => setDeleteConfirmModalOpen(true),
        label: 'DELETE FIELD',
        title: 'Delete currently selected field',
      },
      {
        iconPath: mdiContentDuplicate,
        onClick: () => setDuplicateModalOpen(true),
        label: 'DUPLICATE CONFIG',
        title: 'Duplicate config of currently selected field for new field',
      },
    ]
  }

  let details = null
  if (isEmpty(selectedFieldDetails)) {
    details = <UserFeedback message="Select a field on the left to manage settings" />
  } else {
    details = (
      <>
        <DataFieldDetails
          selectedAssociation={selectedAssociation}
          fieldConfigItemEntity={fieldConfigItemEntity}
          fieldConfigEntity={fieldConfigEntity}
          updateFieldConfigSetting={updateFieldConfigSetting}
          existingSelectorFieldConfigItems={existingSelectorFieldConfigItems}
          openSelectorModal={openSelectorModal}
          openConditionalSelectorModal={openConditionalSelectorModal}
          key={`dataFieldDetails_${selectedField}_${compType}_${detailsRefreshTimestamp}`}
        />
        <div style={{ paddingBottom: '3em' }}>
          <ButtonBlue disabled={!changesMade} onClick={() => saveChanges()}>
            Save changes
          </ButtonBlue>
        </div>
      </>
    )
  }

  let modals = (
    <>
      <InfoCollectorModal
        open={selectorModalOpen}
        defaultItems={selectorItems || []}
        header="SELECTOR OPTIONS"
        subHeader="Field selector options"
        warningMessage="Add at least one item"
        validateInput={() => true}
        transformInput={toUpperCaseCustom}
        placeholder="Enter selector option"
        successLabel="Update"
        minimumItems={1}
        dismiss={closeSelectorModal}
        onSuccess={(selectorItems) => {
          updateFieldConfigSetting('selectorItems', selectorItems)
          closeSelectorModal()
        }}
        onReject={closeSelectorModal}
      />
      <InfoCollectorModal
        open={conditionalSelectorChildValue !== ''}
        defaultItems={
          conditionalSelectorChildValue && conditionalSelectorItems
            ? conditionalSelectorItems.values[conditionalSelectorChildValue]
            : []
        }
        header="CONDITIONAL SELECTOR OPTIONS"
        subHeader="Field selector options"
        warningMessage="Add at least one item for conditional selector to show"
        validateInput={() => true}
        transformInput={toUpperCaseCustom}
        placeholder="Enter selector option"
        successLabel="Update"
        minimumItems={0}
        dismiss={closeConditionalSelectorModal}
        onSuccess={handleConditionalSelectorUpdate}
        onReject={closeConditionalSelectorModal}
      />
      <AlertModalOneButton
        // TODO: There should only be one AlertModalOneButton component per screen with the required headers, body, and functions being set accordingly by a handler.
        open={!hasFieldConfigAccess}
        header={'Not Authorised'}
        body={"You don't have permission to view/edit data field settings."}
        buttonLabel={'Ok'}
        opaqueBackground={true}
        onClick={() => props.history.goBack()}
      />
      <AlertModalTwoButton
        open={unsavedChangesModalOpen}
        dismiss={closeModals}
        onClick1={handleChangesUndo}
        onClick2={handleChangesSave}
        buttonLabel1="Undo"
        buttonLabel2="Save"
        header="UNSAVED CHANGES"
        body={
          <div>
            Cannot switch context without first undoing or saving changes.
            <br />
            What do you want to do?
          </div>
        }
      />
      <AlertModalTwoButton
        open={deleteConfirmModalOpen}
        dismiss={closeModals}
        onClick1={closeModals}
        onClick2={deleteSelectedField}
        buttonLabel1="No"
        buttonLabel2="Yes"
        header="Confirm"
        buttonStyle={{ marginTop: 30 }}
        body={<div>{`Are you sure you want to delete field ${fieldConfig[selectedField]?.label}?`}</div>}
      />
      <SaveFailedModal
        open={saveFailedModalOpen}
        header="Failed to save changes"
        body={
          <div>
            Changes won't be permanently applied. Please try again.
            <p>Contact customer support if the problem persists.</p>
          </div>
        }
        buttonLabel={'Ok'}
        onClick={() => setSaveFailedModalOpen(false)}
      />
      <LoadingModal open={savingModalOpen}>Saving changes...</LoadingModal>
      <LabelCollector
        open={duplicateModalOpen}
        warning="Enter field name"
        placeholder="Enter field name"
        buttonLabel="Add"
        iconName="setting"
        dismiss={() => setDeleteConfirmModalOpen(false)}
        submit={copySettingsAndSaveNewField}
      />
    </>
  )

  return (
    <div style={styles.container}>
      <NavBar match={props.match} location={props.location} history={props.history} />
      <SectionHeaderPrimary style={styles.sectionHeader} disabled={true} searchString={''} onClick={() => ({})}>
        Data Fields
      </SectionHeaderPrimary>
      <div style={styles.contentContainer}>
        <SideMenu
          visible={true}
          menuComponents={
            <DataFieldsSideMenu
              selectedField={selectedField}
              fieldConfig={fieldConfig}
              onFieldClick={handleFieldSelection}
            />
          }
        />
        <div style={styles.rightSide}>
          <Toolbar actionButtons={toolbarActionButtons} navButtons={{ left: { label: 'BACK' } }} />
          <div style={styles.rightSideContent} ref={sectionsRef}>
            <div style={styles.fieldConfigItem}>{details}</div>
          </div>
        </div>
      </div>
      {modals}
      <RouteLeavingGuard
        when={changesMade}
        navigate={(path) => props.history.push(path)}
        shouldBlockNavigation={(location) => true}
        alertHeader="Discard changes"
        alertBody={
          <div>
            You have unsaved changes.
            <br />
            Are you sure you want to leave this page without saving?
          </div>
        }
      />
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.SCREEN_TOP_GRADIENT}, ${ColorPalette.SCREEN_BOTTOM_GRADIENT})`,
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'max(2em, 2%)',
    width: '100%',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  button: {
    fontWeight: 'bolder',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
  fieldConfigItem: {
    width: '91%',
    margin: '0 auto',
  },
}

export default Radium(DataFields)
