import { DataTableFilterState } from '../components/Tables/DataTable/DataTable'
import { IColumnConfig } from '../components/Tables/DataTable/DataTableHeader'
import { ensureArrayFormat, normaliseStringForComparison, toUpperCaseCustom } from '../utils'

export class TableFilterService {
  shouldCalculateFilterState = (currentFilterState: DataTableFilterState) => {
    let recalculate = false
    Object.keys(currentFilterState).forEach((headerKey) => {
      if (!Object.keys(currentFilterState[headerKey]).length) {
        recalculate = true
      }
    })
    return recalculate
  }

  generateFilterState = (
    columnConfig: IColumnConfig[],
    tableData: Record<string, string>[],
    initialFilterState: DataTableFilterState,
  ) => {
    const tableHeaderKeys = columnConfig.map((item) => item.id)
    const allFilterState = {} as DataTableFilterState

    tableHeaderKeys.forEach((headerKey) => {
      if (!allFilterState[headerKey]) {
        allFilterState[headerKey] = {}
      }
      if (initialFilterState[headerKey]) {
        allFilterState[headerKey] = initialFilterState[headerKey]
      }
    })

    tableData.forEach((item) => {
      tableHeaderKeys.forEach((headerKey) => {
        try {
          const value = item[headerKey]
          let valueArray: string[] = []

          if (Array.isArray(value)) {
            valueArray = value.map(String)
          } else if (typeof value === 'number' || typeof value === 'string') {
            valueArray = [String(value)]
          }

          valueArray.forEach((value) => {
            value = toUpperCaseCustom(value).trim()
            if (value !== '' && value !== '-' && !(value in allFilterState[headerKey])) {
              allFilterState[headerKey][value] = false
            }
          })
        } catch (error) {
          console.error('error: ', error)
        }
      })
    })

    return allFilterState
  }

  applyFilters = (tableData: Record<string, any>[], allFilterState: DataTableFilterState) => {
    try {
      let hasFiltersApplied = false

      for (const headerKey in allFilterState) {
        const headerFilters = allFilterState[headerKey]
        for (const filterKey in headerFilters) {
          if (headerFilters[filterKey]) {
            hasFiltersApplied = true
            break
          }
        }
        if (hasFiltersApplied) break
      }

      if (!hasFiltersApplied) {
        return tableData
      }

      const activeFilters: Record<string, string> = {}
      for (const headerKey in allFilterState) {
        const headerFilters = allFilterState[headerKey]
        for (const filterKey in headerFilters) {
          if (headerFilters[filterKey]) {
            activeFilters[headerKey] = filterKey.trim().toUpperCase()
          }
        }
      }

      return tableData.filter((dataItem) => {
        return Object.keys(activeFilters).every((headerKey: string) => {
          const value = dataItem[headerKey]
          if (value !== undefined) {
            const valueArray: string[] = ensureArrayFormat(value)
            const matchesFilter = valueArray.some((item) => {
              const filterValue = normaliseStringForComparison(item)
              return activeFilters[headerKey] === filterValue
            })
            if (matchesFilter) {
              return true
            }
          }
          return false
        })
      })
    } catch (error) {
      throw error
    }
  }

  cleanFilterState = (
    customFilterState: { selectedField: string; filterType: string; filterValue: string; key?: string }[],
  ) => {
    customFilterState = [...customFilterState].filter((item) => {
      if (!item.selectedField || item.filterValue === '') {
        return false
      }
      return true
    })
    return customFilterState
  }
}
