import { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'

import { ColorPalette } from '../../../config'
import CheckboxTabLabelled from '../../BaseComponents/Checkboxes/CheckboxTabLabelled'
import { AutoPopulatedFieldConfigurator } from './AutoPopulateValueConfigurator'
import TwoColumnSelector from '../TwoColumnSelector/TwoColumnSelector'
import { addUnderScores, removeUnderScores, toUpperCaseCustom } from '../../../utils'
import InfoCollectorModal from '../../Modals/InfoCollector'
import { FieldConfig } from '../../../models/fieldConfig/fieldConfigModel'
import { AutoPopulate } from '../../../types'

type AutoPopulateFieldsProps = {
  fieldKey?: string
  autoPopulate: AutoPopulate
  shouldAutoPopulate: boolean
  isParentTextField?: boolean
  selectorItems?: string[]
  fieldConfigEntity: FieldConfig
  onShouldAutoPopulateChange: (values: string[], isMount: boolean) => void
  onFieldValuePairChange: (item: string, field?: string, value?: string, previousField?: string) => void
  onFieldValuesChange?: (fieldValues: string[]) => void
  onFieldValueRemove?: (fieldValue: string) => void
}

export const AutoPopulateFields = (props: AutoPopulateFieldsProps) => {
  const {
    fieldKey,
    autoPopulate,
    shouldAutoPopulate,
    selectorItems,
    isParentTextField,
    fieldConfigEntity,
    onShouldAutoPopulateChange,
    onFieldValuePairChange,
    onFieldValuesChange = () => {},
    onFieldValueRemove,
  } = props

  const [fieldValueModalOpen, setFieldValueModalOpen] = useState(false)
  const [autoPopulateModalOpen, setAutoPopulateModalOpen] = useState('')

  const toggleFieldValueModal = () => setFieldValueModalOpen((fieldValueModalOpen) => !fieldValueModalOpen)
  const toggleAutoPopulateModal = (selectedFieldToAutoPopulate?: string) => {
    if (selectedFieldToAutoPopulate) {
      setAutoPopulateModalOpen(selectedFieldToAutoPopulate)
      return
    }
    setAutoPopulateModalOpen('')
  }

  const allAvailableFieldKeys = fieldConfigEntity
    .getAllFieldConfigItemEntities()
    .filter((entity) => !entity.isPpeKey())
    .map((entity) => entity.getKey())

  const fieldValues = Object.keys(autoPopulate)
    .map((fieldValue) => removeUnderScores(fieldValue))
    .sort()

  const buildFieldsToPopulateUi = (fieldKey: string) => {
    let fieldsToPopulateUi = null

    const selectedFieldToAutoPopulate = autoPopulateModalOpen.length > 0 ? autoPopulateModalOpen : ''
    if (selectedFieldToAutoPopulate) {
      fieldsToPopulateUi = (
        <Dialog
          open={!!autoPopulateModalOpen}
          onBackdropClick={() => toggleAutoPopulateModal()}
          PaperProps={{ style: { borderRadius: 8 } }}
          style={{ backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY }}>
          <h1 style={styles.title}>FIELDS TO POPULATE</h1>
          <AutoPopulatedFieldConfigurator
            fieldConfigEntity={fieldConfigEntity}
            title={
              <span>
                WHEN <b>{selectedFieldToAutoPopulate.toUpperCase()}</b> SPECIFIED AS{' '}
                <b>{fieldConfigEntity.getLabel(fieldKey)}</b>, WHICH FIELDS SHOULD BE AUTO-POPULATED?
              </span>
            }
            allAvailableFieldKeys={allAvailableFieldKeys}
            autoPops={
              autoPopulate.hasOwnProperty(selectedFieldToAutoPopulate) ? autoPopulate[selectedFieldToAutoPopulate] : {}
            }
            setFieldValuePair={(field: string, value: string, previousField?: string) =>
              onFieldValuePairChange(selectedFieldToAutoPopulate, field, value, previousField)
            }
            style={{ margin: '1.5em 3em 3em' }}
            key={`fieldValueChooser_${selectedFieldToAutoPopulate}`}
          />
        </Dialog>
      )
    }
    return fieldsToPopulateUi
  }

  let autoPopulationUi = null
  if (shouldAutoPopulate && fieldKey && isParentTextField) {
    autoPopulationUi = (
      <div>
        <TwoColumnSelector
          label="FIELD VALUES THAT WILL TRIGGER AUTO-POPULATION OF OTHER FIELDS"
          values={fieldValues}
          onClick={toggleFieldValueModal}
          onValueClick={(fieldValue: string) => {
            const fieldKey = addUnderScores(fieldValue)
            toggleAutoPopulateModal(fieldKey)
          }}
          onRemoveClick={(valueName: string) => {
            if (onFieldValueRemove) {
              onFieldValueRemove(addUnderScores(valueName))
            }
          }}
          outlined={true}
          addOrRemoveLabel="Add another?"
        />
        {buildFieldsToPopulateUi(fieldKey)}
      </div>
    )
  } else if (shouldAutoPopulate) {
    autoPopulationUi = selectorItems?.map((selectorItem) => (
      <AutoPopulatedFieldConfigurator
        fieldConfigEntity={fieldConfigEntity}
        title={
          <span>
            WHEN <b>{selectorItem}</b> SELECTED, WHICH FIELDS SHOULD BE AUTO-POPULATED?
          </span>
        }
        allAvailableFieldKeys={allAvailableFieldKeys}
        autoPops={autoPopulate[selectorItem] !== undefined ? autoPopulate[selectorItem] : {}}
        setFieldValuePair={(field: string, value: string, previousField?: string) =>
          onFieldValuePairChange(selectorItem, field, value, previousField)
        }
        key={`fieldValueChooser_${selectorItem}`}
      />
    ))
  }

  let fieldValuesModal = null
  if (fieldValueModalOpen) {
    fieldValuesModal = (
      <InfoCollectorModal
        open={fieldValueModalOpen}
        defaultItems={fieldValues}
        header="FIELD VALUES"
        subHeader="Field values that will trigger auto-population"
        warningMessage="Add at least one item"
        validateInput={() => true}
        transformInput={toUpperCaseCustom}
        transformOutput={addUnderScores}
        placeholder="Enter value"
        successLabel="Update"
        minimumItems={1}
        dismiss={toggleFieldValueModal}
        onSuccess={(fieldValues) => {
          onFieldValuesChange(fieldValues)
          toggleFieldValueModal()
        }}
        onReject={toggleFieldValueModal}
      />
    )
  }
  return (
    <>
      <CheckboxTabLabelled
        style={styles.sectionInput}
        itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
        label={'SHOULD OTHER FIELDS BE AUTO-POPULATED FROM THE RESULT OF THIS FIELD?'}
        values={shouldAutoPopulate ? ['yes'] : ['no']}
        selectorItems={['no', 'yes']}
        selectionHandler={onShouldAutoPopulateChange}
      />
      {autoPopulationUi}
      {fieldValuesModal}
    </>
  )
}

const styles = {
  title: {
    margin: '1.5em 0',
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    color: ColorPalette.PRIMARY_TEXT,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  sectionInput: { margin: '40px 1.5em 0px 1.5em' },
}
