import { FieldConfigSchema } from '../../types'
import { FieldConfigItem } from './fieldConfigItemModel'

export class FieldConfig {
  private fieldConfigJson: FieldConfigSchema
  constructor(fieldConfigJson: FieldConfigSchema) {
    this.fieldConfigJson = fieldConfigJson
  }

  hasField(key: string): boolean {
    return Object.keys(this.fieldConfigJson).includes(key)
  }

  getAllFieldConfigItemEntities(): FieldConfigItem[] {
    return Object.values(this.fieldConfigJson).map((fieldConfigItemJson) => new FieldConfigItem(fieldConfigItemJson))
  }

  getFieldConfigItemEntity(key: string): FieldConfigItem {
    if (!this.hasField(key)) {
      throw `"${key}" not in fieldConfig`
    }
    return new FieldConfigItem(this.fieldConfigJson[key])
  }

  getLabel(key: string): string {
    return this.getFieldConfigItemEntity(key).getLabel()
  }

  getKeyForLabel(label: string): string | undefined {
    const allFieldConfigItemEntities = this.getAllFieldConfigItemEntities()
    for (const fieldConfigItemEntity of allFieldConfigItemEntities) {
      if (fieldConfigItemEntity.getLabel() === label) {
        return fieldConfigItemEntity.getKey()
      }
    }
    return undefined
  }

  getAllAvailableKeys() {
    return Object.keys(this.fieldConfigJson)
  }

  getAllAvailableLabels() {
    const labels = this.getAllAvailableKeys().map((key) => {
      try {
        const label = this.getLabel(key)
        return label
      } catch (error) {}
      return ''
    })
    return labels.filter((label) => label !== '')
  }
}
