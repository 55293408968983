import { Email, PositiveNumber, ProfilePk, UnixMs } from './common'
import { FieldConfigKey } from './fieldConfigTypes'

export type TrendId = string
type TrendName = string
export type TrendConfig = {
  id?: TrendId
  // graphType: GraphTypesEnum;  // TODO: implement more graph types in the future
  trendName: TrendName
  profilesToInclude: ProfilePk[]
  fieldsToAggregate: FieldConfigKey[]
  aggregateBy: AggregationTypesEnum
  resultsRange: { from: UnixMs; to: UnixMs }
}

export type DateRangeKey = 'startDate' | 'endDate'

type DocName = string
export type DocFilterTimeRange =
  | {
      startUnixMs: UnixMs
      endUnixMs: UnixMs
    }
  | 'NONE'

export type FieldOperation = {
  comparatorType: OperationsEnum
  comparatorValue: string
}
export type QueryTimeRange = {
  startDate: UnixMs
  endDate: UnixMs
}
export type FieldFilterParams = {
  targetPath: FieldConfigKey[]
  operation: FieldOperation
}
export type DocFilterParams = {
  targetDoc: DocName
  timeRange: DocFilterTimeRange
}

export type QueryId = string
export type AllQueryResults = Record<QueryId, QueryResults>

export type QueryPeriodType = 'week' | 'month'
export type QueryPeriod = {
  periodType: QueryPeriodType
  periodInteger: PositiveNumber<number>
}

export type QueryFilter = {
  filterType: FilterTypeEnum
  filterParams: FieldFilterParams | DocFilterParams
  dataPath: FieldConfigKey[]
}

export type FilterUiListItem = {
  selectedTarget: string
  comparatorType: string
  filterType: FilterTypeEnum
  filterValue: string | Record<DateRangeKey, Date>
  key?: string
}

type QueryStatus = 'active' | 'deactivated'

export type QueryPayloadConfig = {
  pk: string
  sk: string
  name: string
  filterParams: QueryFilterParams
  trends: {}[]
  status: QueryStatus
}
export type QueryPayload = {
  config: QueryPayloadConfig
}

export type TimePeriod = {
  periodType: PeriodTypeEnum
  periodInteger: PositiveNumber<number>
}
export type CustomTimePeriod = {
  startUnixMs: UnixMs
  endUnixMs: UnixMs
  timeRangePath?: FieldConfigKey[]
}

export type QueryFilterParams = {
  // mirrors EventFilterConfig in lambda-services
  period: TimePeriod | {}
  timeRange: CustomTimePeriod | {}
  filters: QueryFilter[][]
}

export type Query = {
  id?: string
  name: string
  filters?: QueryFilter[][] // OR'ed blocks of AND'ed filters
  filterParams: QueryFilterParams
  trends: TrendConfig[]
  status?: QueryStatus
  blobStoreDir?: string
}

export type QueryResultProfileData = {
  currentData: Record<string, string>
  yValues: number[]
}

export type QueryResultsFileLocation = {
  bucket: string
  fileDir: string
  fileName: string
  fileFormat: 'csv' | 'json'
}
export type QueryResult = {
  profilePk: string
  dateActioned: string
  [key: string]: string // filter fields
}

type PlotData = {
  x: string
  y: number
  warnings: { key: FieldConfigKey; targetValue: any; message: string; aggregationMethod: AggregationTypesEnum }[]
}

export type QueryTrend = {
  id: string
  trendData: PlotData[]
  warningFieldLabels?: string[]
}

export type QueryResults = {
  hasUpdates?: boolean
  results?: QueryResult[]
  resultsFileLocation?: QueryResultsFileLocation
  trends: QueryTrend[]
}

type FilterParamsFormat = {
  targetPath: FieldConfigKey[]
  operation: FieldOperation
}
export type QueryFilterFormat = {
  filterType: FilterTypeEnum
  filterParams: FilterParamsFormat | DocFilterParams
  dataPath: FieldConfigKey[]
}

export type AnalyticsConfigsResponse = {
  data: {
    allConfigs: Query[]
  }
}

export type AnalyticsConfigUpdateResponse = {
  data: { config: Query }
}

export type AnalyticsConfigCreateResponse = {
  data: {
    config: Query & { pk: string; sk: string; blobStoreDir: string }
  }
}

export type AnalyticsResultsResponse = {
  data: QueryResults
}

export type ResultExportTargetEmails = Email[]

export enum AggregationTypesEnum {
  COUNT = 'COUNT',
  VALUE = 'VALUE',
}

export enum OperationsEnum {
  BEGINS_WITH = 'beginsWith',
  CONTAINS = 'contains',
  EQUALS = 'equals',
  EXISTS = 'exists',
  DOES_NOT_EXIST = 'doesntExist',
  HAS_CHANGED = 'hasChanged',
  GREAT_THAN_OR_EQUAL_TO = 'greatThanEqualTo',
  LESS_THAN_OR_EQUAL_TO = 'lessThanEqualTo',
}

export enum FilterTypeEnum {
  FIELD = 'field',
  DOCUMENT = 'document',
}

export enum PeriodTypeEnum {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export enum PeriodTypeLabelsEnum {
  TODAY = 'TODAY',
  LAST_WEEK = 'LAST WEEK',
  LAST_MONTH = 'LAST MONTH',
  LAST_3_MONTHS = 'LAST 3 MONTHS',
  LAST_6_MONTHS = 'LAST 6 MONTHS',
  LAST_12_MONTHS = 'LAST 12 MONTHS',
  CUSTOM_RANGE = 'CUSTOM RANGE',
}
