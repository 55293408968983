import { OperationsEnum } from '../types'

export const QUERY_OPERATIONS_TO_READABLE: Record<string, string> = {
  beginsWith: 'BEGINS WITH',
  contains: 'CONTAINS',
  equals: 'EQUALS',
  exists: 'EXISTS',
  doesntExist: 'DOES NOT EXIST',
  greatThanEqualTo: 'GREATER THAN OR EQUAL TO',
  hasChanged: 'HAS CHANGED',
  lessThanEqualTo: 'LESS THAN OR EQUAL TO',
}

export const QUERY_OPERATIONS_TO_KEY = {
  BEGINS_WITH: 'beginsWith',
  CONTAINS: 'contains',
  EQUALS: 'equals',
  EXISTS: 'exists',
  DOES_NOT_EXIST: 'doesntExist',
  GREATER_THAN_OR_EQUAL_TO: 'greatThanEqualTo',
  HAS_CHANGED: 'hasChanged',
  LESS_THAN_OR_EQUAL_TO: 'lessThanEqualTo',
} as Record<string, OperationsEnum>
