import { CSSProperties, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'

import { DataQueryFilters } from './DataQueryFilters'
import { QueryService } from '../../../services/queryService'
import {
  CustomTimePeriod,
  DocFilterParams,
  DocFilterTimeRange,
  FieldFilterParams,
  FilterTypeEnum,
  FilterUiListItem,
  QueryFilter,
  QueryFilterParams,
  TimePeriod,
} from '../../../types'
import { DataQueryTimePeriodSelector } from './DataQueryTimePeriodSelector'
import { ConfigSectionHeader } from '../ConfigSectionHeader'
import { FieldConfig } from '../../../models/fieldConfig/fieldConfigModel'

type DataQuerySettingsProps = {
  fieldConfigEntity: FieldConfig
  filterParams: QueryFilterParams
  selectedAssociation?: string
  style?: CSSProperties
  saveChanges: (
    filters: QueryFilter[][],
    timePeriod?: TimePeriod | {},
    customTimePeriod?: CustomTimePeriod | {},
  ) => Promise<void>
}

export const DataQuerySettings = (props: DataQuerySettingsProps) => {
  const [timePeriodSelection, setTimePeriodSelection] = useState<TimePeriod | {}>({})
  const [customTimePeriodRange, setCustomTimePeriodRange] = useState<CustomTimePeriod | {}>({})
  const [isScreenContentVisible, setScreenContentVisibility] = useState(false)

  useEffect(() => {
    if (!isEmpty(props.filterParams.period)) {
      setTimePeriodSelection(props.filterParams.period)
    }
    if (!isEmpty(props.filterParams.timeRange)) {
      setCustomTimePeriodRange(props.filterParams.timeRange)
    }
    setScreenContentVisibility(true)
  }, [])

  const handleFilterChangeDoneClick = async (filterUiListItems: FilterUiListItem[][]) => {
    const queryFilters: QueryFilter[][] = QueryService.filterUiListItemsToQueryFilter(
      filterUiListItems,
      props.fieldConfigEntity,
    )
    await props.saveChanges(queryFilters)
  }

  const handleTimePeriodSelection = async (timePeriod?: TimePeriod) => {
    if (timePeriod) {
      await props.saveChanges(props.filterParams.filters, timePeriod, {})
      setTimePeriodSelection(timePeriod)
    }
  }

  const handleCustomDateRangeSelection = async (timeRange: CustomTimePeriod) => {
    await props.saveChanges(props.filterParams.filters, {}, timeRange)
    setCustomTimePeriodRange(timeRange)
  }

  const queryFilterToUiListItems = (
    queryFilters: QueryFilter[][],
    fieldConfigEntity: FieldConfig,
  ): FilterUiListItem[][] => {
    return queryFilters.map((orBlock: QueryFilter[]) => {
      return orBlock.map((filter: QueryFilter) => {
        const { filterType, filterParams } = filter
        let translatedFilter: Partial<FilterUiListItem> = {
          filterType,
        }
        try {
          if (filterType === FilterTypeEnum.FIELD) {
            const fieldFilterParams = filterParams as FieldFilterParams
            translatedFilter.comparatorType = fieldFilterParams.operation.comparatorType
            translatedFilter.filterValue = fieldFilterParams.operation.comparatorValue
            translatedFilter.selectedTarget = ''
            if (fieldFilterParams.targetPath.length) {
              const targetKey = fieldFilterParams.targetPath[0]
              translatedFilter.selectedTarget = fieldConfigEntity.getLabel(targetKey)
            }
          } else {
            const docFilterParams = filterParams as DocFilterParams
            const timeRange = docFilterParams.timeRange as DocFilterTimeRange
            if (timeRange === 'NONE') {
              translatedFilter.filterValue = undefined
            } else {
              const startDate = new Date(timeRange.startUnixMs)
              const endDate = new Date(timeRange.endUnixMs)
              translatedFilter.filterValue = {
                startDate,
                endDate,
              }
            }
            translatedFilter.selectedTarget = docFilterParams.targetDoc
          }
        } catch (error) {
          console.log('queryFilterToUiListItems error: ', error)
        }
        return translatedFilter as FilterUiListItem
      })
    })
  }

  const { filterParams, fieldConfigEntity } = props

  let screenContent = null
  if (isScreenContentVisible) {
    screenContent = (
      <div style={{ width: '90%', height: '100%', ...props.style }}>
        <div style={styles.section}>
          <ConfigSectionHeader>TIME RANGE</ConfigSectionHeader>
          <div style={styles.sectionInput}>
            <DataQueryTimePeriodSelector
              timePeriod={timePeriodSelection}
              customTimeRange={customTimePeriodRange}
              setTimePeriod={handleTimePeriodSelection}
              setCustomDateRange={handleCustomDateRangeSelection}
            />
          </div>
        </div>
        <div style={styles.section}>
          <ConfigSectionHeader>FILTERS TO BE APPLIED</ConfigSectionHeader>
          <div style={styles.sectionInput}>
            <DataQueryFilters
              fieldConfigEntity={fieldConfigEntity}
              filters={queryFilterToUiListItems(filterParams.filters, fieldConfigEntity)}
              onDoneClick={handleFilterChangeDoneClick}
            />
          </div>
        </div>
      </div>
    )
  }

  return screenContent
}

const styles = {
  section: {
    width: '96%',
    margin: '4% 2%',
    paddingBottom: '20px',
  },
  sectionInput: {
    padding: '5px 25px 20px 25px',
  },
}
