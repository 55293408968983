import { useEffect, useState } from 'react'

import ButtonBlue from '../../BaseComponents/Buttons/ButtonBlue'
import DataQueryGraphConfig from '../../Configurator/DataQueries/DataQueryGraphConfig'
import Modal from '../Modal'
import { DataQueryTrendsProfiles } from '../../Configurator/DataQueries/DataQueryTrendsProfiles'
import ButtonGrey from '../../BaseComponents/Buttons/ButtonGrey'
import { PeopleFlowCombinedReducer } from '../../../store'
import InfoCollectorModal from '../InfoCollector'
import { FieldConfigKey, AggregationTypesEnum, QueryResult, TrendConfig } from '../../../types'
import { useSelector } from 'react-redux'
import { AssociationSettingsRepository } from '../../../repositories'
import { IColumnConfig } from '../../Tables/DataTable/DataTableHeader'
import CheckboxTabLabelled from '../../BaseComponents/Checkboxes/CheckboxTabLabelled'
import { ColorPalette } from '../../../config'

const { COUNT } = AggregationTypesEnum

type GraphConfiguratorProps = {
  aggregationOptions: FieldConfigKey[]
  profileTableRowData: QueryResult[]
  profileFilterConfig: { key: string; label: string }[]
  profileTableColumnConfig: IColumnConfig[]
  // queryResults?: QueryResults
  trendConfig?: TrendConfig
  onCancelClick: () => void
  onDoneClick: (trendConfig: TrendConfig) => void
}

export const DataQueryGraphConfigurator = (props: GraphConfiguratorProps) => {
  const [mainModalOpen, setMainModalOpen] = useState(true)
  const [fieldSelectionModalOpen, setFieldSelectionModalOpen] = useState(false)
  const [profileSelectionModalOpen, setProfileSelectionModalOpen] = useState(false)
  const [missingInputModalOpen, setMissingInputModalOpen] = useState(false)
  const [refreshTimestamp, setRefreshTimestamp] = useState<number>(Date.now())
  const [selectedProfileIds, setSelectedProfileIds] = useState<string[]>([])
  const [selectedFieldLabels, setSelectedFieldLabels] = useState<string[]>([])
  const [graphName, setGraphName] = useState('')
  const [aggregateBy, setAggregateBy] = useState(COUNT)
  const [fieldLabelOptions, setFieldLabelOptions] = useState<string[]>([])
  // const [profileResultOptions, setProfileResultOptions] = useState<QueryResult[]>([])
  const [profileOptionsLoading, setProfileOptionsLoader] = useState(false)
  const [selectedDateRange, setSelectedDateRange] = useState<Record<string, Date>>({
    startDate: new Date(new Date().getTime() - 2408000000),
    endDate: new Date(),
  })
  const [includeAllResults, setIncludeAllResults] = useState(['YES'])

  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository

  useEffect(() => {
    const { trendConfig } = props
    if (trendConfig) {
      const { trendName, profilesToInclude, aggregateBy, fieldsToAggregate, resultsRange } = trendConfig
      setGraphName(trendName)
      setAggregateBy(aggregateBy)
      setSelectedProfileIds(profilesToInclude)
      setSelectedDateRange({
        startDate: new Date(resultsRange.from),
        endDate: new Date(resultsRange.to),
      })
      const selectedLabels = getFieldLabelFromKeys(fieldsToAggregate)
      setSelectedFieldLabels(selectedLabels)
    }
    const labelOptions = getFieldLabelFromKeys(props.aggregationOptions)
    setFieldLabelOptions(labelOptions)
    // if (props.queryResults) {
    //   setProfileOptionsLoader(true)
    // const uniqueProfileResults = initialiseProfileOptions(props.queryResults)
    //   setProfileResultOptions(uniqueProfileResults)
    //   setProfileOptionsLoader(false)
    // }
  }, [])

  // const initialiseProfileOptions = (queryResults: QueryResults) => {
  //   if (!queryResults?.results) {
  //     return []
  //   }
  //   let uniqueProfiles = {} as Record<string, any>
  //   queryResults?.results.forEach((result) => {
  //     const { profilePk } = result
  //     if (!profilePk) {
  //       return
  //     }
  //     if (!uniqueProfiles[profilePk]) {
  //       uniqueProfiles[profilePk] = result
  //     }
  //   })
  //   return Object.values(uniqueProfiles)
  // }

  const toggleFieldSelectionMode = () =>
    setFieldSelectionModalOpen((fieldSelectionModalOpen) => !fieldSelectionModalOpen)

  const toggleProfileSelectionMode = () =>
    setProfileSelectionModalOpen((profileSelectionModalOpen) => !profileSelectionModalOpen)

  const handleProfileSelectCancel = () => {
    setSelectedProfileIds([])
    toggleProfileSelectionMode()
  }
  const handleProfileSelectConfirm = () => {
    toggleProfileSelectionMode()
  }

  const onFieldLabelSelectionSave = (selectorItems: string[]) => {
    const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
    let selectedFieldKeys = [] as string[]
    Object.values(fieldConfig).forEach((configItem) =>
      selectorItems.includes(configItem.label) ? selectedFieldKeys.push(configItem.key) : null,
    )
    setSelectedFieldLabels(selectedFieldKeys)
    toggleFieldSelectionMode()
  }

  const handleDoneClick = () => {
    const requiredInputs = graphName && selectedFieldLabels.length > 0 && selectedProfileIds.length > 0
    if (!requiredInputs) {
      setMissingInputModalOpen(true)
      return
    }

    const trendConfig: TrendConfig = {
      trendName: graphName,
      profilesToInclude: selectedProfileIds,
      fieldsToAggregate: selectedFieldLabels,
      aggregateBy,
      resultsRange: {
        from: selectedDateRange.startDate.getTime(),
        to: selectedDateRange.endDate.getTime(),
      },
    }
    if (props.trendConfig) {
      trendConfig.id = props.trendConfig.id
    }
    props.onDoneClick(trendConfig)
  }

  const getFieldLabelFromKeys = (aggregationOptions: FieldConfigKey[]) => {
    try {
      const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
      let labelOptions = new Set<string>()
      aggregationOptions.forEach((fieldKey) => {
        if (fieldConfig[fieldKey]) {
          labelOptions.add(fieldConfig[fieldKey].label)
        }
      })
      return Array.from(labelOptions).sort()
    } catch (error) {
      console.log('error: ', error)
      return []
    }
  }

  let mainConfigModal = null
  if (mainModalOpen) {
    mainConfigModal = (
      <Modal
        open={true}
        title="Graph Configuration"
        actionButtons={[
          <ButtonGrey onClick={props.onCancelClick} style={{ marginTop: 20 }}>
            Cancel
          </ButtonGrey>,
          <ButtonBlue onClick={handleDoneClick} style={{ marginTop: 10 }}>
            Done
          </ButtonBlue>,
        ]}
        titleStyle={{ paddingBottom: 0 }}>
        <DataQueryGraphConfig
          graphName={graphName}
          dateRange={selectedDateRange}
          aggregateBy={aggregateBy}
          numberOfAggregationOptions={selectedFieldLabels.length}
          onGraphNameChange={(value: string) => setGraphName(value)}
          onDateRangeChange={(dateRange: Record<string, Date>) => setSelectedDateRange(dateRange)}
          onAggregateByChange={(value: AggregationTypesEnum) => setAggregateBy(value)}
          toggleProfileSelection={() => setProfileSelectionModalOpen(true)}
          toggleFieldSelection={toggleFieldSelectionMode}
          profileOptionsLoading={profileOptionsLoading}
          numberOfSelectedProfiles={selectedProfileIds.length}
          key={`trends_${refreshTimestamp}`}
        />
      </Modal>
    )
  }

  let profileToIncludeModal = null
  if (profileSelectionModalOpen) {
    profileToIncludeModal = (
      <Modal
        open={true}
        fullWidth={true}
        maxWidth="xl"
        title="Profiles to Include"
        actionButtons={[
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 40,
              gap: 20,
              width: '96%',
            }}>
            <ButtonGrey style={{}} onClick={handleProfileSelectCancel}>
              Cancel
            </ButtonGrey>
            <ButtonBlue style={{ marginLeft: 10 }} onClick={handleProfileSelectConfirm}>
              Confirm
            </ButtonBlue>
          </div>,
        ]}>
        <div style={{ height: '70vh', width: '96%' }}>
          <CheckboxTabLabelled
            style={{}}
            itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
            label={'INCLUDE ALL PROFILE EVENT RESULTS?'}
            values={includeAllResults}
            selectorItems={['YES', 'NO']}
            selectionHandler={(values: string[]) => setIncludeAllResults(values)}
          />
          <DataQueryTrendsProfiles
            profileTableColumnConfig={props.profileTableColumnConfig}
            profileFilterConfig={props.profileFilterConfig}
            profileTableRowData={props.profileTableRowData}
            initialSelectedRows={selectedProfileIds}
            onProfileSelect={setSelectedProfileIds}
            onBackClick={toggleProfileSelectionMode}
          />
        </div>
      </Modal>
    )
  }

  let fieldsToAggregateModal = null
  if (fieldSelectionModalOpen) {
    fieldsToAggregateModal = (
      <InfoCollectorModal
        open={true}
        defaultItems={selectedFieldLabels}
        pickerItems={fieldLabelOptions}
        header="FIELDS TO AGGREGATE"
        subHeader=""
        warningMessage="Add at least one item"
        validateInput={() => true}
        placeholder="Choose field"
        successLabel="Update"
        minimumItems={1}
        dismiss={toggleFieldSelectionMode}
        onSuccess={onFieldLabelSelectionSave}
        onReject={toggleFieldSelectionMode}
        type="picker"
      />
    )
  }

  let missingInputModal = null
  if (missingInputModalOpen) {
    missingInputModal = (
      <Modal
        open={true}
        title="MISSING INPUT"
        actionButtons={[<ButtonBlue onClick={() => setMissingInputModalOpen(false)}>OK</ButtonBlue>]}>
        <div>Please fill in all required fields</div>
      </Modal>
    )
  }

  return (
    <div>
      {mainConfigModal}
      {missingInputModal}
      {profileToIncludeModal}
      {fieldsToAggregateModal}
    </div>
  )
}
