import { httpGet } from '../remoteDataProvider'

export async function getBillingProfiles(
  selectedAssociation: string,
  billingPeriodStart: string,
  billingPeriodEnd: string,
  token: string,
) {
  try {
    const response = await httpGet(
      'people_flow_core',
      `/billing/association/${selectedAssociation}/profiles/${billingPeriodStart}/${billingPeriodEnd}`,
      token,
    )
    return response.data
  } catch (error) {
    throw error
  }
}
