import { pick } from 'lodash'

import { ConfigService, SessionService } from '.'
import { NewPpeInstanceParams, PpeActionRecord, PpeAvailability } from '../models/ppe'
import { AssociationSettingsRepository } from '../repositories'
import {
  AssociationId,
  AuthCredentials,
  FieldConfigKey,
  PpeQuantity,
  PpeRecordSk,
  PpeSize,
  PpeSku,
  PpeSupplierKeyValuePairs,
  PpeTypeConfig,
  PpeTypeUpsert,
  PpeUpsertResponse,
} from '../types'
import {
  getPpeInstances,
  getPpeRequests,
  uploadPpeActionRecords,
  exportPpeInventory,
} from '../providers/endpointDataProvider/ppe'
import { PpeInstanceRecordSchema } from '../models/ppe'
import { httpPost } from '../providers/remoteDataProvider'
import { PpeActions } from '../enums'
import { User } from '../models'

export class PpeService {
  getInstances = async (
    association: string,
    authCredentials: AuthCredentials,
  ): Promise<Record<PpeRecordSk, PpeInstanceRecordSchema>> => {
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    const { instanceRecordDtos } = await getPpeInstances(association, token)
    return instanceRecordDtos
  }

  createPpeInstances = async (
    association: string,
    requestedCreationInstanceParams: NewPpeInstanceParams[],
    authCredentials: AuthCredentials & { name: string; surname: string },
  ) => {
    const { name, surname, username, password } = authCredentials
    const actionRecord = new PpeActionRecord({
      rawData: {
        ppeAction: PpeActions.CREATE_INSTANCES,
        association,
        dateActioned: Date.now().toString(),
        requestedCreationInstanceParams,
        sourceName: name,
        sourceSurname: surname,
        sourcePk: username,
      },
    })
    const token = await SessionService.prepareAuthTokens(username, password)
    return await uploadPpeActionRecords(association, [actionRecord], token)
  }

  getAllTypeConfigs = (association: string, associationRepo: AssociationSettingsRepository): PpeTypeConfig[] => {
    const fieldConfig = associationRepo.getFieldConfig(association)
    const ppeTypeKeys = Object.keys(fieldConfig).filter((key) => fieldConfig[key].isPpe)
    return ppeTypeKeys.map((key) => fieldConfig[key] as PpeTypeConfig)
  }

  upsertType = async (
    association: string,
    type: PpeTypeUpsert,
    username: string,
    password: string,
    associationRepo: AssociationSettingsRepository,
  ): Promise<PpeUpsertResponse> => {
    const userIdPassport = username
    const fieldKey = type.key
    const changes = [
      {
        editedBy: userIdPassport,
        updatePath: [fieldKey],
        updatedData: type,
        updatedMs: +new Date(),
      },
    ]
    const response: { result: any; updatedConfig: any } | { result: any; updatedConfig?: undefined } =
      await ConfigService.updateConfig(association, 'fieldConfig', changes, { username, password })

    let updatedType: Record<string, PpeTypeConfig> = {}
    if (response.result === 'success') {
      updatedType = pick(response.updatedConfig, fieldKey) as Record<string, PpeTypeConfig>
      associationRepo.setFieldConfigItem(association, updatedType)
    }

    return { updatedType, updatedAssociationRepo: associationRepo }
  }

  getSuppliers = (association: string, associationRepo: AssociationSettingsRepository): PpeSupplierKeyValuePairs => {
    const { ppeSuppliers = {} } = associationRepo.getOrganisationConfig(association)
    return ppeSuppliers
  }

  upsertSuppliers = async (
    association: string,
    idPassport: string,
    password: string,
    associationRepo: AssociationSettingsRepository,
    suppliers: PpeSupplierKeyValuePairs,
  ): Promise<AssociationSettingsRepository | null> => {
    const changes = [
      {
        editedBy: idPassport,
        updatePath: ['ppeSuppliers'],
        updatedData: suppliers,
        updatedMs: +new Date(),
      },
    ]
    const response = await ConfigService.updateConfig(association, 'organisationConfig', changes, {
      username: idPassport,
      password,
    })
    if (response.result === 'success') {
      associationRepo.setOrganisationConfigPPESuppliers(association, suppliers)
      return associationRepo
    }

    return null
  }

  getRequests = async (association: string, authCredentials: AuthCredentials) => {
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    return await getPpeRequests(association, token)
  }

  uploadActionRecord = async (
    association: string,
    ppeActionRecords: Partial<PpeActionRecord>[],
    authCredentials: AuthCredentials,
  ): Promise<any> => {
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    console.log('association', association)
    console.log('ppeActionRecords', ppeActionRecords)

    return await uploadPpeActionRecords(association, ppeActionRecords, token)
  }

  async exportPpeInventory(association: string, emailAddresses: string[], authCredentials: AuthCredentials) {
    try {
      const { username, password } = authCredentials
      const token = await SessionService.prepareAuthTokens(username, password)
      await exportPpeInventory(association, emailAddresses, token)
    } catch (error) {
      throw error
    }
  }

  async ppeRequestExport(association: string, emailAddresses: string[], token: string) {
    if (emailAddresses.length === 0) {
      throw { code: 'MissingEmailAddresses' }
    }
    const payload = {
      targetEmails: emailAddresses,
    }
    const response = await httpPost(
      'people_flow_core',
      `/inventory/export/ppe/association/${association}/movement`,
      payload,
      token,
    )

    if (response.data.result !== 'success') {
      throw new Error('UnknownError')
    }
  }

  async transferInventory(
    requestedTransferInstanceParams: Record<
      AssociationId,
      Record<FieldConfigKey, Record<PpeSize, { qty: PpeQuantity; skus?: PpeSku[] }[]>>
    >,
    association: AssociationId,
    userEntity: User,
    authCredentials: AuthCredentials,
    reason: string,
  ) {
    const { name, surname, username } = userEntity.getPersonalUserInfo()
    const actionRecord = new PpeActionRecord({
      rawData: {
        ppeAction: PpeActions.TRANSFER,
        association,
        dateActioned: `${new Date().getTime()}`,
        sourceName: name,
        sourceSurname: surname,
        sourcePk: username,
        requestedTransferInstanceParams,
        ppeActionReasons: { allInstances: reason },
      },
    })
    return await this.uploadActionRecord(association, [actionRecord], authCredentials)
  }

  getInstancesWithAvailaibility(ppeInstances: PpeInstanceRecordSchema[], targetAvailability: PpeAvailability[]) {
    return ppeInstances.filter((ppeInstance: PpeInstanceRecordSchema) =>
      targetAvailability.includes(ppeInstance.availability),
    )
  }

  generateUnavailableItemMessageList(
    unavailablePpeItems: Record<FieldConfigKey, Record<string, number>>,
    associationRepo: AssociationSettingsRepository,
    association: AssociationId,
  ) {
    let messages = [] as string[]
    Object.keys(unavailablePpeItems).forEach((ppeKey) => {
      const ppeLabel = associationRepo.getFieldConfig(association)[ppeKey].label || ''
      const ppeSizes = Object.keys(unavailablePpeItems[ppeKey])
      ppeSizes.forEach((ppeSize) => {
        messages.push(`${unavailablePpeItems[ppeKey][ppeSize]} x ${ppeLabel} (${ppeSize}) unavailable`)
      })
    })
    return messages
  }
}
