import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import DataTable, {
  CustomFilterConfig,
  CustomFilterState,
  DataTableFilterState,
} from '../../Tables/DataTable/DataTable'
import { IColumnConfig } from '../../Tables/DataTable/DataTableHeader'
import { cloneDeep } from 'lodash'
import { PeopleFlowCombinedReducer } from '../../../store'
import { AssociationSettingsRepository } from '../../../repositories'
import { PpeRequestItem } from '../../../types'
import { TableFilterService } from '../../../services/tableFilterService'

interface PpeRequestReturnsProps {
  data: PpeRequestItem[]
  style?: React.CSSProperties
  onItemSelection: (selectedItemIds: string[]) => void
}

const tableFilterService = new TableFilterService()

const PpeRequestReturns = (props: PpeRequestReturnsProps) => {
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )

  const [tableData, setTableData] = useState<Record<string, any>[]>(props.data)
  const [allFilterState, setAllFilterState] = useState<DataTableFilterState>({})
  const [allSortedData, setAllSortedData] = useState<Record<string, any>[]>([])
  const [customFilterConfig, setCustomFilterConfig] = useState<CustomFilterConfig[]>([])
  const [customFilterState, setCustomFilterState] = useState<CustomFilterState[]>([])
  const [customFilterOpen, setCustomFilterOpen] = useState(false)
  const [headerRefreshTimestamp, setHeaderRefreshTimestamp] = useState<number>(Date.now())

  let tableColumnConfig: IColumnConfig[] = [
    { id: 'item', label: 'Item', sizeFactor: 1 },
    { id: 'sku', label: 'SKU', sizeFactor: 1 },
    { id: 'size', label: 'Size', sizeFactor: 1 },
    // { id: 'qty', label: 'Qty', sizeFactor: 1 },
    // { id: 'reason', label: 'Reason for return', sizeFactor: 1 },
  ]

  useEffect(() => {
    try {
      // let { filterState } = props;
      const filterState = {}
      if (filterState && Object.keys(filterState).length) {
        updateFilterState(filterState)
        // refreshTableHeader();
      } else {
        let allFilterState = tableFilterService.generateFilterState(tableColumnConfig, tableData, {})
        const data = tableFilterService.applyFilters(tableData, allFilterState)
        setAllFilterState(allFilterState)
        setAllSortedData(data)
        // this.setState({ allSortedData: tableData, allFilterState }, () =>
        //     this.loadPage(null, this.state.currentPage, true),
        // );
      }
      initialiseCustomFilterConfig()
    } catch (error) {
      console.error('error:', error)
    }
  }, [])

  const refreshTableHeader = () => setHeaderRefreshTimestamp(Date.now())

  const toggleCustomFilter = (saveAndClose: boolean) => {
    let filterState = cloneDeep(customFilterState)
    if (saveAndClose) {
      filterState = tableFilterService.cleanFilterState(filterState)
      // applyCustomFilters(filterState);
    }
    setCustomFilterOpen(!saveAndClose)
    setCustomFilterState(filterState)
  }

  const initialiseCustomFilterConfig = () => {
    const customFilterConfig = getFilterConfig()
    setCustomFilterConfig(customFilterConfig)
  }

  const getFilterConfig = (): CustomFilterConfig[] => {
    const config: CustomFilterConfig[] = tableColumnConfig.map((configItem) => {
      return {
        key: configItem.id,
        label: configItem.label,
      }
    })
    const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
    // NOTE: function belows relies on filterable fields existing in fieldConfig
    return associationRepo.populateFilterConfig(config, fieldConfig)
  }

  const updateFilterState = (updatedFilterState: DataTableFilterState, callback?: () => void) => {
    if (tableFilterService.shouldCalculateFilterState(updatedFilterState)) {
      updatedFilterState = tableFilterService.generateFilterState(tableColumnConfig, tableData, updatedFilterState)
    }
    const data = tableFilterService.applyFilters(tableData, updatedFilterState)
    setAllFilterState(updatedFilterState)
    setAllSortedData(data)
    if (callback) {
      callback()
    }
  }

  const removeFilters = (rowItemId: string) => {
    Object.keys(allFilterState).forEach((headerKey) => {
      if (headerKey === rowItemId) {
        Object.keys(allFilterState[headerKey]).forEach((filterKey) => (allFilterState[headerKey][filterKey] = false))
      }
    })
    updateFilterState(allFilterState)
    refreshTableHeader()
  }

  const { data, onItemSelection } = props

  return (
    <DataTable
      tableData={data}
      columnConfig={tableColumnConfig}
      tableWidth={12}
      filterState={{}}
      selectedRowItemId={''}
      onRowSelect={(selectedItems: Record<string, boolean>) => onItemSelection(Object.keys(selectedItems))}
      rowClickAsSelect={true}
      selectionEnabled={true}
      disabled={true}
      style={{ height: '100%' }}
    />
  )
}

export default PpeRequestReturns
