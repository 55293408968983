import uuid4 from 'uuid4'
import { validateDocFileNames } from '../../utils'
import { AdhocEventRecordSchema, FieldConfigKey, FileNameString, RawData, RecordUid } from '../../types'

class AdHocRecord {
  uid: RecordUid
  rawData: RawData
  docs: FileNameString[]
  constructor(jsonData: Record<string, any>) {
    this.uid = jsonData.uid || ''
    this.rawData = {
      ...jsonData.rawData,
      dateActioned: jsonData.rawData.dateActioned || '',
    }
    this.docs = jsonData.docs || []
  }
  addTimestamp = () => {
    this.rawData.dateActioned = `${new Date().getTime()}`
  }
  convertToJson = () => {
    return {
      uid: this.uid,
      rawData: this.rawData,
      docs: this.docs,
    }
  }
  getRawData = () => {
    return this.rawData
  }
  getAssociation = () => {
    return this.rawData.association || ''
  }
  getRawDataValue = (targetKey: FieldConfigKey, fallbackValue: any) => {
    return this.rawData[targetKey] !== undefined ? this.rawData[targetKey] : fallbackValue
  }
  getDocFileNames = () => {
    if (!validateDocFileNames(this.docs)) {
      return []
    }
    return this.docs
  }
  getUid = () => {
    return this.uid
  }
  generateUid = () => {
    if (this.uid) {
      return
    }
    this.uid = uuid4().substring(0, 36)
  }
  upsertRawData = (newData = {} as RawData, preventOverwrite = false) => {
    Object.keys(newData).forEach((key) => {
      if (this.rawData.hasOwnProperty(key) && preventOverwrite) {
        return
      }
      this.rawData[key] = newData[key]
    })
  }
  upsertDocs = (newDocs = []) => {
    this.docs.unshift(...newDocs)
  }
}

export default AdHocRecord
