import { forwardRef } from 'react'
import TextInputBasic from '../../BaseComponents/Text/TextInputBasic'
import Picker from '../../BaseComponents/Pickers/Picker'

import { ColorPalette } from '../../../config/colors'

type FieldValueInput = {
  currentFieldValue: string
  value: string
  fieldOptions?: string[]
  onFieldValuePairChange: (field: string, value: string) => void
  onValueInputClick?: () => void
  ref?: HTMLInputElement
}

type Ref = HTMLInputElement

export const FieldValueInput = forwardRef<Ref, FieldValueInput>((props, ref) => {
  const { currentFieldValue, value, fieldOptions, onFieldValuePairChange } = props
  const valueEntryDisabled = currentFieldValue === ''

  return (
    <div
      style={{
        display: 'flex',
        alignSelf: 'unset',
        flex: 1,
        padding: '0.8em 1.5em 1.25em',
        gap: 40,
      }}>
      <Picker
        style={{ width: '50%' }}
        value={currentFieldValue}
        items={fieldOptions || []}
        placeholder="Select a field"
        onChange={(e: any) => onFieldValuePairChange(e.target.value, '')}
      />
      <TextInputBasic
        ref={ref}
        style={{
          color: ColorPalette.PRIMARY_TEXT,
          width: '50%',
          borderBottom: `1px solid ${ColorPalette.PRIMARY_TEXT}`,
          marginBottom: 10,
        }}
        placeholder={valueEntryDisabled ? 'Select field first' : 'Which value should be set?'}
        value={value}
        onChange={(e: any) => onFieldValuePairChange(currentFieldValue, e.target.value)}
        disabled={valueEntryDisabled}
        onClick={props.onValueInputClick}
      />
    </div>
  )
})
