import { cloneDeep } from 'lodash'

import { APP_VERSION } from '../cloud-config'
import {
  getTimeBasedDocRecords,
  generateTerminationPlan,
  triggerDisciplineFormGeneration,
  exportProfileDataTemplate,
  checkForTerminationPlanResults,
  getRemoteFile,
} from '../providers'
import { SessionService } from './sessionService'
import { CommonTs } from '../models/common-ts/commonts'
import { AssociationId, AuthCredentials, DocAuditReportResponse } from '../types'
import { updateProfile } from '../providers/endpointDataProvider/employees'
import { csvToJson } from '../utils'

export class ImportExportService {
  async exportRemoteData(
    selectedAssociation: any,
    selectedProfiles: string[],
    selectedExportTemplate: any,
    selectedExportFormat: string,
    emails: any,
    authCredentials: AuthCredentials,
  ): Promise<void> {
    const payload = {
      selectedEmployer: selectedAssociation,
      selectedProfiles,
      selectedExportTemplate,
      selectedExportFormat,
      emails,
      webVersion: APP_VERSION,
    }
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    exportProfileDataTemplate(payload, token)
  }

  async exportDocAuditReports(
    selectedAssociation: any,
    emails: any,
    cohorts: string[],
    authCredentials: AuthCredentials,
  ): Promise<DocAuditReportResponse> {
    const params = {
      emails,
      cohorts,
      ignoreJobType: true,
      flagUnpersisted: true,
    }

    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    return await getTimeBasedDocRecords(selectedAssociation, params, token)
  }

  async generateBulkDisciplineForms(
    selectedAssociation: string,
    selectedDate: Date,
    emails: string[],
    authCredentials: AuthCredentials,
  ): Promise<void> {
    try {
      const { username, password } = authCredentials
      const token = await SessionService.prepareAuthTokens(username, password)
      await triggerDisciplineFormGeneration(selectedAssociation, selectedDate, emails, token)
    } catch (error) {
      throw error
    }
  }

  async planTerminationImport(
    association: AssociationId,
    triggerUnixMs: string,
    fileName: string,
    authCredentials: AuthCredentials,
    options?: CommonTs.Import.ITerminateProfileOptions,
  ) {
    let configOptions: CommonTs.Import.ITerminateProfileOptions = options ? cloneDeep(options) : {}
    const bulkActionConfig = {
      action: CommonTs.Import.IImportAction.TERMINATE,
      options: configOptions,
      allowablePlanWarningReasons: [
        CommonTs.Import.InvalidationReason.UNKNOWN_KEY,
        CommonTs.Import.PlanProblemReason.CORRUPT_EXISTING_TERMINATION_STATE,
      ],
      filter: {
        ignoreValidations: [
          CommonTs.Import.PlanProblemReason.INSUFFICIENT_INPUT,
          CommonTs.Import.PlanProblemReason.ALREADY_TERMINATED,
          CommonTs.Import.PlanProblemReason.NOTHING_TO_DO,
          CommonTs.Import.PlanProblemReason.PROFILE_DOES_NOT_EXIST,
        ],
      },
    }
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    await generateTerminationPlan(association, triggerUnixMs, fileName, bulkActionConfig, token)
  }

  async applyProfileChange(
    selectedEmployer: string,
    profileChange: CommonTs.Import.ProfileChange,
    authCredentials: AuthCredentials,
  ): Promise<any> {
    try {
      const { username, password } = authCredentials
      const token = await SessionService.prepareAuthTokens(username, password)
      return await updateProfile(selectedEmployer, profileChange, token)
    } catch (error) {
      throw error
    }
  }

  async getTerminationPlanResults(
    association: AssociationId,
    triggerUnixMs: string,
    authCredentials: AuthCredentials,
  ): Promise<{ isComplete: boolean; records: CommonTs.Import.IImportPlan[] } | void> {
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    const { isComplete, resultsFileLocation } = await checkForTerminationPlanResults(association, triggerUnixMs, token)
    if (isComplete) {
      const { fileDir, fileName, fileFormat } = resultsFileLocation
      let resultsPath = `${fileDir}/${fileName}.${fileFormat}`
      if (resultsPath.startsWith('public')) {
        resultsPath = resultsPath.replace('public/', '')
      }
      const data = await getRemoteFile(resultsPath, { download: true, cacheControl: 'no-cache' })
      if (!data) {
        throw { code: 'MissingTerminationRecordsFile' }
      }
      return { isComplete, records: JSON.parse(data.Body) }
    }
  }
}
