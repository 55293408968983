import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import ButtonBlue from '../../BaseComponents/Buttons/ButtonBlue'
import ButtonGrey from '../../BaseComponents/Buttons/ButtonGrey'
import CheckboxTabLabelled from '../../BaseComponents/Checkboxes/CheckboxTabLabelled'
import Modal from '../Modal'
import { PeopleFlowCombinedReducer } from '../../../store'
import { ColorPalette } from '../../../config'
import TextInputLabelled from '../../BaseComponents/Text/TextInputLabelled'
import PickerContained from '../../BaseComponents/Pickers/PickerContained'
import CalendarRangeContained from '../../BaseComponents/Calendar/CalendarRangeContained'
import { removeUnderScores, toUpperCaseCustom } from '../../../utils'
import { QUERY_OPERATIONS_TO_READABLE } from '../../../constants'
import { AssociationSettingsRepository } from '../../../repositories'
import { DateRange, FilterTypeEnum } from '../../../types'
import { FieldConfig } from '../../../models/fieldConfig/fieldConfigModel'

type QuerySettingsModalProps = {
  fieldConfigEntity: FieldConfig
  selectedType: FilterTypeEnum
  target: string
  comparatorType: string
  comparatorValue?: string | DateRange
  onCancelClick: () => void
  onDoneClick: (
    dataType: FilterTypeEnum,
    field: string,
    comparatorType: string,
    value?: string | Record<string, Date>,
  ) => void
}

const FIELD_COMPARATOR_OPTIONS = QUERY_OPERATIONS_TO_READABLE
const DOC_COMPARATOR_OPTIONS = {
  none: 'NONE',
  timeRange: 'TIME RANGE',
}

function getInitialDateRange(comparatorValue?: string | DateRange) {
  if (typeof comparatorValue === 'object' && (comparatorValue.startDate || comparatorValue.endDate)) {
    return comparatorValue
  }

  return {
    startDate: new Date(new Date().getTime() - 2408000000),
    endDate: new Date(),
  }
}

export const DataQueryFilterSettingsModal = (props: QuerySettingsModalProps) => {
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository

  const [selectedFilterType, setSelectedFilterType] = useState<FilterTypeEnum>(props.selectedType)
  const [selectedFieldLabel, setSelectedFieldLabel] = useState(
    props.selectedType === FilterTypeEnum.FIELD ? props.target : '',
  )
  const [selectedDocTemplate, setSelectedDocTemplate] = useState(
    props.selectedType === FilterTypeEnum.DOCUMENT ? props.target : '',
  )
  const [selectedComparator, setSelectedComparator] = useState(QUERY_OPERATIONS_TO_READABLE[props.comparatorType] || '')
  const [comparatorValue, setComparatorValue] = useState(props.comparatorValue || '')
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>(getInitialDateRange(props.comparatorValue))
  const [allFieldLabels, setAllFieldLabels] = useState([] as string[])

  useEffect(() => {
    const allFieldLabels = props.fieldConfigEntity.getAllAvailableLabels().sort()
    setAllFieldLabels(allFieldLabels)
  }, [])

  const handleDocumentSelection = (e: React.ChangeEvent<{ value: string }>) => {
    const selectedDocument = e.target.value
    setSelectedDocTemplate(selectedDocument)
  }

  const handleComparatorSelection = (e: React.ChangeEvent<{ value: string }>) => {
    const selectedComparator = e.target.value
    setSelectedComparator(selectedComparator)
  }

  const handleDateRangeSelection = (dateRange: DateRange) => setSelectedDateRange(dateRange)

  const getComparatorOptions = () => {
    if (selectedFilterType === 'field') {
      return Object.values(FIELD_COMPARATOR_OPTIONS)
    }
    return Object.values(DOC_COMPARATOR_OPTIONS)
  }

  const getSelectedComparatorValue = () => {
    if (selectedComparator === 'TIME RANGE') {
      return selectedDateRange
    }
    if (selectedComparator !== 'NONE') {
      return comparatorValue
    }
    return undefined
  }

  const handleDoneClick = () => {
    const selectedTarget = selectedFilterType === 'field' ? selectedFieldLabel : selectedDocTemplate
    const selectedComparatorValue = getSelectedComparatorValue()
    props.onDoneClick(selectedFilterType, selectedTarget, selectedComparator, selectedComparatorValue)
  }

  const publishedDocTemplates = associationRepo.getPublishedDocTemplates(selectedAssociation)
  const allDocuments = Object.keys(publishedDocTemplates).map(removeUnderScores)
  const allComparatorOptions = getComparatorOptions()

  let fieldSelection = null
  if (selectedFilterType === FilterTypeEnum.FIELD) {
    const noComparatorValueRequired =
      [
        QUERY_OPERATIONS_TO_READABLE.exists,
        QUERY_OPERATIONS_TO_READABLE.doesntExist,
        QUERY_OPERATIONS_TO_READABLE.hasChanged,
      ].indexOf(selectedComparator) > -1

    let valueInput = null
    if (!noComparatorValueRequired) {
      valueInput = (
        <TextInputLabelled
          key="comparatorValue"
          inputProps={{ color: ColorPalette.PRIMARY_TEXT, fontSize: '0.8rem' }}
          label="COMPARATOR VALUE"
          value={comparatorValue as string}
          placeholder="Enter comparator value"
          textHandler={(text) => setComparatorValue(toUpperCaseCustom(text))}
          containerStyle={{ marginTop: 35 }}
          primaryStyle={{ width: '92%' }}
        />
      )
    }

    fieldSelection = (
      <>
        <PickerContained
          style={styles.picker}
          label="SELECT A FIELD"
          items={allFieldLabels}
          value={selectedFieldLabel || ''}
          onChange={(event) => setSelectedFieldLabel(event.target.value)}
          disabled={false}
        />
        <PickerContained
          style={styles.picker}
          label="SELECT COMPARATOR TYPE"
          items={allComparatorOptions}
          value={selectedComparator}
          onChange={handleComparatorSelection}
          disabled={false}
        />
        {valueInput}
      </>
    )
  }

  let documentSelection = null
  if (selectedFilterType === FilterTypeEnum.DOCUMENT) {
    let dateRangeSelector = null
    if (selectedComparator === 'TIME RANGE') {
      dateRangeSelector = (
        <CalendarRangeContained
          label="CAPTURE IN DATE RANGE"
          dateRange={selectedDateRange}
          onDateChange={(date?: number, rangePoint?: string) => {
            if (!date || !rangePoint) {
              return
            }
            handleDateRangeSelection({ ...selectedDateRange, [rangePoint]: new Date(date) })
          }}
          minDate={new Date(2023, 0, 1)}
          maxDate={new Date()}
          orientation="horizontal"
          inputStyle={styles.dateRangeInput}
          textStyle={styles.dateRangeText}
          containerStyle={{ width: '100%', marginTop: 50 }}
        />
      )
    }

    documentSelection = (
      <>
        <PickerContained
          style={styles.picker}
          label="SELECT A DOCUMENT"
          items={allDocuments.sort()}
          value={selectedDocTemplate || allDocuments[0]}
          onChange={handleDocumentSelection}
          disabled={false}
        />
        <PickerContained
          style={{ ...styles.picker }}
          label="SELECT COMPARATOR TYPE"
          items={allComparatorOptions}
          value={selectedComparator}
          onChange={handleComparatorSelection}
          disabled={false}
        />
        {dateRangeSelector}
      </>
    )
  }

  return (
    <Modal
      open={true}
      title="Query Filter Settings"
      actionButtons={[
        <ButtonGrey
          onClick={props.onCancelClick}
          key="cancelButton"
          style={{ width: window.innerWidth * 0.28, marginTop: 40 }}>
          Cancel
        </ButtonGrey>,
        <ButtonBlue onClick={handleDoneClick} key="doneButton" style={{ marginTop: 10 }}>
          Done
        </ButtonBlue>,
      ]}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: window.innerWidth * 0.27 }}>
        <CheckboxTabLabelled
          style={{ width: '100%', marginTop: 25 }}
          itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
          label="DATA TYPE TO QUERY"
          values={[selectedFilterType]}
          selectorItems={['FIELD', 'DOCUMENT']}
          selectionHandler={(values: string[], isMount) => {
            if (!isMount) {
              setSelectedFilterType(values[0].toLowerCase() as FilterTypeEnum)
            }
          }}
          maxItems={1}
        />
        {fieldSelection}
        {documentSelection}
      </div>
    </Modal>
  )
}

const styles = {
  dateRangeInput: {
    border: 'none',
    padding: 0,
  },
  dateRangeText: {
    marginTop: '0.75rem',
    marginBottom: 0,
    padding: 0,
    fontSize: '0.85em',
  },
  picker: {
    width: '100%',
    marginTop: 50,
    color: ColorPalette.PRIMARY_TEXT,
  },
}
