import { ColorPalette } from '../../../config/colors'
import ButtonGrey from '../../BaseComponents/Buttons/ButtonGrey'
import ButtonBlue from '../../BaseComponents/Buttons/ButtonBlue'
import DocumentFilterButton from './DocumentFilterButton'
import { getObjectKeyByValue } from '../../../utils'
import { DateRange } from '../../Modals/DateRanges/DateRangesModal'
import { QUERY_OPERATIONS_TO_READABLE } from '../../../constants'
import QueryFilterButton from './QueryFilterButton'
import { FilterUiListItem } from '../../../types'

type DataQueryFilterListProps = {
  filters: FilterUiListItem[][]
  fieldOptions: string[]
  setFilters: (filters: FilterUiListItem[][]) => void
  setFilterField: (index: number, result: string, orBlockIndex: number) => void
  toggleFilterType: (index: number, orBlockIndex: number) => void
  setFilterValue: (index: number, value: string | Record<string, Date>, orBlockIndex: number) => void
  deleteFilter: (index: number, orBlockIndex: number) => void
  onClick: (index: number, orBlockIndex: number) => void
  triggerFilterSave: (filters: FilterUiListItem[][]) => void
}

export const DataQueryFilterList = (props: DataQueryFilterListProps) => {
  const splitFiltersAtIndex = (filters: FilterUiListItem[], index: number, orBlockIndex: number) => {
    const firstArray = filters.slice(0, index)
    const secondArray = filters.slice(index)
    const newFiltersInCurrentOrBlock = [[...firstArray], [...secondArray]]
    const newFilters = [...props.filters]
    newFilters.splice(orBlockIndex, 1, ...newFiltersInCurrentOrBlock)
    props.setFilters(newFilters)
    props.triggerFilterSave(newFilters)
  }

  const mergeOrBlocks = (firstBlock: FilterUiListItem[], secondBlock: FilterUiListItem[], index: number) => {
    const newFilters = [...props.filters]
    newFilters[index] = [...firstBlock, ...secondBlock]
    newFilters.splice(index + 1, 1)
    props.setFilters(newFilters)
    props.triggerFilterSave(newFilters)
  }

  const generateConnectors = (filters: FilterUiListItem[], orBlockIndex: number) =>
    filters.map((filter, filterIndex) => {
      if (filterIndex === 0) {
        return <div style={{ marginLeft: 125 }} key="addButton_1st"></div>
      }
      return (
        <div style={styles.connector}>
          <ButtonGrey
            style={styles.filterButton}
            onClick={() => splitFiltersAtIndex(filters, filterIndex, orBlockIndex)}
            key={`addButton_${filterIndex}`}>
            AND
          </ButtonGrey>
          <div
            style={{
              height: 70,
              width: 20,
              borderLeft: '1.5px solid lightgrey',
              borderTop: '1.5px solid lightgrey',
              borderBottom: '1.5px solid lightgrey',
            }}></div>
        </div>
      )
    })

  const generateFilterList = (filters: FilterUiListItem[], orBlockIndex: number) => {
    const { fieldOptions, setFilterField, toggleFilterType, setFilterValue, deleteFilter } = props

    const filterList = filters.map((item, index) => {
      let warning = false
      const filterButtonStyle: React.CSSProperties = { marginTop: 40 }

      if (item.filterType === 'document') {
        return (
          <DocumentFilterButton
            dateRange={item.filterValue as DateRange}
            selectedDocument={item.selectedTarget || ''}
            setFilterField={(result) => setFilterField(index, result, orBlockIndex)}
            toggleFilterType={() => toggleFilterType(index, orBlockIndex)}
            onDateChange={(dateRange) => setFilterValue(index, dateRange, orBlockIndex)}
            deleteFilter={() => deleteFilter(index, orBlockIndex)}
            documents={fieldOptions}
            warning={warning}
            onClick={() => props.onClick(index, orBlockIndex)}
            style={filterButtonStyle}
            key={`docFilter_${orBlockIndex}_${index}`}
          />
        )
      }
      return (
        <QueryFilterButton
          filterComparatorType={
            getObjectKeyByValue(QUERY_OPERATIONS_TO_READABLE, item.comparatorType as string) || item.comparatorType
          }
          filterValue={item.filterValue as string}
          selectedField={item.selectedTarget as string}
          setFilterField={(result) => setFilterField(index, result, orBlockIndex)}
          toggleFilterType={() => toggleFilterType(index, orBlockIndex)}
          textHandler={(text) => setFilterValue(index, text, orBlockIndex)}
          deleteFilter={() => deleteFilter(index, orBlockIndex)}
          fields={fieldOptions}
          warning={warning}
          onClick={() => props.onClick(index, orBlockIndex)}
          style={filterButtonStyle}
          key={`fieldFilter_${orBlockIndex}_${index}`}
        />
      )
    })
    return filterList
  }

  const generateAllFilters = (filters: FilterUiListItem[][]) => {
    return filters.map((orBlockFilters: FilterUiListItem[], orBlockIndex: number) => {
      const filterAndConnectors = generateConnectors(orBlockFilters, orBlockIndex)
      const filterList = generateFilterList(orBlockFilters, orBlockIndex)
      const filterListStyle: React.CSSProperties = { width: '100%', marginTop: -40 }

      let orButton = null
      if (props.filters.length > 1 && orBlockIndex < props.filters.length - 1) {
        orButton = (
          <div style={{ display: 'flex', alignItems: 'center', margin: '30px 30px 30px 20px', width: '100%' }}>
            <ButtonBlue
              style={{ width: 80, height: 30 }}
              onClick={() => mergeOrBlocks(orBlockFilters, filters[orBlockIndex + 1], orBlockIndex)}>
              OR
            </ButtonBlue>
            <div style={{ borderTop: `2px dashed ${ColorPalette.PRIMARY_BLUE}`, width: '100%' }}></div>
          </div>
        )
      }

      return (
        <div key={`filters_orBlockIndex_${orBlockIndex}`}>
          <div style={{ display: 'flex', marginTop: 20 }}>
            <div style={{ marginTop: 12 }}>{filterAndConnectors}</div>
            <div style={filterListStyle}>{filterList}</div>
          </div>
          {orButton}
        </div>
      )
    })
  }

  const allFilters = generateAllFilters(props.filters)
  return <div>{allFilters}</div>
}

const styles = {
  connector: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    marginTop: 8,
  },
  filterButton: {
    width: 95,
    height: 30,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundImage: 'none',
    backgroundColor: ColorPalette.MEDIUM_GREY,
  },
}
