import React from 'react'
import Radium from 'radium'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import Calendar from './Calendar'
import { ColorPalette } from '../../../config/colors'

export type CalendarRangeProps = {
  id?: string
  dateRange: Record<string, Date>
  orientation?: 'horizontal' | 'vertical'
  minDate?: Date
  maxDate?: Date
  style?: React.CSSProperties
  inputStyle?: React.CSSProperties
  textStyle?: React.CSSProperties
  startDateLabel?: string
  endDateLabel?: string
  dateFormat?: string
  noToLabel?: boolean
  dialogStyle?: React.CSSProperties
  minStartDate?: Date
  maxStartDate?: Date
  minEndDate?: Date
  maxEndDate?: Date
  label?: string
  onClose?: () => void
  onSave?: () => void
  onDateChange: (date?: number, rangePoint?: string, id?: string) => void
}

const CalendarRange = ({
  id,
  dateRange,
  orientation = 'horizontal',
  minDate,
  maxDate,
  style,
  inputStyle,
  textStyle,
  startDateLabel,
  endDateLabel,
  dateFormat,
  noToLabel,
  dialogStyle,
  minStartDate,
  maxStartDate,
  minEndDate,
  maxEndDate,
  onClose,
  onSave,
  onDateChange,
}: CalendarRangeProps) => {
  const orientationHorizontal = orientation === 'horizontal'

  const startDateStyle = {
    width: orientationHorizontal ? '100%' : 'unset',
    ...inputStyle,
  }
  let endDateStyle = {
    width: orientationHorizontal ? '100%' : 'unset',
    ...inputStyle,
  }
  let containerStyle = { ...styles.container, ...style }
  if (!orientationHorizontal) {
    endDateStyle = { ...endDateStyle, marginTop: 0 }
    containerStyle = { ...containerStyle, flexDirection: 'column' }
  }

  let inbetweenLabel = null
  if (!noToLabel) {
    inbetweenLabel = <h1 style={{ ...styles.textStyle, ...textStyle }}>{'to'}</h1>
  }

  return (
    <div style={containerStyle}>
      <Calendar
        style={startDateStyle}
        format={dateFormat || 'dd/MM/yyyy'}
        label={startDateLabel || 'Start'}
        value={dateRange.startDate}
        minDate={minDate || minStartDate}
        maxDate={maxDate || maxStartDate}
        onChange={(date: MaterialUiPickersDate) => onDateChange(date?.getTime(), 'startDate', id)}
        onClose={onClose}
        onSave={onSave}
        dialogStyle={dialogStyle}
      />
      {inbetweenLabel}
      <Calendar
        style={endDateStyle}
        format={dateFormat || 'dd/MM/yyyy'}
        label={endDateLabel || 'End'}
        value={dateRange.endDate}
        minDate={minDate || minEndDate}
        maxDate={maxDate || maxEndDate}
        onClose={onClose}
        onSave={onSave}
        onChange={(date: MaterialUiPickersDate) => onDateChange(date?.getTime(), 'endDate', id)}
        dialogStyle={dialogStyle}
      />
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    gap: 10,
  },
  textStyle: {
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontWeight: 'normal',
    color: ColorPalette.PRIMARY_TEXT,
    fontSize: 14,
    paddingLeft: 2,
    paddingRight: 2,
  },
}

export default Radium(CalendarRange)
