import { APP_VERSION } from '../cloud-config'
import { cloneDeep, isEqual } from 'lodash'
import UpdateTrackerItemRawData from '../models/logging/update.tracker.item.raw.data'
import { Profile } from '../models'
import { FieldConfigSchema, FieldConfigItemSchema } from '../types'
import { ISectionConfigItem } from '../interfaces'

interface IFormattedChange {
  type: 'heading' | 'listItem'
  key: string
  change?: { old: any; new: any }
}

export function buildUpdateTrackerItem(
  rawDataChanges: Record<string, { old: any; new: any }>,
  profile: Profile,
  userInfo: { name: string; surname: string; idPassport: string },
): UpdateTrackerItemRawData {
  const timestamp = new Date().getTime()
  const { name, surname, idPassport } = profile.getPersonalInfo()
  const uid = profile.getUid()

  let updateItem = new UpdateTrackerItemRawData(idPassport, uid, name, surname, 'WEB')
  return logRawDataChanges(rawDataChanges, updateItem, timestamp, userInfo, `${APP_VERSION} (WEB)`)
}

function logRawDataChanges(
  rawDataChanges: Record<string, { old: any; new: any }>,
  updateItem: UpdateTrackerItemRawData,
  timestamp: number,
  userInfo: { name: string; surname: string; idPassport: string },
  appVersion: string,
) {
  try {
    const { name, surname, idPassport } = userInfo
    const userChangeId = `${timestamp}___${idPassport}`

    Object.keys(rawDataChanges).forEach((key) => {
      const changeLog = {
        old: rawDataChanges[key].old,
        new: rawDataChanges[key].new,
        name,
        surname,
        appVersion,
      }
      if (key in updateItem.changes.rawData) {
        updateItem.changes.rawData[key] = { ...updateItem.changes.rawData[key], [userChangeId]: changeLog }
      } else {
        updateItem.changes.rawData[key] = { [userChangeId]: changeLog }
      }
    })
    return updateItem
  } catch (error) {
    return updateItem
  }
}

export function getDataChanges(oldData: Record<string, any>, newData: Record<string, any>) {
  const formatInfo = (data: any) => {
    if (Array.isArray(data)) {
      return data.join(' ')
    }
    return data
  }

  let changes: Record<string, { old: any; new: any }> = {}
  Object.keys(newData).forEach((dataKey) => {
    oldData[dataKey] = dataKey in oldData ? oldData[dataKey] : ''
    const oldFormatted = formatInfo(oldData[dataKey])
    const newFormatted = formatInfo(newData[dataKey])
    if (!isEqual(oldFormatted, newFormatted)) {
      changes[dataKey] = { old: oldFormatted, new: newFormatted }
    }
  })
  return changes
}

export function extractNewFromDataChanges(changes: Record<string, { old: any; new: any }>) {
  let newData: Record<string, any> = {}
  Object.keys(changes).forEach((key) => {
    newData[key] = changes[key].new
  })
  return newData
}

export function generateListOfChanges(
  changes: Record<string, { old: any; new: any }>,
  fieldConfig: FieldConfigSchema,
  dataFlow: Record<string, FieldConfigItemSchema[]>,
  sectionConfig: ISectionConfigItem[],
): IFormattedChange[] {
  changes = cloneDeep(changes)

  try {
    let sectionLabelMap: Record<string, { section: string; label: string }> = {}
    Object.keys(dataFlow).forEach((section) => {
      dataFlow[section].forEach((configItem, index) => {
        if (!fieldConfig[configItem.key]) {
          console.info(`${configItem.key} not found in fieldConfig and won't appear in list of changes`)
          return
        }
        sectionLabelMap[configItem.key] = {
          section,
          label: dataFlow[section][index].label ? dataFlow[section][index].label : fieldConfig[configItem.key].label,
        }
      })
    })

    let processedSectionHeadings: Record<string, boolean> = {}
    let formattedChanges: IFormattedChange[] = []
    Object.keys(changes).forEach((key) => {
      if (sectionLabelMap[key]) {
        if (!processedSectionHeadings[sectionLabelMap[key].section]) {
          let sectionLabel = ''
          sectionConfig.forEach((configItem) =>
            configItem.sectionName === sectionLabelMap[key].section ? (sectionLabel = configItem.sectionLabel) : null,
          )
          formattedChanges = [
            ...formattedChanges,
            {
              type: 'heading',
              key: sectionLabel,
            },
          ]
          processedSectionHeadings[sectionLabelMap[key].section] = true
        }

        let change = changes[key]
        if (Array.isArray(change.new)) {
          change.new = change.new.join(', ')
        }
        if (Array.isArray(change.old)) {
          change.old = change.old.join(', ')
        }
        formattedChanges = [
          ...formattedChanges,
          {
            type: 'listItem',
            key: sectionLabelMap[key].label,
            change,
          },
        ]
      }
    })
    return formattedChanges
  } catch (error) {
    throw error
  }
}
