import React, { LegacyRef, createRef, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Radium from 'radium'
import { RouteComponentProps } from 'react-router'

import { GridSize } from '@material-ui/core/Grid'
import { cloneDeep } from 'lodash'

import { ColorPalette } from '../../config/colors'
import { ActionType } from '../../store/actions/actions'
import { titleCase } from '../../utils'
import { ScreenNamesEnum } from '../../types'
import DataTable from '../../components/Tables/DataTable/DataTable'
import NavBar from '../../components/Navigation/NavBar'
import SectionHeader from '../../components/Headings/SectionHeaderPrimary'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import { Profile } from '../../models'
import { PeopleFlowCombinedReducer } from '../../store'
import { AssociationSettingsRepository, ProfileRepository } from '../../repositories'
import { TrashStatus } from '../../models/common-ts/ProfileDocument'

interface TrashProps extends RouteComponentProps {}

type ProfileCore = { id: string; name: string; surname: string; idPassport: string }
type ColumnConfig = { id: string; label: string; sizeFactor: number }

const sectionHeaderRef: LegacyRef<any> = createRef()
const primaryTableRef: LegacyRef<DataTable> = createRef()

const TrashedProfiles = (props: TrashProps) => {
  const initialModalState = {
    loadingModalOpen: false,
    accountModalOpen: false,
    forgotPasswordModalOpen: false,
    settingsModalOpen: false,
    loadingModalMessage: '',
  }

  const [modalState, setModalState] = useState(initialModalState)
  const [sideMenuVisible, setSideMenuVisible] = useState(false)
  const [initialDownloading, setInitialDownloading] = useState(true)
  const [profileData, setProfileData] = useState<ProfileCore[]>([])
  const [tableWidth, setTableWith] = useState<GridSize>(10)
  const [customProfileFilterConfig, setCustomProfileFilterConfig] = useState<{ key: string; label: string }[]>()
  const [retrievingProfiles, setRetrievingProfiles] = useState(true)
  const [searchString, setSearchString] = useState('')

  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  const profileRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.profileRepo,
  ) as ProfileRepository
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const selectedCohort = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.selectedCohort)
  const currentSection = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.currentSection)
  const currentScreen = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.currentScreen)
  const navMenuAccess = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.navMenuAccess)

  const tableColumnConfig = useRef<ColumnConfig[]>([])

  useEffect(() => {
    setTimeout(() => {
      initialiseCustomProfileFilterConfig()
      initialiseCustomTableColumnConfig()
      updateState({
        profilePic: '',
        currentSection: ScreenNamesEnum.TRASH,
        currentScreen: ScreenNamesEnum.TRASH,
      })
      loadTable()
    }, 150)
  }, [])

  useEffect(() => {
    if (!primaryTableRef?.current) {
      return
    }
    primaryTableRef.current.reload()
  }, [profileData.length])

  const dispatch = useDispatch()
  const updateState = (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data })

  const initialiseCustomProfileFilterConfig = () => {
    const customProfileFilterConfig = associationRepo.getProfileFilterConfig(selectedAssociation)
    setCustomProfileFilterConfig(customProfileFilterConfig)
  }

  const initialiseCustomTableColumnConfig = () => {
    const customProfileTableColumnConfig = associationRepo.getProfileTableColumnConfig(selectedAssociation)
    const columnConfig = cloneDeep(customProfileTableColumnConfig)
    columnConfig.forEach((config: any) => {
      config.id = config.key
      delete config.key
    })
    // @ts-ignore
    tableColumnConfig.current = [...columnConfig]
  }

  // const refreshData = async () => {
  //   setModalState({
  //     ...initialModalState,
  //     loadingModalOpen: true,
  //     loadingModalMessage: 'Refreshing data...',
  //   })
  //   loadProfileData()
  // }

  const loadTable = (): Promise<void> => {
    setInitialDownloading(true)
    return loadTableInternal().finally(() => {
      setInitialDownloading(false)
    })
  }

  const loadTableInternal = (): Promise<void> => {
    return new Promise<void>((res, rej) => {
      if (primaryTableRef && primaryTableRef.current) {
        loadProfileData()
        res()
      } else if (currentSection === ScreenNamesEnum.PROFILES) {
        setTimeout(() => {
          loadTableInternal()
            .catch((err) => rej(err))
            .finally(() => {
              res()
            })
        }, 250)
      }
    })
  }

  const loadProfileData = async () => {
    setRetrievingProfiles(true)
    const trashedProfiles = profileRepo.getTargetProfiles([selectedCohort], undefined, [TrashStatus.TRASHED])
    const profileData: ProfileCore[] = prepareProfileDataForDisplay(trashedProfiles, [])
    setProfileData(profileData)
    setRetrievingProfiles(false)

    return new Promise<void>((res) => res())
  }

  const getFilterFields = (
    generalData: Record<string, any>,
    customProfileFilterConfig: { key: string; label: string }[],
  ): Record<string, any> => {
    let filterFields = {}
    customProfileFilterConfig.forEach((configItem) => {
      if (configItem.key in generalData) {
        filterFields = { ...filterFields, [configItem.key]: generalData[configItem.key] }
      }
    })
    return filterFields
  }

  const prepareProfileDataForDisplay = (
    trashedProfiles: Record<string, Profile>,
    customProfileFilterConfig: { key: string; label: string }[],
  ): any[] => {
    if (trashedProfiles === undefined) {
      return []
    }
    let trashedProfilesPks = Object.keys(trashedProfiles)
    let dataToRender: Record<string, any>[] = []
    trashedProfilesPks.forEach((pk) => {
      const profile = trashedProfiles[pk]
      let filterFields = getFilterFields(profile.getGeneralData(), customProfileFilterConfig)
      let columnFieldKeys = tableColumnConfig.current.map((columnConfig) => columnConfig.id)
      let generalData = profile.getGeneralData()

      let data = { id: generalData.idPassport, ...filterFields }
      columnFieldKeys.forEach((fieldKey) => {
        // @ts-ignore
        data[fieldKey] = generalData.hasOwnProperty(fieldKey)
          ? Array.isArray(generalData[fieldKey])
            ? generalData[fieldKey][0]
            : generalData[fieldKey]
          : '-'
      })
      dataToRender.push(data)
    })
    return dataToRender
  }

  const searchHandler = (event: React.ChangeEvent<{ value: string }>) => {
    setSearchString(event.target.value)
    if (!primaryTableRef?.current) {
      return
    }
    primaryTableRef.current.search(event.target.value)
  }

  const resetProfileFetchState = () => {
    setSideMenuVisible(false)
    setSearchString('')
    setProfileData([])
    const customProfileFilterConfig = associationRepo.getProfileFilterConfig(selectedAssociation)
    setCustomProfileFilterConfig(customProfileFilterConfig)
  }

  const resetAndReload = () => {
    resetProfileFetchState()
    loadProfileData()
  }

  const { loadingModalOpen, loadingModalMessage } = modalState
  const { hasTrashAccess } = navMenuAccess

  return (
    <div style={{ ...styles.container }}>
      <NavBar
        match={props.match}
        location={props.location}
        history={props.history}
        reloadPageData={() => resetAndReload()}
        primaryTableRef={primaryTableRef}
      />
      {/* <Beforeunload onBeforeunload={async (event) => await refreshData()} /> */}
      <SectionHeader
        ref={sectionHeaderRef}
        style={styles.sectionHeader}
        downloading={initialDownloading}
        searchString={searchString}
        textHandler={(e) => searchHandler(e)}
        onClick={() => {
          setSideMenuVisible(false)
          setTableWith(10)
        }}
        key={`sectionHeader_${selectedAssociation}`}>
        {titleCase(`${currentScreen}`)}
      </SectionHeader>

      <div style={styles.contentContainer}>
        <div
          style={{
            ...styles.rightSide,
            paddingInline: sideMenuVisible ? 'max(2em, 2%)' : 'min(3em, 3%)',
          }}>
          <div style={styles.rightSideContent}>
            <DataTable
              ref={primaryTableRef}
              tableData={profileData}
              columnConfig={tableColumnConfig.current}
              tableWidth={tableWidth}
              filterState={{}}
              onRowClick={(rowID) => null}
              selectedRowItemId={''}
              customFilterConfig={customProfileFilterConfig}
              selectionEnabled={false}
              disabled={true}
              key={`dataTable_${selectedAssociation}`}
            />
          </div>
        </div>
      </div>
      <AlertModalOneButton
        open={!hasTrashAccess}
        header="Not Authorised"
        body="You don't have permission to view trashed profile info."
        buttonLabel="Ok"
        opaqueBackground={true}
        onClick={() => props.history.goBack()}
      />
      <LoadingModal open={loadingModalOpen}>{loadingModalMessage}</LoadingModal>
      <LoadingModal open={retrievingProfiles}>Retrieving profiles...</LoadingModal>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.SCREEN_TOP_GRADIENT}, ${ColorPalette.SCREEN_BOTTOM_GRADIENT})`,
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto calc(0.5% + 40px)',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    width: '100%',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  tableNavButtonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbarButton: {
    width: 180,
    fontWeight: 'bolder' as 'bolder',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
}

export default Radium(TrashedProfiles)
